import React from 'react';
import { composeRenderProps, Input as AriaInput, InputProps } from 'react-aria-components';
import { cn } from '@/shared/helpers';

export type { InputProps };

export const Input = React.forwardRef<React.ElementRef<typeof AriaInput>, InputProps>(
  ({ className, ...props }, ref) => (
    <AriaInput
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          /* Focused */
          'data-[focused]:outline-none data-[focused]:ring-1 data-[focused]:ring-ring',
          /* Resets */
          'focus-visible:outline-none',
          composedClassName,
        ),
      )}
      {...props}
    />
  ),
);
