import { OrderDTO, OrderStatus } from '@invenco/common-interface/sales';
import { Form, FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Result } from '../../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../../shared/types';
import { compareAddresses } from '../utils';

export enum CustomerStep {
  CUSTOMER,
  ADDRESS,
}

type Models = {
  form: FormInstance;
  isSaving: boolean;
  isCancelStep: boolean;
  isSaveStep: boolean;
  showIgnoreAddressCorrection: boolean;
  initialValues: Record<string, any>;
};

type Operations = {
  navigateBack: () => void;
  submit: (data: Record<string, string>) => Promise<void>;
};

type Props = {
  isSequential: boolean;
  isOpen: boolean;
  step: CustomerStep;
  changeStep: (step: CustomerStep) => void;
  onClose: () => void;
  orderDetails: Partial<OrderDTO>;
  updateDetails: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function useCustomerModalComponent({
  isSequential,
  isOpen,
  step,
  changeStep,
  onClose,
  orderDetails,
  updateDetails,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [pendingData, setPendingData] = useState<Partial<OrderDTO>>();

  const showIgnoreAddressCorrection = orderDetails.status === OrderStatus.DRAFT;

  // if further steps are added, modify these to be more dynamic
  const isCancelStep = step === CustomerStep.CUSTOMER || !isSequential;
  const isSaveStep = step === CustomerStep.ADDRESS || !isSequential;
  const getPrevStep = (s?: CustomerStep) =>
    s === CustomerStep.ADDRESS ? CustomerStep.CUSTOMER : undefined;
  const getNextStep = (s?: CustomerStep) =>
    s === CustomerStep.CUSTOMER ? CustomerStep.ADDRESS : undefined;

  const initialValues = useMemo(() => {
    const data = pendingData || orderDetails;
    return step === CustomerStep.CUSTOMER
      ? {
          customerName: data.customerName,
          email: data.email,
          phone: data.phone,
        }
      : {
          shippingAddress: data.shippingAddress,
          billingAddress: data.billingAddress,
          ignoreAddressCorrection: data.ignoreAddressCorrection,
          copyAddress: compareAddresses(data.shippingAddress, data.billingAddress),
        };
  }, [step, orderDetails, pendingData]);

  useEffect(() => {
    form.resetFields();
  }, [isOpen, step, form]);
  useEffect(() => {
    setPendingData(undefined);
  }, [isOpen]);

  const submit = async (data: Record<string, string>) => {
    const { copyAddress, ...rest } = data;
    const fullData = { ...pendingData, ...rest };
    if (step === CustomerStep.ADDRESS && copyAddress) {
      fullData.billingAddress = fullData.shippingAddress;
    }

    // only save if we're at the last step
    if (isSaveStep) {
      setIsSaving(true);
      const result = await updateDetails(fullData);
      setIsSaving(false);
      if (result.isSuccess) onClose();
    } else {
      setPendingData(fullData);
      changeStep(getNextStep(step) ?? CustomerStep.ADDRESS);
    }
  };

  const navigateBack = () => {
    if (isCancelStep) {
      onClose();
    } else {
      changeStep(getPrevStep(step) ?? CustomerStep.CUSTOMER);
    }
  };

  return {
    models: {
      form,
      isCancelStep,
      isSaveStep,
      isSaving,
      showIgnoreAddressCorrection,
      initialValues,
    },
    operations: { navigateBack, submit },
  };
}
