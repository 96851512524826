import React from 'react';
import { composeRenderProps, TextArea as AriaTextArea, TextAreaProps } from 'react-aria-components';
import { cn } from '@/shared/helpers';

export type { TextAreaProps };

export const TextArea = React.forwardRef<React.ElementRef<typeof AriaTextArea>, TextAreaProps>(
  ({ className, ...props }, ref) => (
    <AriaTextArea
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground',
          /* Focused */
          'data-[focused]:outline-none data-[focused]:ring-2 data-[focused]:ring-ring data-[focused]:ring-offset-2',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          /* Resets */
          'focus-visible:outline-none',
          composedClassName,
        ),
      )}
      {...props}
    />
  ),
);
