import { useState, useEffect, useMemo } from 'react';
import { Form, FormInstance } from 'antd';
import {
  CarrierDTO,
  CarrierAccountDTO,
  ShippingMethodDTO,
} from '@invenco/common-interface/shipping';
import { IncoTerms } from '@invenco/common-interface/sales';
import { WarehouseCarrierServiceDTO } from '@invenco/common-interface/warehousing';
import { Result } from '../../../../shared/helpers/Result';
import { useQueryWithInput } from '../../../../shared/hooks/queries';
import { ComponentData } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';

type Models = {
  form: FormInstance;
  carrierAccounts?: CarrierAccountDTO[];
  isLoadingCarrierAccounts: boolean;
  shippingMethods?: ShippingMethodDTO[];
  isLoadingShippingMethods: boolean;
  isSaving: boolean;
  selectedCarrierAccountId?: string;
};

type Operations = {
  selectCarrierId: (carrierId: string) => void;
  selectCarrierServiceId: (carrierServiceId: string) => void;
  selectCarrierAccountId: (carrierAccountId: string) => void;
  submit: (formData: Record<string, string>) => Promise<void>;
};

type Props = {
  carriers?: CarrierDTO[];
  isOpen: boolean;
  onClose: () => void;
  createWarehouseCarrierService: (
    warehouseCarrierService: Partial<WarehouseCarrierServiceDTO>,
  ) => Promise<Result>;
};

export function useAddWarehouseCarrierServiceModalComponent({
  carriers,
  isOpen,
  onClose,
  createWarehouseCarrierService,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const { shippingGateway } = useGateways();
  const [selectedCarrierId, setSelectedCarrierId] = useState<string | null>();
  const [selectedCarrierServiceId, setSelectedCarrierServiceId] = useState<string>();
  const [selectedCarrierAccountId, setSelectedCarrierAccountId] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);

  const { data: carrierAccounts, isLoading: isLoadingCarrierAccounts } = useQueryWithInput({
    parentKey: 'carrierAccounts',
    input: {
      carrierIds: [selectedCarrierId!],
    },
    query: (input, { signal }) => shippingGateway.getCarrierAccounts(input, { signal }),
    select: (carrierAccountData) => carrierAccountData.items,
    enabled: !!selectedCarrierId,
  });

  const { data: shippingMethods, isLoading: isLoadingShippingMethods } = useQueryWithInput({
    parentKey: 'shippingMethods',
    input: {
      carrierServiceId: selectedCarrierServiceId,
      carrierAccountId: selectedCarrierAccountId,
    },
    query: (input, { signal }) => shippingGateway.getShippingMethods(input, { signal }),
    select: (shippingMethodData) => shippingMethodData.items,
    enabled: !!(selectedCarrierServiceId && selectedCarrierAccountId),
  });

  const selectCarrierId = (carrierId: string) => {
    setSelectedCarrierId(carrierId);
    form.setFieldsValue({
      carrierServiceId: undefined,
      carrierAccountId: undefined,
      shippingMethodId: undefined,
    });
    setSelectedCarrierAccountId('');
  };

  const selectCarrierServiceId = (carrierServiceId: string) => {
    setSelectedCarrierServiceId(carrierServiceId);
    setSelectedCarrierAccountId('');
    form.setFieldsValue({ carrierAccountId: undefined, shippingMethodId: undefined });
  };

  const selectCarrierAccountId = (carrierAccountId: string) => {
    setSelectedCarrierAccountId(carrierAccountId);
    form.setFieldsValue({
      shippingMethodId: carrierAccountId === 'default' ? '' : undefined,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const filteredShippingMethods = useMemo(() => {
    const warehouseShippingMethods = shippingMethods?.filter(
      (shippingMethod) => shippingMethod.type === 'WAREHOUSE',
    );
    return warehouseShippingMethods && warehouseShippingMethods.length > 0
      ? warehouseShippingMethods
      : shippingMethods;
  }, [shippingMethods]);

  const submit = async (formData: Record<string, string>) => {
    setIsSaving(true);
    const carrier = carriers && carriers.find((c) => c.id === selectedCarrierId);
    const carrierService =
      carrier?.carrierServices &&
      carrier.carrierServices.find((s) => s.id === selectedCarrierServiceId);
    const result = await createWarehouseCarrierService({
      wmsInstanceId: formData.wmsInstanceId,
      codes: [
        { code: formData.primaryCode, isPrimary: true },
        ...(form.getFieldValue('secondaryCodes') || []).map((code) => ({ code, isPrimary: false })),
      ],
      carrierCode: carrier?.code,
      carrierServiceCode: carrierService?.code,
      incoTerms: IncoTerms[formData.incoTerms],
      signatureRequired: !!formData.signatureRequired,
      shippingMethodId: formData.shippingMethodId,
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: {
      form,
      carrierAccounts: carrierAccounts ?? [],
      isLoadingCarrierAccounts,
      shippingMethods: filteredShippingMethods,
      isLoadingShippingMethods,
      isSaving,
      selectedCarrierAccountId,
    },
    operations: {
      selectCarrierId,
      selectCarrierServiceId,
      selectCarrierAccountId,
      submit,
    },
  };
}
