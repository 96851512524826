import styled from 'styled-components';
import { spaceVerticalChildren } from '../../../../../../styles/mixins';

export const FulfillmentsContainer = styled.div`
  ${spaceVerticalChildren()}
`;

export const FulfillmentInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InfoItem = styled.div<{ $align?: string; $gridRow?: number; $gridCol?: number }>`
  text-align: ${({ $align }) => $align};
  ${({ $gridRow }) => $gridRow && `grid-row: ${$gridRow};`}
  ${({ $gridCol }) => $gridCol && `grid-column: ${$gridCol};`}
`;

export const InfoTitle = styled.div`
  color: var(--gray-7);
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 4px;
`;

export const InfoBody = styled.div<{ $color?: string }>`
  color: var(--gray-9);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  strong {
    font-weight: 900;
  }
  ${(props) => props.$color && `color: ${props.$color};`}
`;

export const ShipmentPanel = styled.div`
  background: var(--gray-2);
  border: 1px solid var(--gray-4);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  margin-top: 12px;
`;

export const ShipmentInfo = styled.div`
  flex: 1;
  margin-right: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 12px;
`;

export const ShipmentTracking = styled.div`
  width: 302px;
  min-height: 100%;
  border: 1px solid var(--gray-4);
  border-radius: 4px;
  background: var(--gray-1);
`;
