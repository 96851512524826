import {
  WorkflowDataType,
  WorkflowScopeType,
  WorkflowStatus,
  WorkflowTriggerType,
} from '@invenco/common-domain/enums';
import { WorkflowDTO } from '@invenco/common-interface/automation';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { Label, StatusLabel } from 'components/label';
import { Link } from 'react-router';
import { workflowStatusType } from 'shared/status-maps';
import {
  workflowDataTypeTitle,
  workflowStatusTitle,
  workflowTriggerTypeTitle,
} from 'shared/title-maps';
import { Container, ListContainer } from 'styles/layout';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { useWorkflowListPage, views } from './useWorkflowListPage';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';

type Props = {
  scopeType: WorkflowScopeType;
};

const columns: Column<WorkflowDTO>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Description', key: 'description' },
  {
    title: 'Status',
    key: 'status',
    render: (status: WorkflowStatus) => (
      <StatusLabel type={workflowStatusType} title={workflowStatusTitle} status={status} />
    ),
  },
  {
    title: 'Data Type',
    key: 'dataType',
    render: (dataType: WorkflowDataType) => <Label>{workflowDataTypeTitle[dataType]}</Label>,
  },
  {
    title: 'Trigger',
    key: 'triggerType',
    render: (triggerType: WorkflowTriggerType) => (
      <Label>{workflowTriggerTypeTitle[triggerType]}</Label>
    ),
  },
];

export function WorkflowList({ scopeType }: Props) {
  const {
    models: {
      isLoading,
      hasError,
      rows,
      pagination,
      total,
      query,
      view,
      isLoadingWmsInstances,
      wmsInstanceIdToCode,
    },
    operations: { search, navigate, refresh, changeView, onClickRow },
  } = useWorkflowListPage({ scopeType });

  const finalColumns =
    scopeType !== WorkflowScopeType.WMS_INSTANCE
      ? columns
      : [
          {
            title: 'WMS Instance',
            key: 'scopeId',
            render: (instanceId: string) =>
              isLoadingWmsInstances ? (
                <LoadingSkeleton />
              ) : (
                (wmsInstanceIdToCode?.[instanceId] ?? instanceId)
              ),
          },
          ...columns,
        ];

  return (
    <Container>
      <Header
        title="Workflows"
        links={[
          { url: '/automation', title: 'Automation' },
          { url: '/automation/workflows', title: 'Workflows' },
        ]}
        actionContent={
          <Button type="primary">
            <Link to="/automation/workflows/new">Create Workflow</Link>
          </Button>
        }
      />

      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />

          <DataTable.SearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
          />

          <DataTable
            rows={rows}
            columns={finalColumns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
