import { OrderAction, OrderDTO } from '@invenco/common-interface/sales';
import { useGateways } from 'gateways/GatewayProvider';
import { EnumMap } from 'shared/types';

export const orderActionsToRefresh: OrderAction[] = [OrderAction.RELEASE];

export const orderActionSuccessMessage: EnumMap<OrderAction> = {
  APPLY_MANUAL_HOLD: 'The order has been set to hold',
  RELEASE_MANUAL_HOLD: 'The order hold has been released',
  REVERT_TO_DRAFT: 'The order has been reverted to draft',
  RELEASE: 'The order has been released',
  CANCEL: 'The order has been cancelled',
  CLOSE: 'The order has been closed',
};

export function getOrderActionMethod(
  salesGateway: ReturnType<typeof useGateways>['salesGateway'],
  action: OrderAction,
) {
  const methodMap: EnumMap<OrderAction, (id: string) => Promise<OrderDTO>> = {
    APPLY_MANUAL_HOLD: salesGateway.applyManualHold,
    RELEASE_MANUAL_HOLD: salesGateway.releaseManualHold,
    REVERT_TO_DRAFT: salesGateway.revertToDraft,
    RELEASE: salesGateway.releaseOrder,
    CANCEL: salesGateway.cancelOrder,
    CLOSE: salesGateway.closeOrder,
  };

  return methodMap[action].bind(salesGateway);
}
