import { useNavigate, useLocation } from 'react-router';
import { useAuthToken } from 'shared/hooks/useAuthToken';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getItems, NavItem } from './items';
import { MENU_COLLAPSED_STORAGE_KEY } from '../../../constants';
import { getLocalStorage, setLocalStorage } from '../../../shared/helpers';
import { ComponentData } from '../../../shared/types';

export type NavItemState = Omit<NavItem, 'children'> & {
  id: string;
  children?: NavItemState[];
  isActive: boolean;
  isOpen?: boolean;
  hasActiveChild?: boolean;
};

type Models = {
  primaryItems: NavItemState[];
  secondaryItems: NavItemState[];
  isCollapsed: boolean;
};

type Operations = {
  toggleCollapsed: () => void;
  selectItem: (id: string) => void;
  selectHeader: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

const mapItems = (path: string, items: NavItem[]): NavItemState[] =>
  items.map((item) => {
    const children = item.children ? mapItems(path, item.children) : undefined;
    const hasActiveChild = children?.some((child) => child.isActive) ?? false;
    const selected = path.startsWith(item.url);
    return {
      ...item,
      id: item.key ?? item.title.toLowerCase().replace(' ', '-'),
      children,
      isActive: !hasActiveChild && selected,
      isOpen: hasActiveChild,
      hasActiveChild,
    };
  });

export function useNavigationComponent(): ComponentData<Models, Operations> {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessContext } = useAuthToken();
  const [items, setItems] = useState<NavItemState[]>([]);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    getLocalStorage(MENU_COLLAPSED_STORAGE_KEY) ?? false,
  );
  const [isTemporarilyExpanded, setIsTemporarilyExpanded] = useState(false);
  const collapseTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const [primaryItems, secondaryItems] = useMemo(
    () => [items.filter((item) => !item.secondary), items.filter((item) => item.secondary)],
    [items],
  );

  useEffect(
    () => setItems(mapItems(location.pathname, getItems(accessContext))),
    [location, accessContext],
  );

  const toggleCollapsed = () => {
    if (isTemporarilyExpanded) {
      setIsTemporarilyExpanded(false);
    } else {
      const value = !isCollapsed;
      setIsCollapsed(value);
      setLocalStorage(MENU_COLLAPSED_STORAGE_KEY, value);
    }
  };

  const selectItem = (id: string) => {
    // ensure all top-level items are closed and toggle the selected item
    const newItems = items.map((item) => ({
      ...item,
      isOpen: !!item.children?.length && item.id === id && !item.isOpen,
    }));
    setItems(newItems);
    const updatedItem = newItems.find((item) => item.id === id);
    if (isCollapsed && updatedItem?.children?.length && updatedItem.isOpen) {
      setIsTemporarilyExpanded(true);
    }
  };

  const selectHeader = () => {
    if (isCollapsed) {
      toggleCollapsed();
    } else {
      void navigate('/dashboard');
    }
  };

  const onMouseEnter = () => {
    if (collapseTimerRef.current) {
      clearTimeout(collapseTimerRef.current);
      collapseTimerRef.current = undefined;
    }
  };

  const onMouseLeave = () => {
    if (isTemporarilyExpanded) {
      if (collapseTimerRef.current) {
        clearTimeout(collapseTimerRef.current);
      }
      collapseTimerRef.current = setTimeout(() => {
        setIsTemporarilyExpanded(false);
        collapseTimerRef.current = undefined;
      }, 500);
    }
  };

  return {
    models: {
      primaryItems,
      secondaryItems,
      isCollapsed: isCollapsed && !isTemporarilyExpanded,
    },
    operations: {
      toggleCollapsed,
      selectItem,
      selectHeader,
      onMouseEnter,
      onMouseLeave,
    },
  };
}
