import { CloseCircleOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FulfillmentStrategy } from '@invenco/common-interface/products';
import {
  ChannelDTO,
  OrderCurrency,
  OrderLineDTO,
  OrderLineStatus,
} from '@invenco/common-interface/sales';
import { Button } from 'components/button';
import { EditableTable } from 'components/editable-table';
import { EditableColumn } from 'components/editable-table/types';
import { SkuLabel } from 'components/label';
import { ListTable, ListTableProps } from 'components/list-table';
import { SkuSearch } from 'components/sku-search';
import { formatMoney, formatNumber } from 'shared/helpers';
import { Result } from 'shared/helpers/Result';
import { VerticallySpaced } from 'styles/layout';
import { OrderLineDetails } from './OrderLineDetails';
import { BundleComponents } from './bundle-components';
import { BundleDetailsContainer, DescriptionField, LineStatus, SkuSearchContainer } from './styles';
import { useOrderLinesComponent } from './useOrderLinesComponent';
import {
  getDiscountInputValue,
  getFormattedDiscount,
  getOrderLineFromFormData,
  getOrderLineTotal,
} from './utils';

type Props = {
  loading?: boolean;
  editable?: boolean;
  canDeleteLines?: boolean;
  orderLines: Partial<OrderLineDTO>[];
  orderCurrency?: OrderCurrency;
  channel: Partial<ChannelDTO>;
  addOrderLine: (orderLine: Partial<OrderLineDTO>) => Promise<Result>;
  updateOrderLine: (lineId: string, orderLine: Partial<OrderLineDTO>) => Promise<Result>;
  deleteOrderLine: (lineId: string) => Promise<void>;
  openAssignSku: (line: Partial<OrderLineDTO>) => void;
};

const getFormattedTotal = (
  line: Partial<OrderLineDTO>,
  currency: OrderCurrency = OrderCurrency.AUD,
) => {
  const total = getOrderLineTotal(line);
  return Number.isNaN(total) ? 'N/A' : formatMoney(total, currency);
};

const getColumns = (
  currency?: OrderCurrency,
  selectedLineIds?: string[],
  editable?: boolean,
): EditableColumn<Partial<OrderLineDTO>>[] => [
  {
    title: 'SKU / Description',
    key: 'description',
    prefix: ({ skuName, fulfillmentStrategy, id }) => (
      <SkuLabel
        name={skuName}
        fulfillmentStrategy={fulfillmentStrategy}
        active={selectedLineIds?.includes(id as string) && editable}
      />
    ),
    render: (description: string, { status }: Partial<OrderLineDTO>) => (
      <DescriptionField>
        <div>{description}</div>

        {status &&
          [
            OrderLineStatus.PARTIAL,
            OrderLineStatus.BACKORDER,
            OrderLineStatus.ITEM_NOT_FOUND,
          ].includes(status) && (
            <LineStatus $status={status}>
              {status === OrderLineStatus.PARTIAL ? (
                <ExclamationCircleOutlined />
              ) : (
                <CloseCircleOutlined />
              )}
            </LineStatus>
          )}
      </DescriptionField>
    ),
    required: true,
  },
  {
    title: 'Qty',
    key: 'qtyOrdered',
    render: (qty?: number) => formatNumber(qty ?? 0),
    width: '120px',
    align: 'right',
    required: true,
    pattern: 'integer',
    autoFocus: true,
  },
  {
    title: 'Unit Price',
    key: 'unitPrice',
    render: (price?: number) => formatMoney(price ?? 0, currency),
    width: '120px',
    align: 'right',
    required: true,
    pattern: 'float',
  },
  {
    title: 'Discount',
    key: 'discount',
    render: (_, line) => getFormattedDiscount(line, currency),
    inputValue: (line) => getDiscountInputValue(line),
    width: '120px',
    align: 'right',
    required: true,
    pattern: /^[0-9]*(\.[0-9]{0,2})?%?$/,
  },
  {
    title: 'Line Total',
    key: 'lineTotal',
    // lineTotal field not used to more easily support in-memory line updates
    render: (_, line) => getFormattedTotal(line, currency),
    renderFromForm: (data) => getFormattedTotal(getOrderLineFromFormData(data), currency),
    align: 'right',
    width: '174px',
    editable: false,
  },
];

export function OrderLines({
  editable,
  canDeleteLines,
  loading,
  orderLines,
  orderCurrency,
  channel,
  addOrderLine,
  updateOrderLine,
  deleteOrderLine,
  openAssignSku,
}: Props) {
  const {
    models: { processedLines, selectedLineIds, isSelectingSku, isEditingNewLine, selectedSku },
    operations: { selectLine, closeLine, save, selectNewSku, showSkuSelection, validateForm },
  } = useOrderLinesComponent({
    editable,
    orderLines,
    addOrderLine,
    updateOrderLine,
  });

  const expandedRowContent = (line: Partial<OrderLineDTO>) => {
    /** Render order line details */
    const renderDetails = () => (
      <OrderLineDetails
        line={{ ...line, skuId: selectedSku?.id ?? line.skuId }}
        channel={channel}
        editing={editable}
        onOpenAssignSku={() => openAssignSku(line)}
      />
    );

    if (line.fulfillmentStrategy === FulfillmentStrategy.BUNDLE) {
      // This order line is a bundle.
      // Find the child components and display them here ordered by line number.
      const components = orderLines
        .filter((l) => l.assemblyOrderLineId === line.id)
        .sort((a, b) => (a.lineNumber ?? 0) - (b.lineNumber ?? 0));

      return (
        <BundleDetailsContainer>
          <BundleComponents components={components} editing={editable} />
          {renderDetails()}
        </BundleDetailsContainer>
      );
    }
    return renderDetails();
  };

  const commonProps: ListTableProps<Partial<OrderLineDTO>> = {
    simple: true,
    columns: getColumns(orderCurrency, selectedLineIds, editable),
    // Do not show bundle's component lines
    rows: processedLines.filter(({ assemblyOrderLineId }) => !assemblyOrderLineId),
    loading,
    hidePlaceholderText: !orderLines.length,
    onClickRow: (line) => selectLine(line),
    expandedRowContent,
  };

  return editable ? (
    <VerticallySpaced $factor={0.5}>
      <EditableTable
        {...commonProps}
        deletable={canDeleteLines && !isEditingNewLine}
        onCloseRow={closeLine}
        onSaveRow={({ id }, data) => id && save(id, data)}
        onDeleteRow={({ id }) => id && deleteOrderLine(id)}
        validateForm={validateForm}
        selectedRowKey={selectedLineIds[0]}
      />

      {isSelectingSku ? (
        <SkuSearchContainer>
          <SkuSearch prefixIcon selectedSku={selectedSku} onSelect={selectNewSku} autoFocus />
        </SkuSearchContainer>
      ) : (
        !isEditingNewLine && (
          <Button
            aria-label="Add new item"
            type="link"
            inline
            icon={<PlusOutlined />}
            onClick={showSkuSelection}
          >
            Add new item
          </Button>
        )
      )}
    </VerticallySpaced>
  ) : (
    <ListTable {...commonProps} selectedRowKeys={selectedLineIds} />
  );
}
