import { FulfillmentStrategy } from '@invenco/common-interface/products';
import { Label } from 'components/label/Label';
import { HorizontallySpaced } from 'styles/layout';
import { BundleIcon } from './styles';

interface Props {
  /** SKU name */
  name?: string;
  /** SKU's fulfillment strategy */
  fulfillmentStrategy?: FulfillmentStrategy;
  /** Whether the label is currently selected */
  active?: boolean;
}

/** SKU label. Show a bundle icon if the fulfillment strategy is "BUNDLE". */
export function SkuLabel({ name, fulfillmentStrategy, active }: Props) {
  return (
    <Label normalCase color={active ? 'active' : 'default'}>
      <HorizontallySpaced $factor={0.5}>
        {fulfillmentStrategy === FulfillmentStrategy.BUNDLE && <BundleIcon />}
        <span>{name ?? 'N/A'}</span>
      </HorizontallySpaced>
    </Label>
  );
}
