import { PropsWithChildren } from 'react';
import { BrowserRouter, useNavigate, useHref } from 'react-router';
import { RouterProvider as AriaRouterProvider } from 'react-aria-components';

function InnerRouterProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <AriaRouterProvider navigate={navigate} useHref={useHref}>
      {children}
    </AriaRouterProvider>
  );
}

export function RouterProvider({ children }: PropsWithChildren) {
  return (
    <BrowserRouter>
      <InnerRouterProvider>{children}</InnerRouterProvider>
    </BrowserRouter>
  );
}
