import React, { useState } from 'react';
import { GatewayName } from '@/gateways/types';
import { baseUrlProvider } from '@/gateways/baseUrlProvider';
import { ComponentData } from '@/shared/types';
import { Control, useForm } from 'react-hook-form';
import { useMessages } from '@/shared/providers/MessagesProvider';

type ConfigureFormData = { branch: string };
type UrlFormData = Record<GatewayName, string>;

type Models = {
  isConfiguringUrls: boolean;
  baseUrls: Record<GatewayName, string>;
  configureFormControl: Control<ConfigureFormData>;
  urlFormControl: Control<UrlFormData>;
};

type Operations = {
  submitConfigureForm: (e?: React.BaseSyntheticEvent) => void;
  submitUrlForm: (e?: React.BaseSyntheticEvent) => void;
  resetUrls: () => void;
};

export function useDeveloperConfigPage(): ComponentData<Models, Operations> {
  const [isConfiguringUrls, setIsConfiguringUrls] = useState(false);
  const messages = useMessages();

  const baseUrls = baseUrlProvider.getForAllGateways();
  const urlForm = useForm({ defaultValues: baseUrls });
  const configureForm = useForm({ defaultValues: { branch: 'lynk-' } });

  const updateUrlFormValues = () => urlForm.reset(baseUrlProvider.getForAllGateways());

  const submitConfigureForm = configureForm.handleSubmit(async ({ branch }) => {
    setIsConfiguringUrls(true);
    baseUrlProvider.reset();

    const entries = await Promise.all(
      Object.entries(baseUrlProvider.getForAllGateways()).map(async ([name, baseUrl]) => {
        const branchUrl = `${baseUrl}-${branch}`;
        const res = await fetch(`${branchUrl}/v1/info`);
        if (![403, 404].includes(res.status)) {
          return [name, branchUrl];
        }
        return null;
      }),
    );
    const filteredEntries = entries.filter((x) => x !== null) as [string, string][];

    if (filteredEntries.length) {
      baseUrlProvider.setForGateways(Object.fromEntries(filteredEntries));
      messages.success(
        `Configured ${filteredEntries.length}/${
          Object.keys(baseUrls).length
        } base URLs for ${branch}`,
      );
    } else {
      messages.error(`Unable to find any matching gateways for ${branch}`);
    }

    updateUrlFormValues();
    setIsConfiguringUrls(false);
  });

  const submitUrlForm = urlForm.handleSubmit((data) => {
    baseUrlProvider.setForGateways(data);
    messages.success('Updated API base URLs');
  });

  const resetUrls = () => {
    baseUrlProvider.reset();
    updateUrlFormValues();
    messages.success('Reset API base URLs');
  };

  return {
    models: {
      isConfiguringUrls,
      urlFormControl: urlForm.control,
      configureFormControl: configureForm.control,
      baseUrls,
    },
    operations: { submitConfigureForm, submitUrlForm, resetUrls },
  };
}
