import React from 'react';
import { CheckIcon } from 'lucide-react';
import {
  Collection as AriaCollection,
  Header as AriaHeader,
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps as AriaListBoxItemProps,
  ListBoxProps as AriaListBoxProps,
  ListBoxSection as AriaListBoxSection,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

const ListBoxSection = AriaListBoxSection;

const ListBoxCollection = AriaCollection;

function ListBox<T extends object>({ className, ...props }: AriaListBoxProps<T>) {
  return (
    <AriaListBox
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          composedClassName,
          'group overflow-auto rounded-md border bg-popover p-1 text-popover-foreground shadow-md outline-none',
          /* Empty */
          'data-[empty]:p-6 data-[empty]:text-center data-[empty]:text-sm',
        ),
      )}
      {...props}
    />
  );
}

function ListBoxItem<T extends object>({ className, children, ...props }: AriaListBoxItemProps<T>) {
  return (
    <AriaListBoxItem
      textValue={props.textValue || (typeof children === 'string' ? children : undefined)}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'relative flex w-full cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
          /* Disabled */
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          /* Focused */
          'data-[focused]:bg-accent data-[focused]:text-accent-foreground',
          /* Hovered */
          'data-[hovered]:bg-accent data-[hovered]:text-accent-foreground',
          /* Selection */
          'data-[selection-mode]:pr-8',
          composedClassName,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (composedChildren, renderProps) => (
        <>
          {renderProps.isSelected && (
            <span className="absolute right-2 flex size-4 items-center justify-center">
              <CheckIcon className="size-4" />
            </span>
          )}
          {composedChildren}
        </>
      ))}
    </AriaListBoxItem>
  );
}

function ListBoxHeader({ className, ...props }: React.ComponentProps<typeof AriaHeader>) {
  return <AriaHeader className={cn('px-2 py-1.5 text-sm font-medium', className)} {...props} />;
}

export { ListBox, ListBoxItem, ListBoxHeader, ListBoxSection, ListBoxCollection };
