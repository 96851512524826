import { SkuType } from '@invenco/common-interface/products';
import { Button } from 'components/button';
import { Card } from 'components/card';
import { DataImporter } from 'components/data-importer';
import { TemplateKey } from 'components/data-importer/types';
import { Column, DataTable } from 'components/data-table';
import { Header } from 'components/header';
import { Label } from 'components/label';
import { skuTypeTitle } from 'shared/title-maps';
import { Container, HorizontallySpaced, ListContainer } from 'styles/layout';
import { ModalConfirm } from '../../../../components/modal-confirm';
import { CreateSkuModal } from './create-sku-modal/CreateSkuModal';
import { SkuRow, useSkuListPage, views } from './useSkuListPage';

const columns: Column<SkuRow>[] = [
  {
    title: 'SKU / Description',
    key: 'name',
    render: (name, row) => (
      <HorizontallySpaced $factor={0.5}>
        <Label normalCase>{name}</Label>
        <span>{row.description}</span>
      </HorizontallySpaced>
    ),
  },
  { title: 'Barcode', key: 'barcode' },
  {
    title: 'SKU Type',
    key: 'type',
    render: (type: SkuType) => <Label>{(skuTypeTitle[type] || type).toUpperCase()}</Label>,
  },
  {
    key: 'dimensions',
    dataIndex: 'measurement',
    title: 'Dimensions (LxWxH)',
    render: (measurement) =>
      measurement
        ? `${measurement.length}x${measurement.width}x${
            measurement.height
          }${measurement.dimensionUnit.toLocaleLowerCase()}`
        : '',
    align: 'right',
  },
  {
    key: 'weight',
    dataIndex: 'measurement',
    title: 'Weight',
    render: (measurement) =>
      measurement ? `${measurement.weight}${measurement.weightUnit.toLocaleLowerCase()}` : '',
    align: 'right',
  },
];

export function SkuList() {
  const {
    models: {
      isCreateModalOpen,
      isExportModalOpen,
      isLoading,
      isExporting,
      hasError,
      rows,
      view,
      pagination,
      total,
      query,
      filters,
      filterSelections,
    },
    operations: {
      search,
      navigate,
      changeView,
      refresh,
      updateFilters,
      onClickRow,
      exportAll,
      onImportComplete,
      openCreateModal,
      closeCreateModal,
      openExportModal,
      closeExportModal,
    },
  } = useSkuListPage();

  return (
    <Container>
      <Header
        links={[
          { url: '/products', title: 'Products' },
          { url: '/products/skus', title: 'SKUs' },
        ]}
        title="SKUs"
        actionContent={
          <>
            <Button onClick={openExportModal} loading={isExporting}>
              Export
            </Button>
            <DataImporter
              template={TemplateKey.SKUS}
              title="Import SKUs"
              onComplete={onImportComplete}
            />
            <Button type="primary" onClick={openCreateModal}>
              Create SKU
            </Button>
          </>
        }
      />

      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />

          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search SKU..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterSelections}
            onChangeFilterSelections={updateFilters}
          />

          <DataTable
            rows={rows}
            columns={columns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>

      <CreateSkuModal isOpen={isCreateModalOpen} onClose={closeCreateModal} />

      <ModalConfirm
        isSubmitting={isExporting}
        open={isExportModalOpen}
        onCancel={closeExportModal}
        onOk={exportAll}
        title="Export SKUs"
        description={
          <>
            <p>
              <strong>All</strong> SKUs in your account will be exported in <strong>CSV</strong>{' '}
              format and downloaded to your computer.
            </p>
            <p>Click the confirm button below when you are ready to start the export.</p>
          </>
        }
      />
    </Container>
  );
}
