import { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { WmsInstanceDTO, WarehouseCarrierServiceDTO } from '@invenco/common-interface/warehousing';
import {
  CarrierDTO,
  CarrierAccountDTO,
  ShippingMethodDTO,
} from '@invenco/common-interface/shipping';
import { ComponentData } from 'shared/types';
import { Result } from '../../../../shared/helpers/Result';
import {
  useQueryWithInput,
  useEntityDetailsQuery,
  useGatewayMutation,
} from '../../../../shared/hooks/queries';
import { useGateways } from '../../../../gateways/GatewayProvider';

type WarehouseCarrierService = Partial<WarehouseCarrierServiceDTO> & { carrierAccountId: string };

type Models = {
  wmsInstances?: WmsInstanceDTO[];
  isLoadingWmsInstances: boolean;
  carriers?: CarrierDTO[];
  isLoadingCarriers: boolean;
  carrierAccounts?: CarrierAccountDTO[];
  isLoadingCarrierAccounts: boolean;
  shippingMethods?: ShippingMethodDTO[];
  isLoadingShippingMethods: boolean;
  warehouseCarrierService: Partial<WarehouseCarrierService>;
  isLoading: boolean;
  primaryExportCode?: string;
  secondaryExportCodes: string[];
  isSaving: boolean;
  canSave: boolean;
};

type Operations = {
  selectWmsInstanceId: (wmsInstanceId: string) => void;
  selectCarrierId: (carrierId: string) => void;
  selectCarrierAccountId: (carrierAccountId: string) => void;
  selectCarrierServiceId: (carrierServiceId: string) => void;
  selectShippingMethodId: (shippingMethodId: string) => void;
  updatePrimaryExportCode: (code: string) => void;
  addSecondaryExportCode: (newCode: string) => void;
  removeSecondaryExportCode: (index: number) => void;
  updateWarehouseCarrierService: (updateData: Partial<WarehouseCarrierServiceDTO>) => void;
  save: () => Promise<Result>;
  cancel: () => void;
};

export function useWarehouseCarrierServiceDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const { warehousingGateway, shippingGateway } = useGateways();
  const [initialCarrierId, setInitialCarrierId] = useState<string>();
  const [warehouseCarrierService, setWarehouseCarrierService] = useState<
    Partial<WarehouseCarrierService>
  >({});
  const navigate = useNavigate();
  const primaryExportCode = warehouseCarrierService.codes?.find(({ isPrimary }) => isPrimary)?.code;
  const secondaryExportCodes =
    warehouseCarrierService.codes?.filter((c) => !c.isPrimary).map(({ code }) => code) ?? [];

  const canSave = Boolean(
    warehouseCarrierService.wmsInstanceId &&
      warehouseCarrierService.carrierId &&
      warehouseCarrierService.carrierServiceId,
  );

  const { data: wmsInstances, isLoading: isLoadingWmsInstances } = useQuery({
    queryKey: ['wmsInstances'],
    queryFn: ({ signal }) => warehousingGateway.getWmsInstances(undefined, { signal }),
  });

  const { data: carriers, isLoading: isLoadingCarriers } = useQueryWithInput({
    parentKey: 'carriers',
    input: {
      include: { carrierServices: true },
    },
    query: (input, { signal }) => shippingGateway.getCarriers(input, { signal }),
    select: (carrierData) => carrierData.items,
  });

  const { data: carrierAccounts, isLoading: isLoadingCarrierAccounts } = useQueryWithInput({
    parentKey: 'carrierAccounts',
    input: {
      carrierIds: [warehouseCarrierService.carrierId!],
    },
    query: (input, { signal }) => shippingGateway.getCarrierAccounts(input, { signal }),
    select: (carrierAccountData) => carrierAccountData.items,
    enabled: !!warehouseCarrierService.carrierId,
  });

  const { data: shippingMethods, isLoading: isLoadingShippingMethods } = useQueryWithInput({
    parentKey: 'shippingMethods',
    input: {
      carrierServiceId: warehouseCarrierService.carrierServiceId,
      carrierAccountId: warehouseCarrierService.carrierAccountId,
    },
    query: (input, { signal }) => shippingGateway.getShippingMethods(input, { signal }),
    select: (shippingMethodData) => shippingMethodData.items,
    enabled: !!(
      warehouseCarrierService.carrierServiceId && warehouseCarrierService.carrierAccountId
    ),
  });

  const { data, isLoading } = useEntityDetailsQuery({
    parentKey: 'warehouseCarrierServices',
    id,
    query: (fetchId, { signal }) =>
      warehousingGateway.getWarehouseCarrierService(fetchId, { signal }),
    enabled: !!id,
  });

  const updateWarehouseCarrierService = useCallback(
    (updateData: Partial<WarehouseCarrierService>) => {
      setWarehouseCarrierService((current) => ({ ...current, ...updateData }));
    },
    [],
  );

  useEffect(() => {
    if (data) {
      setInitialCarrierId(data.carrierId);
      updateWarehouseCarrierService({
        ...data,
        shippingMethodId: data.shippingMethodId || 'default',
      });
    }
  }, [data, updateWarehouseCarrierService]);

  useEffect(() => {
    if (carrierAccounts && warehouseCarrierService.carrierId === initialCarrierId) {
      const carrierAccountId = carrierAccounts?.find(
        (carrierAccount) =>
          carrierAccount.name === warehouseCarrierService.shippingMethodBookingAccountName,
      )?.id;
      updateWarehouseCarrierService({ carrierAccountId: carrierAccountId || 'default' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierAccounts, initialCarrierId, updateWarehouseCarrierService]);

  const selectCarrierId = (carrierId: string) => {
    updateWarehouseCarrierService({
      carrierId,
      carrierServiceId: undefined,
      carrierAccountId: undefined,
      shippingMethodId: undefined,
    });
  };

  const selectCarrierAccountId = (carrierAccountId: string) => {
    updateWarehouseCarrierService({
      carrierAccountId,
      shippingMethodId: carrierAccountId === 'default' ? 'default' : undefined,
    });
  };

  const selectCarrierServiceId = (carrierServiceId: string) => {
    updateWarehouseCarrierService({
      carrierServiceId,
      shippingMethodId: undefined,
    });
  };

  const selectShippingMethodId = (shippingMethodId: string) => {
    updateWarehouseCarrierService({ shippingMethodId });
  };

  const selectWmsInstanceId = (wmsInstanceId: string) => {
    updateWarehouseCarrierService({ wmsInstanceId });
  };

  const updatePrimaryExportCode = (newCode: string) => {
    if (!newCode) return;

    updateWarehouseCarrierService({
      codes: warehouseCarrierService.codes?.map((code) =>
        code.isPrimary ? { ...code, code: newCode } : code,
      ),
    });
  };

  const addSecondaryExportCode = (newCode: string) => {
    if (!warehouseCarrierService?.codes?.some((c) => c.code === newCode)) {
      updateWarehouseCarrierService({
        codes: [
          ...(warehouseCarrierService?.codes || []),
          ...[{ code: newCode, isPrimary: false }],
        ],
      });
    }
  };

  const removeSecondaryExportCode = (index: number) => {
    const warehouseSecondaryExportCodes = [
      ...(warehouseCarrierService.codes?.filter((c) => !c.isPrimary) ?? []),
    ];
    warehouseSecondaryExportCodes.splice(index, 1);
    const primaryCode = warehouseCarrierService.codes?.find(({ isPrimary }) => isPrimary);
    updateWarehouseCarrierService({
      codes: [...(primaryCode ? [primaryCode] : []), ...warehouseSecondaryExportCodes],
    });
  };

  const getRequestDTO = () => {
    if (warehouseCarrierService.shippingMethodId === 'default') {
      warehouseCarrierService.shippingMethodId = null;
    }
    const carrier = carriers && carriers.find((c) => c.id === warehouseCarrierService.carrierId);
    const carrierService =
      carrier?.carrierServices &&
      carrier.carrierServices.find((s) => s.id === warehouseCarrierService.carrierServiceId);
    return {
      wmsInstanceId: warehouseCarrierService.wmsInstanceId,
      carrierCode: carrier?.code,
      carrierServiceCode: carrierService?.code,
      shippingMethodId: warehouseCarrierService.shippingMethodId || null,
      codes: warehouseCarrierService.codes,
      incoTerms: warehouseCarrierService.incoTerms,
      signatureRequired: warehouseCarrierService.signatureRequired,
    };
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => {
      const dto = getRequestDTO();
      return warehousingGateway.updateWarehouseCarrierService(id!, dto);
    },
    linkedQuery: ['warehouseCarrierServices', id],
    successMessage: 'Warehousing carrier service updated successfully',
    linkResponseToQuery: false,
  });

  const filteredShippingMethods = useMemo(() => {
    const warehouseShippingMethods = shippingMethods?.filter(
      (shippingMethod) => shippingMethod.type === 'WAREHOUSE',
    );
    return warehouseShippingMethods && warehouseShippingMethods.length > 0
      ? warehouseShippingMethods
      : shippingMethods;
  }, [shippingMethods]);

  const cancel = () => navigate(`/warehouseCarrierServices`);

  return {
    models: {
      wmsInstances,
      isLoadingWmsInstances,
      carriers,
      isLoadingCarriers,
      carrierAccounts,
      isLoadingCarrierAccounts,
      shippingMethods: filteredShippingMethods,
      isLoadingShippingMethods,
      warehouseCarrierService,
      isLoading,
      primaryExportCode,
      secondaryExportCodes,
      isSaving,
      canSave,
    },
    operations: {
      selectWmsInstanceId,
      selectCarrierId,
      selectCarrierAccountId,
      selectCarrierServiceId,
      selectShippingMethodId,
      updatePrimaryExportCode,
      addSecondaryExportCode,
      removeSecondaryExportCode,
      updateWarehouseCarrierService,
      save,
      cancel,
    },
  };
}
