import { Toolbar as AriaToolbar, composeRenderProps, ToolbarProps } from 'react-aria-components';

import { cn } from '@/shared/helpers';
import React from 'react';

export const Toolbar = React.forwardRef<React.ElementRef<typeof AriaToolbar>, ToolbarProps>(
  ({ className, children, ...props }, ref) => (
    <AriaToolbar
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex gap-2',
          /* Orientation */
          'data-[orientation=vertical]:flex-col',
          composedClassName,
        ),
      )}
      {...props}
    >
      {children}
    </AriaToolbar>
  ),
);
