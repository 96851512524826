import { FeatureFlagGateway, type FeatureFlags } from '../FeatureFlagGateway';

export const DEFAULT_STORAGE_KEY = 'lynk_frontend_feature_flags';
type Options = {
  storage: Storage;
  storageKey?: string;
};

/**
 * Handles feature flag management in local storage
 *
 * Flags are stored as a single item in local storage, with the following structure:
 * ```json
 * {
 *   "featureA": true,
 *   "featureB": false,
 * }
 * ```
 */
export class LocalStorageFeatureFlagGateway implements FeatureFlagGateway {
  private readonly storage: Storage;
  private readonly storageKey: string;

  constructor(options: Options) {
    this.storage = options.storage;
    this.storageKey = options.storageKey ?? DEFAULT_STORAGE_KEY;
  }

  getAllFlags(): FeatureFlags {
    try {
      const flagsRaw = this.storage.getItem(this.storageKey);
      const flagsParsed = flagsRaw ? JSON.parse(flagsRaw) : {};
      const cleanFlags = {};

      Object.entries(flagsParsed).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
          cleanFlags[key] = value;
        } else {
          // eslint-disable-next-line no-console
          console.error(
            `feature flag found in storage with a non-boolean value and will be disabled: ${key}. Feature flags must always have a true or false value`,
          );
        }
      });

      return cleanFlags;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Unexpected error reading feature flags from browser storage. All flags will be disabled.',
        error,
      );
      return {};
    }
  }

  getFlag(flagKey: string): boolean {
    try {
      return this.getAllFlags()[flagKey] ?? false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        `Unexpected error reading feature flag from browser storage. Returning false for ${flagKey}.`,
        error,
      );
      return false;
    }
  }

  setFlag(flagKey: string, value: boolean): void {
    try {
      const flags = this.getAllFlags();
      flags[flagKey] = value;
      this.storage.setItem(this.storageKey, JSON.stringify(flags));
    } catch (error) {
      if (error instanceof DOMException && error.name === 'QuotaExceededError') {
        // eslint-disable-next-line no-console
        console.warn('cannot set feature flag in local storage because local storage is full');
      } else {
        // eslint-disable-next-line no-console
        console.error('unexpected error setting feature flag in local storage', error);
      }
    }
  }

  deleteFlag(flagKey: string): void {
    try {
      const flags = this.getAllFlags();
      delete flags[flagKey];
      this.storage.setItem(this.storageKey, JSON.stringify(flags));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('unexpected error deleting feature flag in local storage', error);
    }
  }
}
