import { SkuDTO } from '@invenco/common-interface/products';
import { DimensionUnit, WeightUnit } from '@invenco/common-interface/shared';
import { Form, FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Result } from '../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  isOpen: boolean;
  sku: Partial<SkuDTO>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  onClose: () => void;
};

type Models = {
  form: FormInstance;
  isSaving: boolean;
  initialValues: Record<string, any>;
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

export function useMeasurementsModalComponent({
  isOpen,
  sku,
  updateSku,
  onClose,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = useMemo(
    () => ({
      length: sku.measurement?.length,
      width: sku.measurement?.width,
      height: sku.measurement?.height,
      weight: sku.measurement?.weight,
      dimensionUnit: sku.measurement?.dimensionUnit ?? DimensionUnit.CM,
      weightUnit: sku.measurement?.weightUnit ?? WeightUnit.KG,
    }),
    [sku],
  );

  useEffect(() => {
    form.resetFields();
  }, [isOpen, form]);

  const submit = async (data: Record<string, string>) => {
    setIsSaving(true);
    const result = await updateSku({
      measurement: {
        length: parseFloat(data.length || '0'),
        width: parseFloat(data.width || '0'),
        height: parseFloat(data.height || '0'),
        weight: parseFloat(data.weight || '0'),
        dimensionUnit: data.dimensionUnit as DimensionUnit,
        weightUnit: data.weightUnit as WeightUnit,
      },
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: { form, isSaving, initialValues },
    operations: { submit },
  };
}
