import { OrderDTO } from '@invenco/common-interface/sales';

import { Button } from 'components/button';
import { Form } from '../../../../../../components/form';
import { Modal } from '../../../../../../components/modal';
import { Result } from '../../../../../../shared/helpers/Result';
import { AddressForm } from './forms/AddressForm';
import { CustomerForm } from './forms/CustomerForm';
import { CustomerStep, useCustomerModalComponent } from './useCustomerModalComponent';

const stepTitle = {
  [CustomerStep.CUSTOMER]: 'Contact Information',
  [CustomerStep.ADDRESS]: 'Shipping Address',
};

type Props = {
  isSequential: boolean;
  isOpen: boolean;
  step: CustomerStep;
  changeStep: (step: CustomerStep) => void;
  onClose: () => void;
  orderDetails: Partial<OrderDTO>;
  updateDetails: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function CustomerModal({
  isSequential,
  isOpen,
  step,
  changeStep,
  onClose,
  orderDetails,
  updateDetails,
}: Props) {
  const {
    models: {
      form,
      isCancelStep,
      isSaveStep,
      isSaving,
      showIgnoreAddressCorrection,
      initialValues,
    },
    operations: { navigateBack, submit },
  } = useCustomerModalComponent({
    isSequential,
    isOpen,
    step,
    changeStep,
    onClose,
    orderDetails,
    updateDetails,
  });

  return (
    <Modal
      forceRender // this gets rid of the unused useForm value warning
      open={isOpen}
      title={stepTitle[step]}
      onCancel={onClose}
      width={550}
      footer={
        <>
          <Button onClick={navigateBack} disabled={isSaving}>
            {isCancelStep ? 'Cancel' : 'Previous'}
          </Button>
          <Button type="primary" onClick={() => form.submit()} loading={isSaving}>
            {isSaveStep ? 'Save' : 'Next'}
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={initialValues}
      >
        {step === CustomerStep.CUSTOMER && <CustomerForm />}
        {step === CustomerStep.ADDRESS && (
          <AddressForm showIgnoreAddressCorrection={showIgnoreAddressCorrection} />
        )}
      </Form>
    </Modal>
  );
}
