import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export * from './storage';
export * from './requests';
export * from './format';
export * from './visual-test';

export function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
