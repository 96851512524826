import { OrderLineStatus } from '@invenco/common-interface/sales';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { ComponentData } from '../../../../../../shared/types';
import { useQueryWithInput } from '../../../../../../shared/hooks/queries';

type Props = {
  skuId?: string | null;
  channelId?: string;
  status?: OrderLineStatus;
  editing: boolean;
};

type Models = {
  isLoading: boolean;
  canAssignSku: boolean;
  availableQuantity?: number;
  showExpandedQuantities: boolean;
};

export function useOrderLineDetailsComponent({
  skuId,
  channelId,
  status,
  editing,
}: Props): ComponentData<Models> {
  const showExpandedQuantities = !editing;
  const canAssignSku = editing && status === OrderLineStatus.ITEM_NOT_FOUND;
  const { salesGateway } = useGateways();

  const { data: quantity, isLoading } = useQueryWithInput({
    parentKey: 'listings',
    input: { skuIds: [skuId!], channelIds: [channelId!] },
    query: (input, { signal }) => salesGateway.getListings(input, { signal }),
    select: ({ items }) => items.reduce((sum, { qtyAvailable }) => sum + qtyAvailable, 0),
    enabled: editing && !!skuId && !!channelId,
  });

  return {
    models: {
      isLoading: isLoading && !!skuId && !!channelId,
      canAssignSku,
      availableQuantity: quantity ?? undefined,
      showExpandedQuantities,
    },
  };
}
