import { cva } from 'class-variance-authority';
import { DataTableProps } from '@/components/data-table/DataTable';
import { useMemo } from 'react';
import { cn } from '@/shared/helpers';
import { Checkbox } from '@/components-shad/ui/checkbox';
import { ArrowDownAZIcon, ArrowDownZAIcon } from 'lucide-react';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { Row } from './types';

const cellVariants = cva('p-2 align-middle [&_svg]:size-4', {
  variants: {
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
    header: {
      true: 'font-medium text-muted-foreground',
    },
    simple: {
      false: 'h-10 border-t first:pl-6 last:pr-6',
      true: 'h-12 first:rounded-l-md first:pl-3 last:rounded-r-md last:pr-3',
    },
    checkbox: {
      true: 'relative w-16 cursor-default',
    },
  },
  compoundVariants: [
    {
      simple: true,
      header: true,
      className: 'border-b',
    },
  ],
  defaultVariants: {
    align: 'left',
    simple: false,
    checkbox: false,
    header: false,
  },
});

export function DataTable<T extends Row>({
  rows,
  columns,
  loading,
  hasError,
  simple,
  emptyText,
  onClickRow,
  selectedRowsById,
  onToggleRowSelection,
  onTogglePageSelection,
  sort,
  sortOptions,
  onChangeSort,
}: DataTableProps<T>) {
  const isRowSelectable = typeof onToggleRowSelection === 'function';
  const isPageSelectable = typeof onTogglePageSelection === 'function';
  const isRowClickable = typeof onClickRow === 'function';

  const rowSelectionCount = useMemo(
    () =>
      rows?.reduce((acc, { id }) => acc + (selectedRowsById?.has(id.toString()) ? 1 : 0), 0) ?? 0,
    [selectedRowsById, rows],
  );

  return (
    <div className={simple ? '-mx-3' : '-mx-6 overflow-auto'}>
      <table className="w-full text-sm">
        <thead>
          <tr>
            {isPageSelectable && (
              <th
                onClick={() => onTogglePageSelection(rows ?? [])}
                className={cn(cellVariants({ checkbox: true, header: true, simple }))}
              >
                <div className="flex h-full items-center">
                  <Checkbox
                    onChange={() => onTogglePageSelection(rows ?? [])}
                    isDisabled={loading || !rows?.length}
                    isIndeterminate={
                      rows && rowSelectionCount > 0 && rowSelectionCount < rows?.length
                    }
                    isSelected={!!rows?.length && rowSelectionCount === rows?.length}
                    aria-label={
                      rowSelectionCount === rows?.length ? 'Deselect all rows' : 'Select all rows'
                    }
                  />
                </div>
              </th>
            )}

            {columns.map((column) => {
              const isSortable = sortOptions?.some((option) => option.key === column.key);
              const isActiveSort = sort?.key === column.key;
              return (
                <th
                  key={column.key}
                  className={cn(
                    cellVariants({
                      header: true,
                      simple,
                      align: column.align,
                    }),
                    isSortable && 'cursor-pointer',
                  )}
                  onClick={
                    isSortable
                      ? () =>
                          onChangeSort?.({
                            key: column.key,
                            direction: isActiveSort && sort?.direction === 'asc' ? 'desc' : 'asc',
                          })
                      : undefined
                  }
                >
                  {!isSortable ? (
                    column.title
                  ) : (
                    <div className="group flex w-full items-center gap-2">
                      {column.title}
                      <span
                        className={
                          isActiveSort
                            ? 'text-muted-foreground'
                            : 'invisible text-muted-foreground/50 group-hover:visible'
                        }
                        aria-hidden="true"
                      >
                        {isActiveSort && sort?.direction === 'desc' ? (
                          <ArrowDownZAIcon />
                        ) : (
                          <ArrowDownAZIcon />
                        )}
                      </span>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr aria-label="Loading">
              {isRowSelectable && (
                <td className={cn(cellVariants({ simple, checkbox: true }))}>
                  <div className="flex h-full items-center">
                    <Checkbox isDisabled />
                  </div>
                </td>
              )}
              {columns.map((column) => (
                <td key={column.key} className={cellVariants({ simple, align: column.align })}>
                  <Skeleton className="h-5 w-full" />
                </td>
              ))}
            </tr>
          ) : null}

          {!loading &&
            (rows?.length ? (
              rows.map((row, index) => {
                const isSelected = selectedRowsById?.has(row.id.toString());
                return (
                  <tr
                    key={row.id}
                    className={cn('hover:bg-muted/50', isRowClickable && 'cursor-pointer')}
                    onClick={isRowClickable ? (event) => onClickRow(row, event) : undefined}
                  >
                    {isRowSelectable && (
                      <td
                        className={cn(cellVariants({ simple, checkbox: true }))}
                        onClick={(e) => {
                          onToggleRowSelection(row);
                          e.stopPropagation();
                        }}
                      >
                        <div className="flex h-full items-center">
                          <Checkbox
                            onChange={() => onToggleRowSelection(row)}
                            isSelected={isSelected}
                            aria-label={isSelected ? 'Deselect row' : 'Select row'}
                          />
                        </div>
                      </td>
                    )}

                    {columns.map((column) => (
                      <td
                        key={column.key}
                        className={cn(cellVariants({ simple, align: column.align }))}
                      >
                        {typeof column.render === 'function'
                          ? column.render(row[column.dataIndex || column.key], row, index)
                          : row[column.dataIndex || column.key]}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr className="h-16">
                <td
                  colSpan={isRowSelectable ? columns.length + 1 : columns.length}
                  className={cn('text-muted-foreground', cellVariants({ simple, align: 'center' }))}
                >
                  {hasError ? 'Error fetching results' : emptyText || 'No results found'}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
