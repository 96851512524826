import { OrderDTO } from '@invenco/common-interface/sales';

import { Input, Select } from 'antd';
import { FormModal } from 'components/form-modal';
import { Form } from '../../../../../../components/form';
import { Result } from '../../../../../../shared/helpers/Result';
import { useShippingModalComponent } from './useShippingModalComponent';

type Props = {
  preload?: boolean;
  isOpen: boolean;
  onClose: () => void;
  orderDetails: Partial<OrderDTO>;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function ShippingModal({ preload, isOpen, orderDetails, onClose, updateOrder }: Props) {
  const {
    models: { isSaving, isLoading, form, initialValues, shippingProfiles },
    operations: { submit },
  } = useShippingModalComponent({ preload, isOpen, orderDetails, updateOrder, onClose });
  return (
    <FormModal
      open={isOpen}
      title="Shipping"
      onCancel={onClose}
      onSave={() => form.submit()}
      isSaving={isSaving}
    >
      <Form
        form={form}
        layout="vertical"
        name="shipping"
        onFinish={(data) => void submit(data)}
        initialValues={initialValues}
      >
        {orderDetails.shippingDescription && (
          <Form.Item label="Shipping Description">{orderDetails.shippingDescription}</Form.Item>
        )}

        <Form.Item
          name="shippingProfileName"
          label="Shipping Profile"
          rules={[{ required: true, message: 'Please select a shipping profile' }]}
        >
          <Select loading={isLoading}>
            {shippingProfiles.map(({ name }) => (
              <Select.Option key={name} value={name}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="subtotalShipping"
          label="Shipping Charge"
          rules={[
            { required: true, message: 'Please enter a shipping charge' },
            { pattern: /^\d+(\.\d{1,2})?$/, message: 'Must be a valid monetary amount' },
          ]}
          normalize={(val, prev) => (/^\d*\.?\d{0,2}$/.test(val) ? val : prev)}
        >
          <Input />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
