import { ReactNode } from 'react';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  Breadcrumbs,
  BreadcrumbSeparator,
} from '@/components-shad/ui/breadcrumbs';
import { LoaderCircleIcon } from 'lucide-react';
import { useHeaderComponent } from '@/components/header/useHeaderComponent';
import { SidebarTrigger } from '@/components-shad/ui/sidebar';
import { Separator } from '@/components-shad/ui/separator';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { UserMenu } from '@/components-shad/header/user-menu/UserMenu';

export type Breadcrumb = {
  title: string;
  url?: string;
  loading?: boolean;
};

type Props = {
  /** Show whether the Header is loading */
  loading?: boolean;

  /** Show whether the description is loading */
  loadDescription?: boolean;

  /** The breadcrumbs of the page */
  breadcrumbs?: Breadcrumb[];

  /** Title of the current page */
  title?: string;

  /** Title of the HTML document */
  documentTitle?: string;

  /** Description of the page */
  description?: ReactNode;

  /** URL to go back */
  // backURL?: string;

  /** Actions that can be performed on the page */
  actions?: ReactNode;

  /** Extra content */
  // extraContent?: ReactNode;
  onRefresh?: () => Promise<void>;

  editable?: boolean;
  editInitially?: boolean;
  // editPlaceholder?: string;
  // editAriaLabel?: string;
  onSave?: (title: string) => void;
};

export function Header({
  loading = false,
  loadDescription = false,
  breadcrumbs,
  title,
  documentTitle,
  description,
  actions,
  onRefresh,
  editable = false,
  editInitially = false,
  onSave,
}: Props) {
  const {
    models: { isFetching },
  } = useHeaderComponent({
    isEditable: editable,
    editInitially,
    documentTitle: documentTitle || title,
    onSave,
    onRefresh,
  });

  return (
    <header className="-mx-[--page-inset] -mt-[--page-inset] [&>div]:px-[--page-inset]">
      <div className="flex min-h-12 items-center justify-between border-b sm:min-h-16">
        <div className="flex items-center gap-4">
          <SidebarTrigger />
          {breadcrumbs && breadcrumbs.length > 0 && (
            <>
              <Separator orientation="vertical" className="h-4" />
              <Breadcrumbs>
                {breadcrumbs.map((item, index) => (
                  <BreadcrumbItem key={item.url || item.title}>
                    {item.loading ? (
                      <Skeleton className="h-4 w-24" />
                    ) : (
                      <BreadcrumbLink href={item.url}>{item.title}</BreadcrumbLink>
                    )}
                    {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </BreadcrumbItem>
                ))}
              </Breadcrumbs>
            </>
          )}
          {isFetching && <LoaderCircleIcon className="size-4 animate-spin text-muted-foreground" />}
        </div>

        <UserMenu />
      </div>

      <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-3 border-b py-4 sm:flex-nowrap sm:py-8">
        <div className="flex flex-col gap-2 sm:gap-2.5">
          <h1 className="text-2xl font-bold sm:text-3xl">
            {loading ? <Skeleton className="h-9 w-72" /> : title}
          </h1>
          {loadDescription && loading ? (
            <Skeleton className="h-6 w-64" />
          ) : (
            description && (
              <p className="text-sm text-muted-foreground sm:text-base">{description}</p>
            )
          )}
        </div>

        {actions && <div className="flex items-center gap-4">{actions}</div>}
      </div>
    </header>
  );
}
