import React from 'react';
import { useNavigate } from 'react-router';
import { Auth0Provider as BaseProvider } from '@auth0/auth0-react';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'env';

export function Auth0Provider({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <BaseProvider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        void navigate(appState?.returnTo || window.location.pathname);
      }}
    >
      {children}
    </BaseProvider>
  );
}
