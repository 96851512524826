import { ExclamationOutlined } from '@ant-design/icons';
import {
  OrderDTO,
  OrderFulfillmentStatus,
  OrderHoldStatus,
  OrderHoldType,
  OrderProcessStatusDTO,
  OrderProcessTypeDTO,
} from '@invenco/common-interface/sales';
import React, { useMemo } from 'react';
import { Card } from '../../../../../../components/card';
import {
  Chevron,
  ChevronContainer,
  DescriptionWrapper,
  Progress,
  ProgressContainer,
  Step,
  Steps,
  TitleWrapper,
} from './styles';

const OrderProgressState = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
};

const OrderProgressName = {
  SHIPPING_ADDRESS_VALIDATION: OrderProcessTypeDTO.SHIPPING_ADDRESS_VALIDATION,
  RESERVE_INVENTORY: OrderProcessTypeDTO.RESERVE_INVENTORY,
  AUTOMATION: OrderProcessTypeDTO.AUTOMATION,
  MANUAL_HOLD: 'MANUAL_HOLD',
  FULFILLMENT: 'FULFILLMENT',
};

const PROGRESS_TRACKER_ENTRIES = {
  [OrderProgressName.RESERVE_INVENTORY]: {
    title: 'Inventory',
    subtitles: {
      [OrderProgressState.NOT_STARTED]: 'Not started',
      [OrderProgressState.IN_PROGRESS]: 'Reserving',
      [OrderProgressState.FINISHED]: 'Completed',
    },
  },
  [OrderProgressName.SHIPPING_ADDRESS_VALIDATION]: {
    title: 'Address',
    subtitles: {
      [OrderProgressState.NOT_STARTED]: 'Not started',
      [OrderProgressState.IN_PROGRESS]: 'Validating',
      [OrderProgressState.FINISHED]: 'Completed',
      [OrderProgressState.ERROR]: 'Error',
    },
  },
  [OrderProgressName.AUTOMATION]: {
    title: 'Automation',
    subtitles: {
      [OrderProgressState.NOT_STARTED]: 'Not started',
      [OrderProgressState.IN_PROGRESS]: 'Processing',
      [OrderProgressState.FINISHED]: 'Completed',
    },
  },
  [OrderProgressName.MANUAL_HOLD]: {
    title: 'Manual Hold',
    subtitles: {
      [OrderProgressState.NOT_STARTED]: 'Not started',
      [OrderProgressState.IN_PROGRESS]: 'Processing',
      [OrderProgressState.FINISHED]: 'Released',
    },
    completeByDefault: true,
  },
  [OrderProgressName.FULFILLMENT]: {
    title: 'Fulfillment',
    subtitles: {
      [OrderProgressState.NOT_STARTED]: 'Pending',
      [OrderProgressState.IN_PROGRESS]: 'In Progress',
      [OrderProgressState.FINISHED]: 'Shipped',
    },
  },
};

const HOLD_ENTRY_INFO = {
  [OrderHoldType.ITEM_NOT_FOUND]: {
    entryName: OrderProgressName.RESERVE_INVENTORY,
    description: 'Item Not Found',
  },
  [OrderHoldType.RESERVE_INVENTORY_PROCESS]: {
    entryName: OrderProgressName.RESERVE_INVENTORY,
    description: 'Backorder',
  },
  [OrderHoldType.SHIPPING_ADDRESS_PROCESS]: {
    entryName: OrderProgressName.SHIPPING_ADDRESS_VALIDATION,
    description: 'Invalid',
  },
  [OrderHoldType.MANUAL_HOLD]: {
    entryName: OrderProgressName.MANUAL_HOLD,
    description: 'Applied',
  },
};

type ProgressTrackerProps = {
  title: string;
  description: string;
  state: string;
  percent?: number;
};

function ProgressTracker({ title, description, state, percent }: ProgressTrackerProps) {
  let status;
  let icon;
  let color;
  let adjustedPercent;

  switch (state) {
    case OrderProgressState.IN_PROGRESS:
      adjustedPercent = percent ?? 50;
      break;
    case OrderProgressState.FINISHED:
      adjustedPercent = 100;
      break;
    case OrderProgressState.ERROR:
      adjustedPercent = 100;
      status = 'exception';
      break;
    case OrderProgressState.WARNING:
      adjustedPercent = 100;
      icon = <ExclamationOutlined />;
      color = 'var(--orange-6)';
      break;
    case OrderProgressState.NOT_STARTED:
    default:
      adjustedPercent = 0;
  }

  return (
    <Step>
      <ProgressContainer>
        <Progress
          type="circle"
          width={40}
          percent={adjustedPercent}
          status={status}
          strokeColor={color}
          format={icon ? () => icon : undefined}
        />
      </ProgressContainer>
      <div>
        <TitleWrapper>{title}</TitleWrapper>
        <DescriptionWrapper>{description}</DescriptionWrapper>
      </div>
    </Step>
  );
}

type Props = {
  order?: Partial<OrderDTO>;
};
export function OrderProgressTracker({ order = {} }: Props) {
  const entries = useMemo(() => {
    const { orderProcesses, orderHolds, fulfillmentStatus, allowPartialFulfillments, orderLines } =
      order;

    const isPartial =
      allowPartialFulfillments && orderLines?.some(({ status }) => status === 'PARTIAL');

    // Initial progress data
    const progressData = Object.entries(PROGRESS_TRACKER_ENTRIES).map(([name, entry]) => {
      let state = entry.completeByDefault
        ? OrderProgressState.FINISHED
        : OrderProgressState.NOT_STARTED;

      // if this entry has an associated process, pull the current state from it
      const process = orderProcesses?.find((orderProcess) => orderProcess.type === name);
      if (
        process &&
        [OrderProcessStatusDTO.PENDING, OrderProcessStatusDTO.COMPLETE].includes(process.status)
      ) {
        state =
          process.status === OrderProcessStatusDTO.PENDING
            ? OrderProgressState.IN_PROGRESS
            : OrderProgressState.FINISHED;
      }

      // special case for fulfillment
      if (name === OrderProgressName.FULFILLMENT) {
        if (fulfillmentStatus === OrderFulfillmentStatus.CLOSED) {
          state = OrderProgressState.FINISHED;
        } else if (fulfillmentStatus && ['OPEN', 'PARTIAL'].includes(fulfillmentStatus)) {
          state = OrderProgressState.IN_PROGRESS;
        }
      }

      // special case for partial fulfillment in inventory
      let color;
      let description;
      let icon;
      if (
        name === OrderProgressName.RESERVE_INVENTORY &&
        process?.status === OrderProcessStatusDTO.COMPLETE &&
        isPartial
      ) {
        state = OrderProgressState.WARNING;
        description = 'Partially Committed';
      }

      return {
        name,
        title: entry.title,
        description: description || entry.subtitles[state],
        state,
        color,
        icon,
      };
    });

    // Update tracker data with holds and errors
    if (orderHolds) {
      orderHolds.forEach(({ type, status }) => {
        const entryInfo = HOLD_ENTRY_INFO[type];
        if (entryInfo && status === OrderHoldStatus.HOLD) {
          const trackerEntry = progressData.find(({ name }) => name === entryInfo.entryName);
          if (trackerEntry) {
            trackerEntry.description = entryInfo.description;
            trackerEntry.state = OrderProgressState.ERROR;
          }
        }
      });
    }

    return progressData;
  }, [order]);

  return (
    <Card slim>
      <Steps>
        {entries.map((entry, index) => (
          <React.Fragment key={entry.name}>
            <ProgressTracker {...entry} />
            {index < entries.length - 1 && (
              <ChevronContainer>
                <Chevron />
              </ChevronContainer>
            )}
          </React.Fragment>
        ))}
      </Steps>
    </Card>
  );
}
