import React from 'react';
import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
  Text,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { TextArea } from '@/components-shad/ui/text-area';
import { Input } from '@/components-shad/ui/input';
import { FieldError, Label } from './field';

export const BaseTextField = AriaTextField;

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  textArea?: boolean;
}

export const TextField = React.forwardRef<React.ElementRef<typeof BaseTextField>, TextFieldProps>(
  ({ label, description, errorMessage, textArea, className, ...props }, ref) => (
    <BaseTextField
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      {textArea ? <TextArea /> : <Input />}
      {description && (
        <Text className="text-sm text-muted-foreground" slot="description">
          {description}
        </Text>
      )}
      <FieldError>{errorMessage}</FieldError>
    </BaseTextField>
  ),
);
