import { Link } from 'react-router';
import { Button } from 'components/button';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useShippingPriceDetailsPage } from './useShippingPriceDetailsPage';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
} from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { DetailsCard } from '../../../../components/details-card';
import { DetailsBlock } from '../../../../components/details-block';
import { DetailList } from '../../../../components/detail-list/DetailList';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';
import { formatDate } from '../../../../shared/helpers';
import { PriceBands } from './price-bands/PriceBands';
import { DetailsModal } from './details-modal/DetailsModal';
import { BottomContainer } from './styles';
import { CarrierSelectionModal } from './carrier-selection-modal/CarrierSelectionModal';
import { ActionButton } from './price-bands/styles';

export function ShippingPriceDetails() {
  const {
    models: {
      isNew,
      isSaving,
      isLoading,
      isLoadingAccounts,
      isLoadingCarriers,
      isDetailsModalOpen,
      hasChanges,
      canSave,
      shippingPrice,
      isCarrierSelectionModalOpen,
      accounts,
      account,
      location,
      carrierOptions,
      breadcrumbs,
    },
    operations: {
      refresh,
      updateDetails,
      save,
      addNewGroup,
      removeGroup,
      addBand,
      addGroupBand,
      updateBand,
      updateGroupBand,
      removeBand,
      removeGroupBand,
      openDetailsModal,
      closeDetailsModal,
      openCarrierSelectionModal,
      closeCarrierSelectionModal,
    },
  } = useShippingPriceDetailsPage();

  return (
    <Container>
      <Header
        title={shippingPrice.name}
        documentTitle={isNew ? 'New Shipping Price' : shippingPrice.name}
        backURL="/shipping/prices"
        links={breadcrumbs}
        loading={isLoading}
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          <Button
            type="primary"
            onClick={() => void save()}
            disabled={!canSave || !hasChanges}
            loading={isSaving}
          >
            Save
          </Button>
        }
        editable
        editInitially={isNew}
        editPlaceholder="Enter shipping price name..."
        editAriaLabel="Shipping price name"
        onSave={(name) => updateDetails({ name })}
      />

      <DetailContainer>
        <MainContainer>
          <Card
            title="Price bands"
            count={shippingPrice.priceBands?.length ?? 0}
            description={
              "Each band's markup is applied in sequence as an additional percentage of the shipping cost. " +
              "The markup is applied to the portion of the cost between the previous band's (if any) maximum " +
              "and this band's maximum."
            }
          >
            <PriceBands
              loading={isLoading}
              priceBands={shippingPrice.priceBands ?? []}
              addBand={addBand}
              updateBand={updateBand}
              removeBand={removeBand}
            />
          </Card>
          {shippingPrice.carrierPrices?.map((carrierShippingPrice, groupIndex) => (
            <Card
              key={carrierShippingPrice.carrierId}
              title={`${carrierShippingPrice.carrierName} price bands`}
              count={carrierShippingPrice.priceBands?.length ?? 0}
              rightContent={
                <ActionButton
                  type="text"
                  danger
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => removeGroup(groupIndex)}
                  aria-label="Delete"
                />
              }
            >
              <PriceBands
                loading={isLoading}
                priceBands={carrierShippingPrice.priceBands ?? []}
                addBand={(band) => addGroupBand(groupIndex, band)}
                updateBand={(bandIndex, band) => updateGroupBand(groupIndex, bandIndex, band)}
                removeBand={(bandIndex) => removeGroupBand(groupIndex, bandIndex)}
              />
            </Card>
          ))}
          <BottomContainer>
            <Button inline type="link" icon={<PlusOutlined />} onClick={openCarrierSelectionModal}>
              Add new band group
            </Button>
          </BottomContainer>
        </MainContainer>

        <SideContainer>
          <DetailsCard title="Details" hasEditButton onEdit={openDetailsModal}>
            <DetailsBlock loading={isLoading} loadingRows={3}>
              <DetailList
                items={[
                  {
                    label: 'Account',
                    value: isLoadingAccounts ? (
                      <LoadingSkeleton />
                    ) : (
                      account && <Link to={`/accounts/${account.id}`}>{account.name}</Link>
                    ),
                  },
                  {
                    label: 'Valid From',
                    value: shippingPrice.validFrom && formatDate(shippingPrice.validFrom),
                  },
                  {
                    label: 'Valid To',
                    value: shippingPrice.validTo && formatDate(shippingPrice.validTo),
                  },
                  {
                    label: 'Location',
                    value: isLoadingAccounts ? <LoadingSkeleton /> : location?.name,
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <DetailsModal
        isOpen={isDetailsModalOpen}
        isNew={isNew}
        shippingPrice={shippingPrice}
        accounts={accounts}
        updateDetails={updateDetails}
        onClose={closeDetailsModal}
      />

      <CarrierSelectionModal
        isOpen={isCarrierSelectionModalOpen}
        loading={isLoadingCarriers}
        carriers={carrierOptions}
        onSave={addNewGroup}
        onClose={closeCarrierSelectionModal}
      />
    </Container>
  );
}
