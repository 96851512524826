import { CustomerDTO, OrderDTO } from '@invenco/common-interface/sales';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { useAutoCompleteQuery } from '../../../../../../shared/hooks/queries/useAutoCompleteQuery';
import { ComponentData } from '../../../../../../shared/types';

type Props = {
  updateOrder: (data: Partial<OrderDTO>) => any;
};

type Models = {
  isLoading: boolean;
  options: CustomerDTO[];
};

type Operations = {
  search: (query: string) => void;
  selectOption: (customer: CustomerDTO) => void;
};

export function useCustomerSearchComponent({
  updateOrder,
}: Props): ComponentData<Models, Operations> {
  const { salesGateway } = useGateways();
  const {
    options,
    onSearch: search,
    isLoading,
  } = useAutoCompleteQuery({
    parentKey: 'customers',
    query: (params, { signal }) => salesGateway.getCustomers(params, { signal }),
  });

  const selectOption = (customer: CustomerDTO) =>
    updateOrder({
      customerId: customer.id,
      customerName: customer.name,
      email: customer.email,
      phone: customer.phone,
      shippingAddress: customer.shippingAddress,
      billingAddress: customer.billingAddress,
    });

  return {
    models: { options, isLoading },
    operations: { search, selectOption },
  };
}
