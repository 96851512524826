import React from 'react';
import { type VariantProps } from 'class-variance-authority';
import {
  composeRenderProps,
  Link as AriaLink,
  LinkProps as AriaLinkProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { buttonVariants } from './button';

export interface LinkProps extends AriaLinkProps, VariantProps<typeof buttonVariants> {}

export const Link = React.forwardRef<React.ElementRef<typeof AriaLink>, LinkProps>(
  ({ className, variant, size, children, ...props }, ref) => (
    <AriaLink
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          buttonVariants({
            variant,
            size,
            className: composedClassName,
          }),
        ),
      )}
      {...props}
    >
      {children}
    </AriaLink>
  ),
);
