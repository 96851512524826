import { SidebarMenuButton } from '@/components-shad/ui/sidebar';
import { ChevronsUpDownIcon } from 'lucide-react';
import { Input } from '@/components-shad/ui/input';
import { AccessContext } from '@invenco/common-interface/auth';
import { Popover, PopoverTrigger } from '@/components-shad/ui/popover';
import {
  Combobox,
  ComboboxCollection,
  ComboboxHeader,
  ComboboxItem,
  ComboboxListBox,
  ComboboxSection,
} from '@/components-shad/ui/combobox';
import { MembershipGroup, useAccountMenuComponent } from './useAccountMenuComponent';
import { MembershipDetail } from './MembershipDetail';
import { getMembershipName } from './membershipName';

const contextToGroupTitle = {
  [AccessContext.SELLER]: 'Accounts',
  [AccessContext.WAREHOUSE]: 'Warehouses',
  [AccessContext.PLATFORM]: 'Other',
  [AccessContext.SYSTEM]: 'System',
};

export function AccountMenu() {
  const {
    models: {
      isLoading,
      isMenuOpen,
      changingToMembership,
      hasMultipleMemberships,
      hasNonSellerMemberships,
      query,
      activeMembership,
      membershipGroups,
    },
    operations: { setIsMenuOpen, search, selectMembership },
  } = useAccountMenuComponent();

  if (!hasMultipleMemberships) {
    return (
      <MembershipDetail active membership={activeMembership} loading={isLoading} className="p-2" />
    );
  }
  return (
    <PopoverTrigger isOpen={isMenuOpen} onOpenChange={setIsMenuOpen}>
      <SidebarMenuButton
        size="lg"
        className="aria-expanded:bg-sidebar-accent aria-expanded:text-sidebar-accent-foreground"
        isPending={isLoading}
      >
        <MembershipDetail
          membership={changingToMembership ?? activeMembership}
          loading={isLoading || !!changingToMembership}
          active
        />
        <ChevronsUpDownIcon className="ml-auto size-4" />
      </SidebarMenuButton>
      <Popover>
        {/*
         * Note - Below we are borrowing parts of a Combobox to render a search input followed by
         * a list of memberships within a popup. Normally a Combobox would contain a <ComboboxPopover>
         * component which expands/collapses the list of items. Our combobox below always shows the
         * input and the list at all times, because it already inside a <Popover>. In the future
         * we might replace this Combobox workaround with a custom component, or perhaps
         * an Autocomplete https://react-spectrum.adobe.com/react-aria/Autocomplete.html
         */}
        <Combobox
          items={membershipGroups}
          inputValue={query}
          onInputChange={search}
          selectedKey={activeMembership?.id}
          onSelectionChange={(id) => {
            // react-aria-combobox fails to infer type of id from its items prop and instead uses a
            // generic `Key` type allowing string | number | null whereas AccountMenu items always
            // use string
            if (typeof id === 'string') {
              void selectMembership(id);
            }
          }}
          allowsCustomValue
          shouldFocusWrap
          menuTrigger="focus" // these two are required to display values by default
          autoFocus
        >
          <Input
            placeholder="Search accounts..."
            className="rounded-b-none border-0 border-b shadow-none"
          />
          <ComboboxListBox<MembershipGroup>
            renderEmptyState={() => (
              <div className="p-2 text-center text-sm text-muted-foreground">
                No matching accounts
              </div>
            )}
          >
            {(membershipGroup) => (
              <ComboboxSection id={membershipGroup.context}>
                {hasNonSellerMemberships && (
                  <ComboboxHeader>{contextToGroupTitle[membershipGroup.context]}</ComboboxHeader>
                )}
                <ComboboxCollection items={membershipGroup.memberships}>
                  {(membership) => (
                    <ComboboxItem
                      id={membership.id}
                      aria-label={`Switch to ${getMembershipName(membership)}`}
                      isDisabled={!!changingToMembership || membership.id === activeMembership?.id}
                    >
                      <MembershipDetail
                        membership={membership}
                        loading={changingToMembership?.id === membership.id}
                      />
                    </ComboboxItem>
                  )}
                </ComboboxCollection>
              </ComboboxSection>
            )}
          </ComboboxListBox>
        </Combobox>
      </Popover>
    </PopoverTrigger>
  );
}
