import { FulfillmentDTO } from '@invenco/common-interface/sales';
import { ShipmentDTO } from '@invenco/common-interface/shipping';
import { useMemo } from 'react';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { useQueryWithInput } from '../../../../../../shared/hooks/queries';
import { ComponentData } from '../../../../../../shared/types';

type Props = {
  fulfillments: FulfillmentDTO[];
};

type Models = {
  shipmentsByFulfillmentId: Record<string, ShipmentDTO[]>;
  shipDateByFulfillmentId: Record<string, string | undefined>;
};

export function useOrderFulfillmentsComponent({ fulfillments }: Props): ComponentData<Models> {
  const { shippingGateway } = useGateways();
  const { data } = useQueryWithInput({
    parentKey: 'shipments',
    input: { shippableIds: fulfillments.map((f) => f.id!) },
    query: (input) => shippingGateway.getShipments(input),
    enabled: fulfillments.length > 0,
  });

  const shipmentsByFulfillmentId = useMemo(() => {
    const map: Record<string, ShipmentDTO[]> = {};

    if (data?.items) {
      data.items.forEach((shipment) => {
        const id = shipment.shippableId;
        if (!id) return;
        if (!map[id]) {
          map[id] = [];
        }
        map[id].push(shipment);
      });
    }

    fulfillments.forEach(({ id }) => {
      if (!id) return;
      map[id] = map[id] ?? [];
    });
    return map;
  }, [data, fulfillments]);

  const shipDateByFulfillmentId = useMemo<Record<string, string | undefined>>(
    () =>
      Object.fromEntries(
        fulfillments.map(({ id }) => [
          id,
          (shipmentsByFulfillmentId[id!] ?? []).reduce<string | undefined>(
            (current, { shippedAt }) =>
              !current || (shippedAt && shippedAt > current) ? shippedAt : current,
            undefined,
          ),
        ]),
      ),
    [fulfillments, shipmentsByFulfillmentId],
  );

  return {
    models: {
      shipmentsByFulfillmentId,
      shipDateByFulfillmentId,
    },
  };
}
