import {
  ChildConditionType,
  ConditionOperator,
  PropertyConditionName,
  WorkflowDataType,
  WorkflowStatus,
  WorkflowTriggerType,
} from '@invenco/common-domain/enums';
import {
  ShippingMethodAutoInvite,
  ShippingMethodRateCalculationMethod,
  ShippingMethodRateMatchMethod,
} from '@invenco/common-domain/enums/ShippingMethod';
import {
  BillStatus,
  InvoiceStatus,
  LocationStatus,
  ServiceAgreementStatus,
} from '@invenco/common-interface/accounts';
import { LocationInventoryStatus } from '@invenco/common-interface/inventory';
import { SkuSelectionMethod, SkuType } from '@invenco/common-interface/products';
import {
  ListingStatus,
  ListingStrategy,
  OrderAction,
  OrderHoldSubtype,
  OrderHoldType,
  OrderLineStatus,
  OrderStatus,
} from '@invenco/common-interface/sales';
import { AddressType } from '@invenco/common-interface/shared';
import {
  CarrierServiceCategory,
  CarrierServiceRateMatchMethod,
  ShipmentStatus,
} from '@invenco/common-interface/shipping';
import { AsnStatus } from '@invenco/common-interface/supply';
import { EnumMap } from './types';

export const asnStatusTitle: EnumMap<AsnStatus> = {
  DRAFT: 'Draft',
  PENDING: 'Pending',
  OPEN: 'Booked',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
};
export const orderStatusTitle: EnumMap<OrderStatus> = {
  draft: 'Draft',
  pending: 'Pending',
  hold: 'Hold',
  open: 'Open',
  closed: 'Closed',
  cancelled: 'Cancelled',
};
export const orderLineStatusTitle: EnumMap<OrderLineStatus> = {
  BACKORDER: 'Backorder',
  CLOSED: 'Closed',
  ITEM_NOT_FOUND: 'Invalid SKU',
  OPEN: 'Open',
  PARTIAL: 'Partial',
  PENDING: 'Pending',
};
export const orderActionTitle: EnumMap<OrderAction> = {
  APPLY_MANUAL_HOLD: 'Hold',
  CANCEL: 'Cancel Order',
  CLOSE: 'Close Order',
  RELEASE: 'Release',
  RELEASE_MANUAL_HOLD: 'Release hold',
  REVERT_TO_DRAFT: 'Edit',
};
export const shipmentStatusTitle: EnumMap<ShipmentStatus> = {
  DRAFT: 'Draft',
  WAITING: 'Waiting',
  PROCESSING: 'Processing',
  SHIPPED: 'Shipped',
  CANCELLED: 'Cancelled',
};
export const skuTypeTitle: EnumMap<SkuType> = {
  STANDARD: 'Standard',
  BUNDLE: 'Bundle',
  MYSTERY: 'Mystery',
};
export const orderHoldTypeTitle: EnumMap<OrderHoldType> = {
  AUTOMATION_PROCESS: 'Automation',
  RESERVE_INVENTORY_PROCESS: 'Backorder',
  SHIPPING_ADDRESS_PROCESS: 'Invalid Address',
  MANUAL_HOLD: 'Manual Hold',
  ITEM_NOT_FOUND: 'Invalid SKU',
  SHIPPING_HOLD: 'Shipping Hold',
};
export const orderHoldSubtypeTitle: EnumMap<OrderHoldSubtype> = {
  NO_PREFERRED_RATE: 'No Preferred Rate',
  SHIPPING_PROFILE_NOT_FOUND: 'Shipping Profile Not Found',
};

export const listingStatusTitle: EnumMap<ListingStatus> = {
  ACTIVE: 'Active',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
};
export const listingStrategyTitle: EnumMap<ListingStrategy> = {
  PRESELL: 'Presell',
  DYNAMIC_AVAILABLE: 'Dynamic',
  SOLD_OUT: 'Sold out',
  STATIC_RINGFENCE: 'Static',
};
export const locationInventoryStatusTitle: EnumMap<LocationInventoryStatus> = {
  in_stock: 'In Stock',
  out_of_stock: 'Out of Stock',
};
export const carrierServiceCategoryTitle: EnumMap<CarrierServiceCategory> = {
  STANDARD: 'Standard',
  EXPRESS: 'Express',
  PICK_UP: 'Pick Up',
  SAME_DAY: 'Same Day',
};
export const carrierServiceRateMatchMethodTitle: EnumMap<CarrierServiceRateMatchMethod> = {
  ALL: 'All',
  STATE_STATE: 'By State',
  COUNTRY_COUNTRY: 'By Country',
  TO_POSTCODE_RANGE: 'To Postcode Range',
};
export const shippingMethodRateMatchMethodTitle: EnumMap<ShippingMethodRateMatchMethod> = {
  ALL: 'All',
  STATE_STATE: 'By State',
  COUNTRY_COUNTRY: 'By Country',
  TO_POSTCODE_RANGE: 'To Postcode Range',
};
export const shippingMethodAutoInviteTitle: EnumMap<ShippingMethodAutoInvite> = {
  NONE: 'None',
  MANAGED_LOCATIONS: 'Managed Locations',
};
export const shippingMethodRateCalculationMethodTitle: EnumMap<ShippingMethodRateCalculationMethod> =
  {
    PER_CONSIGNMENT: 'Per Consignment',
    PER_PACKAGE: 'Per Package',
  };

export const locationStatusTitle: EnumMap<LocationStatus> = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
};

export const workflowStatusTitle: EnumMap<WorkflowStatus> = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};
export const workflowDataTypeTitle: EnumMap<WorkflowDataType> = {
  ORDER: 'Order',
  WAREHOUSE_SHIPMENT: 'Warehouse Shipment',
};
export const workflowTriggerTypeTitle: EnumMap<WorkflowTriggerType> = {
  ORDER_RELEASED: 'Order Released',
  WAREHOUSE_SHIPMENT_EXPORT: 'Warehouse Shipment Exported',
};
export const propertyConditionNameTitle: EnumMap<PropertyConditionName> = {
  channelName: 'Channel',
  channelCategory: 'Channel Category',
  channelBrandName: 'Channel Brand Name',
  countryCode: 'Country Code',
  currency: 'Currency',
  orderedAt: 'Ordered At',
  skuName: 'SKUs',
  totalItems: 'Total Items',
  totalPrice: 'Total Price',
  totalVolume: 'Total Volume',
  email: 'Email',
  customerName: 'Customer Name',
  customerOrderNumber: 'Customer Order Number',
  shippingAddressCustomerName: 'Shipping Address Customer Name',
  shippingAddressEmail: 'Shipping Address Email',
  shippingAddressState: 'Shipping Address State',
  billingAddressCustomerName: 'Billing Address Customer Name',
  billingAddressEmail: 'Billing Address Email',
  shippingProfileName: 'Shipping Profile Name',
  tagName: 'Tags',
  ORDER_EMAIL: 'Email (multiple)',
  ORDER_CUSTOMER_NAME: 'Customer Name (multiple)',
  WAREHOUSE_SHIPMENT_WAREHOUSE_CODE: 'Shipment Warehouse Code',
  WAREHOUSE_SHIPMENT_TENANT_CODE: 'Shipment Tenant Code',
  WAREHOUSE_SHIPMENT_CHANNEL_NAME: 'Channel Name',
  WAREHOUSE_SHIPMENT_CHANNEL_CATEGORY: 'Channel Category',
  WAREHOUSE_SHIPMENT_CHANNEL_BRAND: 'Channel Brand',
  WAREHOUSE_SHIPMENT_FULFILLMENT_SERVICE_TYPE: 'Fulfillment Service Type',
  WAREHOUSE_SHIPMENT_TOTAL_ITEMS: 'Total Items',
  WAREHOUSE_SHIPMENT_TOTAL_WEIGHT: 'Total Weight',
  WAREHOUSE_SHIPMENT_TOTAL_VOLUME: 'Total Volume',
  WAREHOUSE_SHIPMENT_CARRIER_SERVICE: 'Carrier Service',
  WAREHOUSE_SHIPMENT_SHIPPING_ADDRESS_COUNTRY: 'Shipment Country',
  WAREHOUSE_SHIPMENT_CREATED_AT: 'Shipment Created At',
  ORDER_NAME: 'Order Name',
};
export const conditionOperatorTitle: EnumMap<ConditionOperator> = {
  CONTAINS: 'contains',
  DOES_NOT_CONTAIN: 'does not contain',
  EQUAL_TO: 'is',
  GREATER_THAN: 'is greater than',
  GREATER_THAN_OR_EQUAL_TO: 'is greater than or equal to',
  IN: 'is in',
  LESS_THAN: 'is less than',
  LESS_THAN_OR_EQUAL_TO: 'is less than or equal to',
  NOT_EQUAL_TO: 'is not',
  NOT_IN: 'is not in',
  STARTS_WITH: 'starts with',
  ENDS_WITH: 'ends with',
  STRING_CONTAINS: 'contains',
  EQUAL_TO_CASE_INSENSITIVE: 'is (case insensitive)',
  NOT_EQUAL_TO_CASE_INSENSITIVE: 'is not (case insensitive)',
  IN_CASE_INSENSITIVE: 'is in (case insensitive)',
  NOT_IN_CASE_INSENSITIVE: 'is not in (case insensitive)',
  CONTAINS_CASE_INSENSITIVE: 'contains (case insensitive)',
  DOES_NOT_CONTAIN_CASE_INSENSITIVE: 'does not contain (case insensitive)',
};
export const childConditionTypeTitle: EnumMap<ChildConditionType> = {
  ALL: 'AND',
  ANY: 'OR',
};

export const billStatusTitle: EnumMap<BillStatus> = {
  PENDING: 'Pending',
  OPEN: 'Open',
  CLOSED: 'Closed',
};
export const invoiceStatusTitle: EnumMap<InvoiceStatus> = {
  PENDING: 'Pending',
  OPEN: 'Open',
};

export const serviceAgreementStatusTitle: EnumMap<ServiceAgreementStatus> = {
  ACTIVE: 'Active',
  TERMINATED: 'Terminated',
};

export const addressTypeTitle: EnumMap<AddressType> = {
  RESIDENTIAL: 'Residential',
  POSTAL: 'Postal',
  BUSINESS: 'Business',
};

export const skuSelectionMethodTitle: EnumMap<SkuSelectionMethod> = {
  LEAST_AVAILABLE: 'Least Available',
  MOST_AVAILABLE: 'Most Available',
  MOST_UNIQUE_TO_ORDER: 'Most Unique to Order',
  RANDOM: 'Random',
};
