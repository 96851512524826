import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { SkuAssemblyComponentDTO, SkuType } from '@invenco/common-interface/products';
import { Button } from 'components/button';
import { FormModal } from 'components/form-modal';
import { InputNumber } from 'components/input';
import { ListTable } from 'components/list-table';
import { TableColumn } from 'components/list-table/types';
import { SkuSearch } from 'components/sku-search';
import { Result } from '../../../../../shared/helpers/Result';
import { VerticallySpaced } from '../../../../../styles/layout';
import { useBundleComponentModalComponent } from './useBundleComponentModalComponent';

type Props = {
  isOpen: boolean;
  skuType: SkuType;
  existingComponents?: Partial<SkuAssemblyComponentDTO>[];
  updateAssemblyComponents: (data: Partial<SkuAssemblyComponentDTO>[]) => Promise<Result>;
  onClose: () => void;
};

export function BundleComponentModal({
  isOpen,
  onClose,
  skuType,
  existingComponents,
  updateAssemblyComponents,
}: Props) {
  const {
    models: { components, excludeSkuIds, isSaving, canSave, canAdd },
    operations: { addComponent, deleteComponent, submit, updateComponent },
  } = useBundleComponentModalComponent({
    onClose,
    existingComponents,
    updateAssemblyComponents,
    isOpen,
  });

  const componentColumns: TableColumn<Partial<SkuAssemblyComponentDTO>>[] = [
    {
      title: 'SKU / Description',
      key: 'componentSku',
      render: (componentSku, { id }) => (
        <SkuSearch
          selectedSku={componentSku}
          onSelect={(sku) => id && updateComponent(id, { componentSku: sku })}
          queryOptions={{ excludeSkuIds, type: SkuType.STANDARD }}
          hideEdit
        />
      ),
    },
    {
      title: `Qty per ${skuType === SkuType.MYSTERY ? 'Mystery' : 'Bundle'}`,
      key: 'quantity',
      width: '130px',
      align: 'right',
      render: (qty, { id }, index) => (
        <InputNumber
          aria-label={`Quantity for component ${index + 1}`}
          type="number"
          value={qty}
          onChange={(value) => id && updateComponent(id, { quantity: +(value || 0) })}
          precision={0}
          min={1}
        />
      ),
    },
    {
      key: '',
      render: (_, { id }, index) => (
        <Button
          type="text"
          icon={<CloseOutlined />}
          aria-label={`Delete component ${index + 1}`}
          onClick={() => id && deleteComponent(id)}
        />
      ),
      align: 'right',
    },
  ];

  return (
    <FormModal
      title="Manage components"
      onSave={submit}
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      saveDisabled={!canSave}
      width={700}
    >
      <VerticallySpaced $factor={0.5}>
        <div>Add the SKUs and the required quantity per assembly to define the components</div>
        <ListTable
          simple
          columns={componentColumns}
          rows={components}
          hidePlaceholderText={!components.length}
        />
        {canAdd && (
          <Button type="link" inline icon={<PlusOutlined aria-hidden />} onClick={addComponent}>
            Add component
          </Button>
        )}
      </VerticallySpaced>
    </FormModal>
  );
}
