import { SkuDTO } from '@invenco/common-interface/products';
import { OrderLineDTO } from '@invenco/common-interface/sales';
import { useEffect, useMemo, useState } from 'react';
import { Result } from '../../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../../shared/types';
import { getOrderLineFromFormData, getOrderLineTotal } from './utils';

type Models = {
  isSelectingSku: boolean;
  isEditingNewLine: boolean;
  selectedLineIds: string[];
  processedLines: Partial<OrderLineDTO>[];
  selectedSku: SkuDTO | undefined;
};

type Operations = {
  selectLine: (orderLine: Partial<OrderLineDTO>) => void;
  closeLine: (orderLine: Partial<OrderLineDTO>) => void;
  save: (id: string, data: Record<string, any>) => Promise<Result<void>>;
  selectNewSku: (sku: SkuDTO | undefined) => void;
  showSkuSelection: () => void;
  validateForm: (data: Record<string, any>) => boolean;
};

type Props = {
  editable?: boolean;
  orderLines: Partial<OrderLineDTO>[];
  addOrderLine: (orderLine: Partial<OrderLineDTO>) => Promise<Result>;
  updateOrderLine: (lineId: string, orderLine: Partial<OrderLineDTO>) => Promise<Result>;
};

const NEW_ID = 'NEW';

export function useOrderLinesComponent({
  editable,
  orderLines,
  addOrderLine,
  updateOrderLine,
}: Props): ComponentData<Models, Operations> {
  const [selectedLineIds, setSelectedLineIds] = useState<string[]>([]);
  const [isSelectingSku, setIsSelectingSku] = useState(!!editable && !orderLines.length); // open initially for new/empty orders
  const [selectedSku, setSelectedSku] = useState<SkuDTO>();
  const isEditingNewLine = selectedLineIds.includes(NEW_ID);

  useEffect(() => {
    if (editable) {
      setSelectedLineIds((lineIds) => (lineIds.length > 1 ? [] : lineIds));
    }
  }, [editable]);

  const processedLines = useMemo(() => {
    const lines = [...orderLines];
    if (selectedSku) {
      lines.push({
        id: NEW_ID,
        skuName: selectedSku.name,
        description: selectedSku.description,
        unitPrice: selectedSku.sellPrice,
      });
    }
    return lines;
  }, [orderLines, selectedSku]);

  const closeLine = ({ id }: Partial<OrderLineDTO>) => {
    if (!id) return;
    setSelectedLineIds(selectedLineIds.filter((lineId) => lineId !== id));
    setSelectedSku(undefined);
    if (!orderLines.length && editable) {
      setIsSelectingSku(true);
    }
  };

  const selectLine = (line: Partial<OrderLineDTO>) => {
    const { id } = line;
    if (!id) return;

    if (selectedLineIds.includes(id)) {
      // reselecting a line acts as a toggle when not editing (when editing the user will use cancel button)
      if (!editable) closeLine(line);
      return;
    }

    setSelectedLineIds(editable ? [id] : [...selectedLineIds, id]);
    setIsSelectingSku(false);
    setSelectedSku(undefined);
  };

  const save = async (id: string, data: Record<string, string>) => {
    const lineData = getOrderLineFromFormData(data);

    if (id !== NEW_ID) {
      return updateOrderLine(id, lineData);
    }
    if (selectedSku) {
      setSelectedSku(undefined);
      return addOrderLine({ skuId: selectedSku.id, skuName: selectedSku.name, ...lineData });
    }
    return Result.fail('invalid operation');
  };

  const selectNewSku = (sku: SkuDTO | undefined) => {
    setIsSelectingSku(false);
    setSelectedSku(sku);
    if (sku) {
      setSelectedLineIds([NEW_ID]);
    }
  };

  const showSkuSelection = () => {
    setIsSelectingSku(true);
    setSelectedLineIds([]);
  };

  const validateForm = (data: Record<string, string>) => {
    const total = getOrderLineTotal(getOrderLineFromFormData(data));
    return !Number.isNaN(total) && total >= 0;
  };

  return {
    models: {
      isSelectingSku,
      isEditingNewLine,
      selectedLineIds,
      processedLines,
      selectedSku,
    },
    operations: {
      selectLine,
      closeLine,
      showSkuSelection,
      selectNewSku,
      save,
      validateForm,
    },
  };
}
