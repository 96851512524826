import { Select } from 'antd';
import { Form } from 'components/form';
import { CarrierDTO } from '@invenco/common-interface/shipping';
import { FormModal } from '../../../../../components/form-modal';
import { useCarrierSelectionModal } from './useCarrierSelectionModal';

type Props = {
  isOpen: boolean;
  loading: boolean;
  carriers: CarrierDTO[];
  onSave: (carrier: CarrierDTO) => void;
  onClose: () => void;
};

export function CarrierSelectionModal({ isOpen, loading, carriers, onSave, onClose }: Props) {
  const {
    models: { form },
    operations: { filter, submit },
  } = useCarrierSelectionModal({ carriers, onSave, onClose });
  return (
    <FormModal
      title="Select Carrier"
      saveTitle="Select"
      open={isOpen}
      onSave={() => form.submit()}
      onCancel={onClose}
    >
      <Form layout="vertical" name="carrierSelection" form={form} onFinish={(data) => submit(data)}>
        <Form.Item
          name="carrierId"
          label="Carrier"
          rules={[{ required: true, message: 'Please select a carrier' }]}
        >
          <Select
            showSearch
            filterOption={filter}
            options={
              loading
                ? []
                : carriers.map((carrier) => ({
                    label: carrier.name,
                    value: carrier.id,
                  }))
            }
          />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
