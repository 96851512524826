import { OrderHoldType, OrderStatus } from '@invenco/common-interface/sales';

import { Link as RouterLink } from 'react-router';

import { Card } from 'components/card';
import { Header } from 'components/header';
import { Tooltip } from 'components/tooltip';
import { formatDateTime, formatMoney, formatNumber } from 'shared/helpers';
import { Container, HorizontallySpaced, ListContainer, VerticallySpaced } from 'styles/layout';

import { Label, StatusLabel } from 'components/label';
import { orderStatusType } from 'shared/status-maps';
import { orderHoldSubtypeTitle, orderHoldTypeTitle, orderStatusTitle } from 'shared/title-maps';
import { TemplateKey } from 'components/data-importer/types';
import { DataImporter } from 'components/data-importer';
import { Button } from 'components/button';
import { useOrderListPage, OrderRow, views } from './useOrderListPage';
import { DataTable, Column } from '../../../../../components/data-table';
import { FilterOption } from '../../../../../components/data-table/filter-panel/types';

const prefixColumns: Column<OrderRow>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Reference', key: 'reference' },
  {
    title: 'Customer',
    key: 'customerName',
  },
  { title: 'Channel', key: 'channelName' },
];

const columns: Column<OrderRow>[] = [
  ...prefixColumns,
  {
    title: 'Status',
    key: 'status',
    render: (status) => (
      <StatusLabel status={status} type={orderStatusType} title={orderStatusTitle} />
    ),
  },
  { title: 'Quantity', align: 'right', key: 'qtyOrdered', render: (qty) => formatNumber(qty) },
  {
    title: 'Total',
    align: 'right',
    key: 'totalPrice',
    render: (total, { currency }) => formatMoney(total, currency),
  },
  {
    title: 'Ordered',
    key: 'orderedAt',
    render: (date) => (date ? formatDateTime(date) : ''),
    align: 'right',
  },
];

const holdColumns: Column<OrderRow>[] = [
  ...prefixColumns,
  {
    title: 'Status',
    key: 'status',
    render: (status, { orderHolds }) => (
      <HorizontallySpaced $factor={0.5}>
        <StatusLabel status={status} type={orderStatusType} title={orderStatusTitle} />
        {status === OrderStatus.HOLD &&
          orderHolds &&
          orderHolds.map(({ id, type, subtype, description, createdAt }) => (
            <Tooltip
              title={
                <VerticallySpaced $factor={0.5}>
                  <div>
                    {subtype && (
                      <>
                        <strong>{orderHoldSubtypeTitle[subtype] || subtype}</strong>
                        <br />
                      </>
                    )}
                    {formatDateTime(createdAt)}
                  </div>
                  {description && <div>{description}</div>}
                </VerticallySpaced>
              }
            >
              <Label key={id}>{orderHoldTypeTitle[type] || type}</Label>
            </Tooltip>
          ))}
      </HorizontallySpaced>
    ),
  },
  {
    title: 'Ordered',
    key: 'orderedAt',
    render: (date) => (date ? formatDateTime(date) : ''),
    align: 'right',
  },
];

const holdOptions: FilterOption[] = [
  {
    value: OrderHoldType.RESERVE_INVENTORY_PROCESS,
    title: orderHoldTypeTitle.RESERVE_INVENTORY_PROCESS,
  },
  {
    value: OrderHoldType.SHIPPING_ADDRESS_PROCESS,
    title: orderHoldTypeTitle.SHIPPING_ADDRESS_PROCESS,
  },
  { value: OrderHoldType.MANUAL_HOLD, title: orderHoldTypeTitle.MANUAL_HOLD },
  { value: OrderHoldType.ITEM_NOT_FOUND, title: orderHoldTypeTitle.ITEM_NOT_FOUND },
  { value: OrderHoldType.SHIPPING_HOLD, title: orderHoldTypeTitle.SHIPPING_HOLD },
];

export function OrderList() {
  const {
    models: {
      isLoading,
      hasError,
      isHoldOnly,
      rows,
      pagination,
      total,
      query,
      view,
      filters,
      filterValues,
    },
    operations: {
      search,
      navigate,
      changeView,
      updateFilters,
      updateHoldTypes,
      refresh,
      onImportComplete,
      onClickRow,
    },
  } = useOrderListPage();

  return (
    <Container>
      <Header
        links={[
          { url: '', title: 'Sales' },
          { url: '/sales/orders', title: 'Orders' },
        ]}
        title="Sales Orders"
        actionContent={
          <>
            <DataImporter
              template={TemplateKey.ORDERS}
              title="Import Orders"
              onComplete={onImportComplete}
            />
            <Button type="primary">
              <RouterLink to="/sales/orders/new">Create Order</RouterLink>
            </Button>
          </>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.ViewTabs views={views} current={view ?? ''} onChange={changeView} />

          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search for orders..."
            defaultSearchValue={query}
            total={total}
            pagination={pagination}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterValues}
            onChangeFilterSelections={updateFilters}
          />

          {isHoldOnly && (
            <DataTable.QuickFilter
              title="Hold Types"
              options={holdOptions}
              hasAllOption
              selection={filterValues?.holds}
              onChange={updateHoldTypes}
            />
          )}

          <DataTable
            rows={rows}
            columns={isHoldOnly ? holdColumns : columns}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
