import { FulfillmentDTO } from '@invenco/common-interface/sales';
import { ShipmentDTO } from '@invenco/common-interface/shipping';
import { StatusLabel } from '../../../../../../components/label';
import { formatDate } from '../../../../../../shared/helpers';
import { shipmentStatusType } from '../../../../../../shared/status-maps';
import { shipmentStatusTitle } from '../../../../../../shared/title-maps';
import { Shipment } from './Shipment';
import { FulfillmentInfo, InfoBody, InfoItem, InfoTitle } from './styles';

type Props = {
  fulfillment: FulfillmentDTO;
  shipments: ShipmentDTO[];
  shipDate: string | undefined;
};

export function Fulfillment({
  fulfillment: { name, shipmentStatus, locationName, fulfillmentServiceType },
  shipments,
  shipDate,
}: Props) {
  return (
    <div>
      <FulfillmentInfo>
        <InfoItem $align="left">
          <InfoTitle>Name</InfoTitle>
          <InfoBody>
            <strong>{name}</strong>
          </InfoBody>
        </InfoItem>
        <InfoItem $align="center">
          <InfoTitle>Shipment Status</InfoTitle>
          {shipmentStatus && (
            <StatusLabel
              status={shipmentStatus}
              type={shipmentStatusType}
              title={shipmentStatusTitle}
            />
          )}
        </InfoItem>
        <InfoItem $align="center">
          <InfoTitle>Fulfillment Location</InfoTitle>
          <InfoBody>{locationName}</InfoBody>
        </InfoItem>
        <InfoItem $align="center">
          <InfoTitle>Fulfillment Service</InfoTitle>
          <InfoBody>{fulfillmentServiceType}</InfoBody>
        </InfoItem>
        <InfoItem $align="right">
          <InfoTitle>Date Shipped</InfoTitle>
          <InfoBody>{shipDate && formatDate(shipDate)}</InfoBody>
        </InfoItem>
      </FulfillmentInfo>

      {shipments?.map((s) => <Shipment key={s.id} shipment={s} />)}
    </div>
  );
}
