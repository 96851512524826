import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { Filter, FilterSelections, useDataTable, View } from 'components/data-table';
import { FilterOption } from 'components/data-table/filter-panel/types';
import { useGateways } from 'gateways/GatewayProvider';
import { useState } from 'react';
import { useQueryWithInput } from 'shared/hooks/queries';
import { listingStatusTitle } from 'shared/title-maps';
import { ComponentData, Page, Pagination } from 'shared/types';
import { RowAction } from '../../../../../components/data-table/row-actions/types';
import { useRowSelection } from '../../../../../components/data-table/useRowSelection';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { useBatchedRequests } from '../../../../../shared/hooks/useBatchedRequests';

type FilterKey = 'status' | 'strategies';

type Models = {
  isLoading: boolean;
  isEditListingsModalOpen: boolean;
  hasError: boolean;
  rows?: ListingDTO[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filterValues?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
  selectedRowsById: Map<string, ListingDTO>;
  rowActions: RowAction<ListingDTO>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  refresh: () => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  toggleRowSelection: (row: ListingDTO) => void;
  togglePageSelection: (rows: ListingDTO[]) => void;
  clearSelection: () => void;
  updateListings: (newData: Partial<ListingDTO>) => Promise<void>;
  closeEditListingsModal: () => void;
};

type Props = {
  channelId: string | undefined;
};

const statusOptions: FilterOption[] = [
  ListingStatus.ACTIVE,
  ListingStatus.DRAFT,
  ListingStatus.ARCHIVED,
].map((status) => ({
  value: status,
  title: listingStatusTitle[status],
}));

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...[ListingStatus.ACTIVE, ListingStatus.DRAFT, ListingStatus.ARCHIVED].map((status) => ({
    key: status,
    title: listingStatusTitle[status],
    filters: { status: [{ value: status, title: listingStatusTitle[status] }] },
  })),
];

const filters: Filter<FilterKey>[] = [
  {
    key: 'status',
    title: 'Status',
    options: statusOptions,
    multiple: true,
  },
];

export function useListingsDataTable({ channelId }: Props): ComponentData<Models, Operations> {
  const { salesGateway } = useGateways();
  const [tableState, setTableState, { getMultiFilterValues }] = useDataTable({
    filterKeys: ['status'],
    multiFilterKeys: ['strategies'],
    views,
  });
  const { selectedRowsById, toggleRowSelection, togglePageSelection, clearSelection } =
    useRowSelection<ListingDTO>();
  const [isEditListingsModalOpen, setIsEditListingsModalOpen] = useState(false);

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'listings',
    input: {
      take: tableState?.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState?.page?.cursor,
      search: tableState?.query,
      channelIds: [channelId!],
      statuses: getMultiFilterValues<ListingStatus>('status'),
      strategies: getMultiFilterValues<ListingStrategy>('strategies'),
    },
    query: (input, { signal }) => salesGateway.getListings(input, { signal }),
    enabled: !!channelId,
  });

  const { run: runEdit } = useBatchedRequests<Partial<ListingDTO>, ListingDTO>({
    messageKey: 'listings-edit',
    progressMessage: ({ results, failures, total }) =>
      `Updated listings (${results.length}/${total}${
        failures.length ? ` - ${failures.length} failed` : ''
      })`,
  });

  const { run: runRefresh } = useBatchedRequests<ListingDTO, ListingDTO>({
    messageKey: 'listings-refresh',
    progressMessage: ({ results, failures, total }) =>
      `Refreshed listings (${results.length}/${total}${
        failures.length ? ` - ${failures.length} failed` : ''
      })`,
  });

  const rowActions: RowAction<ListingDTO>[] = [
    {
      key: 'edit',
      title: 'Edit',
      disabled: isEditListingsModalOpen,
      action: () => setIsEditListingsModalOpen(true),
    },
    {
      key: 'refresh',
      title: 'Refresh',
      disabled: isEditListingsModalOpen,
      action: async (inputs) => {
        await runRefresh({
          execute: ({ id }) => salesGateway.refreshListing(id),
          inputs,
        });
        void refetch();
      },
    },
  ];

  const updateListings = async ({ status, strategy }: Partial<ListingDTO>) => {
    if (selectedRowsById.size) {
      await runEdit({
        execute: (listing) => salesGateway.updateListing(listing),
        inputs: Array.from(selectedRowsById.keys()).map((id) => ({
          id,
          status,
          strategy,
        })),
      });
      void refetch();
    }
  };

  return {
    models: {
      isLoading,
      isEditListingsModalOpen,
      hasError: isError,
      rows: data?.items as ListingDTO[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filterValues: tableState.filters,
      filters,
      selectedRowsById,
      rowActions,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      updateFilters: (updated) =>
        setTableState({ filters: updated, page: undefined, view: undefined }),
      refresh: refetch,
      toggleRowSelection,
      togglePageSelection,
      clearSelection,
      updateListings,
      closeEditListingsModal: () => setIsEditListingsModalOpen(false),
    },
  };
}
