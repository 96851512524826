import React from 'react';
import {
  Dialog as AriaDialog,
  DialogProps as AriaDialogProps,
  DialogTrigger as AriaDialogTrigger,
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

const PopoverTrigger = AriaDialogTrigger;

const Popover = React.forwardRef<React.ElementRef<typeof AriaPopover>, AriaPopoverProps>(
  ({ className, offset = 4, children, ...props }, ref) => (
    <AriaPopover
      ref={ref}
      offset={offset}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'z-50 rounded-md border bg-popover text-popover-foreground shadow-md outline-none',
          /* Entering */
          'data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95',
          /* Exiting */
          'data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95',
          /* Placement */
          'data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2',
          composedClassName,
        ),
      )}
      {...props}
    >
      {children}
    </AriaPopover>
  ),
);

function PopoverDialog({ className, children, ...props }: AriaDialogProps) {
  return (
    <AriaDialog className={cn('p-4 outline outline-0', className)} {...props}>
      {children}
    </AriaDialog>
  );
}

export { Popover, PopoverTrigger, PopoverDialog };
