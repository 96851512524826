import { CarrierDTO, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ComponentData } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';
import { useRowNavigation } from '../../../../components/data-table';

type Models = {
  isNew: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isModalOpen: boolean;
  canSave: boolean;
  breadcrumbs: BreadCrumb[];
  carrier: Partial<CarrierDTO>;
};

type Operations = {
  openModal: () => void;
  closeModal: () => void;
  refresh: () => Promise<void>;
  save: () => Promise<Result>;
  updateCarrier: (data: Partial<CarrierDTO>) => Promise<Result>;
  onClickService: (row: CarrierServiceDTO, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export function useCarrierDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const isNew = id === 'new';
  const { shippingGateway } = useGateways();
  const navigate = useNavigate();
  const { onClickRow: onClickService } = useRowNavigation({
    baseUrl: '/shipping/carrier_services',
  });

  const [carrier, setCarrier] = useState<Partial<CarrierDTO>>({});
  const [isModalOpen, setIsModalOpen] = useState(isNew);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'carriers',
    id,
    isNew,
    query: async (fetchId, { signal }) => {
      // TODO: remove this hack once there is a GET carrier API
      const carriers = await shippingGateway.getCarriers(
        { include: { carrierServices: true } },
        { signal },
      );
      const matchedCarrier = carriers.items.find((c) => c.id === fetchId);
      if (!matchedCarrier) throw new Error('Could not find carrier');
      return matchedCarrier;
    },
  });

  const existingName = data?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/shipping/carriers', title: 'Carriers' },
      { url: `/shipping/carriers/${id}`, title: existingName || 'New Carrier', loading: isLoading },
    ],
    [id, existingName, isLoading],
  );

  const canSave = Boolean(isNew && carrier.name && carrier.code);

  useEffect(() => {
    setCarrier(data ?? {});
  }, [data]);

  const refresh = async () => {
    await refetch();
  };

  // TODO: update when PATCH is supported
  const updateCarrier = async (updateData: Partial<CarrierDTO>) => {
    setCarrier({ ...carrier, ...updateData });
    return Result.ok(); // mainly returning this to make including PATCH easier later
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () => shippingGateway.createCarrier(carrier),
    onSuccess: (newCarrier) => navigate(`/shipping/carriers/${newCarrier.id}`),
    linkedQuery: ['carriers', id],
    successMessage: 'Carrier created',
  });

  return {
    models: {
      isNew,
      isLoading,
      isSaving,
      isModalOpen,
      canSave,
      breadcrumbs,
      carrier,
    },
    operations: {
      openModal: () => setIsModalOpen(true),
      closeModal: () => setIsModalOpen(false),
      refresh,
      save,
      updateCarrier,
      onClickService,
    },
  };
}
