import React, { ReactNode, useId } from 'react';
import { cn } from '@/shared/helpers';

/*
 * NOTE USAGE:
 * This component currently exports 2 Card components:
 * - Card (our wrapper of shadcn Card)
 * - CardBase (generic shadcn Card, renamed to avoid a clash. See https://ui.shadcn.com/docs/components/card)
 *
 * To use shadcn Card without our wrapper, follow the usage step in the docs. Note that you are
 * responsible for importing all of the parts and composing the card yourself:
 *
 * ```tsx
 * import {
 *  CardBase, // Not Card!
 *  CardContent,
 *  CardDescription,
 *  CardFooter,
 *  CardHeader,
 *  CardTitle,
 * } from "@/components-new/ui/card"
 *
 * <CardBase>
 *  <CardHeader>
 *   <CardTitle>Card Title</CardTitle>
 *   <CardDescription>Card Description</CardDescription>
 *  </CardHeader>
 *  <CardContent>
 *   <p>Card Content</p>
 *  </CardContent>
 *  <CardFooter>
 *   <p>Card Footer</p>
 *  </CardFooter>
 * </CardBase>
 * ```
 *
 * To use our wrapper component, you only need to import it and configure its props for your needs,
 * you don't need to compose it of each part:
 *
 * ```tsx
 * import {
 *  Card
 * } from "@/components-new/ui/card"
 *
 * <Card><p>Card Content</p></Card>
 * ```
 *
 */

const CardBase = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <section
      ref={ref}
      className={cn('rounded-xl border bg-card text-card-foreground shadow', className)}
      {...props}
    >
      {children}
    </section>
  ),
);
CardBase.displayName = 'Card';

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('flex flex-col space-y-1.5 p-6', className)} {...props}>
      {children}
    </div>
  ),
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('font-semibold leading-none tracking-tight', className)}
      {...props}
    >
      {children}
    </div>
  ),
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props}>
      {children}
    </div>
  ),
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('p-6 pt-0', className)} {...props}>
      {children}
    </div>
  ),
);
CardContent.displayName = 'CardContent';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center p-6 pt-0', className)} {...props}>
      {children}
    </div>
  ),
);
CardFooter.displayName = 'CardFooter';

type CardProps = {
  /** Title displayed at the top of the card. */
  title?: ReactNode;

  /** Description displayed below the title. */
  description?: ReactNode;

  /** Action content to be displayed on the right side of the card's header. */
  actions?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, title, description, actions, children, ...props }, ref) => {
    const titleId = useId();
    const descriptionId = useId();
    return (
      <CardBase
        ref={ref}
        className={className}
        aria-labelledby={props['aria-labelledby'] ?? (title ? titleId : undefined)}
        aria-describedby={props['aria-describedby'] ?? (description ? descriptionId : undefined)}
        {...props}
      >
        <div className="flex flex-col gap-4 p-6 sm:flex-row sm:items-center sm:justify-between">
          {(title || description) && (
            <div className="flex flex-col gap-1.5">
              {title && <CardTitle id={titleId}>{title}</CardTitle>}
              {description && <CardDescription id={descriptionId}>{description}</CardDescription>}
            </div>
          )}
          {actions && <div className="flex items-center gap-4">{actions}</div>}
        </div>
        {children !== undefined && <CardContent>{children}</CardContent>}
      </CardBase>
    );
  },
);

export {
  type CardProps,
  Card,
  CardBase,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
