import { OrderDTO, OrderStatus } from '@invenco/common-interface/sales';
import { useGateways } from 'gateways/GatewayProvider';
import { useMemo } from 'react';
import {
  Filter,
  FilterSelections,
  useDataTable,
  useRowNavigation,
  View,
} from '../../../../../components/data-table';
import { FilterOption, FilterValue } from '../../../../../components/data-table/filter-panel/types';
import { Label } from '../../../../../components/label';
import { DEFAULT_PAGE_SIZE } from '../../../../../constants';
import { useAutoCompleteQuery, useQueryWithInput } from '../../../../../shared/hooks/queries';
import { orderStatusTitle } from '../../../../../shared/title-maps';
import { ComponentData, Page, Pagination } from '../../../../../shared/types';
import { HorizontallySpaced } from '../../../../../styles/layout';

export type OrderRow = OrderDTO;

type FilterKey = 'status' | 'skuNames' | 'tagNames' | 'holds' | 'channelNames';

type Models = {
  isLoading: boolean;
  hasError: boolean;
  isHoldOnly: boolean;
  rows?: OrderRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filterValues?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  updateHoldTypes: (holds: FilterValue[] | null) => void;
  refresh: () => void;
  onImportComplete: () => void;
  onClickRow: (row: OrderRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
};

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...[
    OrderStatus.DRAFT,
    OrderStatus.OPEN,
    OrderStatus.HOLD,
    OrderStatus.CLOSED,
    OrderStatus.CANCELLED,
  ].map((status) => ({
    key: status,
    title: orderStatusTitle[status],
    filters: { status: { value: status, title: orderStatusTitle[status] } },
  })),
];

const statusOptions: FilterOption[] = [
  OrderStatus.DRAFT,
  OrderStatus.PENDING,
  OrderStatus.OPEN,
  OrderStatus.HOLD,
  OrderStatus.CLOSED,
  OrderStatus.CANCELLED,
].map((status) => ({ value: status, title: orderStatusTitle[status] }));

export function useOrderListPage(): ComponentData<Models, Operations> {
  const { salesGateway, productsGateway } = useGateways();
  const { onClickRow } = useRowNavigation<OrderRow>({ baseUrl: '/sales/orders' });
  const [tableState, setTableState, { getFilterValue, getMultiFilterValues, hasFilterValue }] =
    useDataTable({
      filterKeys: ['status'],
      multiFilterKeys: ['skuNames', 'tagNames', 'holds', 'channelNames'],
      views,
    });
  const isHoldOnly = hasFilterValue('status', OrderStatus.HOLD);

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'orders',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      status: getFilterValue<OrderStatus>('status'),
      holds: isHoldOnly ? (getMultiFilterValues('holds') ?? ['ALL']) : undefined,
      skuNames: getMultiFilterValues('skuNames'),
      tagNames: getMultiFilterValues('tagNames'),
      channelNames: getMultiFilterValues('channelNames'),
    },
    query: (input, { signal }) => salesGateway.getOrders(input, { signal }),
  });

  const {
    options: skus,
    onSearch: onSkuSearch,
    isLoading: isLoadingSkus,
  } = useAutoCompleteQuery({
    parentKey: 'skus',
    query: (input, { signal }) => productsGateway.getSkus(input, { signal }),
  });

  const {
    options: channels,
    onSearch: onChannelNameSearch,
    isLoading: isLoadingChannelNames,
  } = useAutoCompleteQuery({
    parentKey: 'channels',
    query: (input, { signal }) => salesGateway.getChannels(input, { signal }),
    enabled: true,
  });

  const {
    options: tags,
    onSearch: onTagSearch,
    isLoading: isLoadingTags,
  } = useAutoCompleteQuery({
    parentKey: 'tags',
    query: (input, { signal }) => salesGateway.getTags(input, { signal }),
    // this means the call will be made every time we first load the orders list (regardless of filtering)
    // an acceptable tradeoff in complexity for now, but we may want to revisit this (LYNK-2756)
    enabled: true,
  });

  const skuFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'skuNames',
      title: 'SKUs',
      loading: isLoadingSkus,
      options:
        skus.map((sku) => ({
          value: sku.name,
          title: (
            <HorizontallySpaced $factor={0.5}>
              <Label normalCase>{sku.name}</Label>
              <span>{sku.description}</span>
            </HorizontallySpaced>
          ),
        })) || [],
      onSearch: onSkuSearch,
      multiple: true,
    }),
    [skus, isLoadingSkus, onSkuSearch],
  );

  const tagFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'tagNames',
      title: 'Tags',
      loading: isLoadingTags,
      options: tags.map((tag) => ({ value: tag.name })) || [],
      onSearch: onTagSearch,
      multiple: true,
    }),
    [tags, isLoadingTags, onTagSearch],
  );

  const channelNameFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'channelNames',
      title: 'Channels',
      loading: isLoadingChannelNames,
      options: channels.map((channel) => ({ value: channel.name })) || [],
      onSearch: onChannelNameSearch,
      multiple: true,
    }),
    [channels, isLoadingChannelNames, onChannelNameSearch],
  );

  const filters: Filter<FilterKey>[] = [
    {
      key: 'status',
      title: 'Status',
      options: statusOptions,
    },
    skuFilter,
    tagFilter,
    channelNameFilter,
  ];

  const updateFilters = (updated: FilterSelections<FilterKey>) => {
    const newValues = { ...updated };
    if (newValues.holds && (newValues.status as FilterValue)?.value !== OrderStatus.HOLD) {
      delete newValues.holds;
    }
    setTableState({ filters: newValues, view: undefined, page: undefined });
  };

  return {
    models: {
      isLoading,
      hasError: isError,
      isHoldOnly,
      rows: data?.items as OrderRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filterValues: tableState.filters,
      filters,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      updateFilters,
      updateHoldTypes: (holds) =>
        setTableState({ filters: { ...tableState.filters, holds }, page: undefined }),
      refresh: refetch,
      onImportComplete: refetch,
      onClickRow,
    },
  };
}
