import { OrderLineDTO } from '@invenco/common-interface/sales';
import { useMemo } from 'react';
import { ComponentData } from '../../../../../../shared/types';
import { getDiscountAmount } from '../utils';

type Props = {
  orderLines: Partial<OrderLineDTO>[];
  shippingTotal?: number;
};

type Models = {
  subTotal: number;
  discountTotal: number;
  shippingTotal: number;
  total: number;
};

export function useOrderTotalsComponent({
  orderLines,
  shippingTotal = 0,
}: Props): ComponentData<Models> {
  const { subTotal, discountTotal } = useMemo(
    () =>
      orderLines
        .filter((ol) => !ol.assemblyOrderLineId)
        .reduce(
          (current, line) => ({
            subTotal: current.subTotal + (line.qtyOrdered ?? 0) * (line.unitPrice ?? 0),
            discountTotal: current.discountTotal + getDiscountAmount(line),
          }),
          { subTotal: 0, discountTotal: 0 },
        ),
    [orderLines],
  );
  return {
    models: {
      subTotal,
      discountTotal,
      shippingTotal,
      total: subTotal + shippingTotal - discountTotal,
    },
  };
}
