import { SkuAssemblyComponentDTO } from '@invenco/common-interface/products';
import { createId } from '@paralleldrive/cuid2';
import { useEffect, useMemo, useState } from 'react';
import { Result } from '../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  existingComponents?: Partial<SkuAssemblyComponentDTO>[];
  updateAssemblyComponents: (data: Partial<SkuAssemblyComponentDTO>[]) => Promise<Result>;
  onClose: () => void;
  isOpen: boolean;
};

type Models = {
  isSaving: boolean;
  canSave: boolean;
  canAdd: boolean;
  components: Partial<SkuAssemblyComponentDTO>[];
  excludeSkuIds: string[];
};

type Operations = {
  submit: () => Promise<void>;
  addComponent: () => void;
  deleteComponent: (id: string) => void;
  updateComponent: (id: string, data: Partial<SkuAssemblyComponentDTO>) => void;
};

export function useBundleComponentModalComponent({
  onClose,
  isOpen,
  existingComponents,
  updateAssemblyComponents,
}: Props): ComponentData<Models, Operations> {
  const [isSaving, setIsSaving] = useState(false);
  const [components, setComponents] = useState<Partial<SkuAssemblyComponentDTO>[]>(
    existingComponents ?? [],
  );

  const excludeSkuIds = useMemo(
    () => components.map(({ componentSku }) => componentSku?.id).filter((c) => c) as string[],
    [components],
  );
  const canSave = useMemo(
    () =>
      !!components.length &&
      !components.some((c) => !c.componentSku || !c.quantity || c.quantity <= 0),
    [components],
  );

  // only add if not currently selecting a sku (i.e. if any row doesn't currently have one)
  const canAdd = useMemo(() => !components.some((c) => !c.componentSku), [components]);

  useEffect(() => {
    setComponents(existingComponents ?? []);
  }, [existingComponents, isOpen]);

  const addComponent = () => setComponents([...components, { id: createId(), quantity: 1 }]);

  const updateComponent = (id: string, data: Partial<SkuAssemblyComponentDTO>) =>
    setComponents(components.map((c) => (c.id === id ? { ...c, ...data } : c)));

  const deleteComponent = (id: string) => {
    setComponents(components.filter((c) => c.id !== id));
  };

  const submit = async () => {
    setIsSaving(true);
    const data = components.map((kc) => ({
      componentSkuId: kc.componentSku?.id,
      quantity: kc.quantity,
    }));
    const result = await updateAssemblyComponents(data);
    if (result.isSuccess) onClose();
    setIsSaving(false);
  };
  return {
    models: {
      isSaving,
      components,
      excludeSkuIds,
      canSave,
      canAdd,
    },
    operations: {
      addComponent,
      deleteComponent,
      submit,
      updateComponent,
    },
  };
}
