import React from 'react';
import { ArrowUpDownIcon } from 'lucide-react';
import {
  ComboBox as AriaComboBox,
  ComboBoxProps as AriaComboBoxProps,
  Input as AriaInput,
  InputProps as AriaInputProps,
  ListBox as AriaListBox,
  ListBoxProps as AriaListBoxProps,
  PopoverProps as AriaPopoverProps,
  ValidationResult as AriaValidationResult,
  composeRenderProps,
  Text,
  Collection,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { Button } from './button';
import { FieldError, FieldGroup, Label } from './field';
import { ListBoxHeader, ListBoxItem, ListBoxSection } from './list-box';
import { Popover } from './popover';

const Combobox = AriaComboBox;

const ComboboxItem = ListBoxItem;

const ComboboxHeader = ListBoxHeader;

const ComboboxSection = ListBoxSection;

const ComboboxCollection = Collection;

const ComboboxInput = React.forwardRef<React.ElementRef<typeof AriaInput>, AriaInputProps>(
  ({ className, ...props }, ref) => (
    <AriaInput
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'flex h-10 w-full bg-background px-3 py-2 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground',
          /* Disabled */
          'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
          composedClassName,
        ),
      )}
      {...props}
    />
  ),
);

const ComboboxPopover = React.forwardRef<React.ElementRef<typeof Popover>, AriaPopoverProps>(
  ({ className, ...props }, ref) => (
    <Popover
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn('w-[calc(var(--trigger-width)+4px)]', composedClassName),
      )}
      {...props}
    />
  ),
);

function ComboboxListBox<T extends object>({ className, ...props }: AriaListBoxProps<T>) {
  return (
    <AriaListBox
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
          composedClassName,
        ),
      )}
      {...props}
    />
  );
}

// TODO: refactor this unified interface into our own form
interface JollyComboBoxProps<T extends object> extends Omit<AriaComboBoxProps<T>, 'children'> {
  label?: string;
  description?: string | null;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

function JollyComboBox<T extends object>({
  label,
  description,
  errorMessage,
  className,
  children,
  ...props
}: JollyComboBoxProps<T>) {
  return (
    <Combobox
      className={composeRenderProps(className, (composedClassName) =>
        cn('group flex flex-col gap-2', composedClassName),
      )}
      {...props}
    >
      <Label>{label}</Label>
      <FieldGroup className="p-0">
        <ComboboxInput />
        <Button variant="ghost" size="icon" className="mr-1 size-6 p-1">
          <ArrowUpDownIcon aria-hidden="true" className="size-4 opacity-50" />
        </Button>
      </FieldGroup>
      {description && (
        <Text className="text-sm text-muted-foreground" slot="description">
          {description}
        </Text>
      )}
      <FieldError>{errorMessage}</FieldError>
      <ComboboxPopover>
        <ComboboxListBox>{children}</ComboboxListBox>
      </ComboboxPopover>
    </Combobox>
  );
}

export {
  ComboboxSection,
  Combobox,
  ComboboxListBox,
  ComboboxInput,
  ComboboxCollection,
  ComboboxItem,
  ComboboxHeader,
  ComboboxPopover,
  JollyComboBox,
};
export type { JollyComboBoxProps };
