import React from 'react';
import { CheckIcon, ChevronRightIcon, CircleIcon } from 'lucide-react';
import { VariantProps } from 'class-variance-authority';
import {
  Collection,
  composeRenderProps,
  Header as AriaHeader,
  Keyboard as AriaKeyboard,
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  MenuItemProps as AriaMenuItemProps,
  MenuProps as AriaMenuProps,
  MenuSection as AriaMenuSection,
  MenuTrigger as AriaMenuTrigger,
  MenuTriggerProps as AriaMenuTriggerProps,
  PopoverProps,
  Separator as AriaSeparator,
  SeparatorProps as AriaSeparatorProps,
  SubmenuTrigger as AriaSubmenuTrigger,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

import { Button, buttonVariants } from './button';
import { Popover } from './popover';

const MenuTrigger = AriaMenuTrigger;

const MenuSubTrigger = AriaSubmenuTrigger;

const MenuSection = AriaMenuSection;

const MenuCollection = Collection;

const MenuPopover = React.forwardRef<React.ElementRef<typeof Popover>, PopoverProps>(
  ({ className, ...props }, ref) => (
    <Popover
      ref={ref}
      className={composeRenderProps(className, (composedClassName) =>
        cn('w-auto', composedClassName),
      )}
      {...props}
    />
  ),
);

function Menu<T extends object>({ className, ...props }: AriaMenuProps<T>) {
  return (
    <AriaMenu
      className={cn(
        'max-h-[inherit] overflow-auto rounded-md p-1 outline outline-0 [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
        className,
      )}
      {...props}
    />
  );
}

const MenuItem = React.forwardRef<React.ElementRef<typeof AriaMenuItem>, AriaMenuItemProps>(
  ({ children, className, ...props }, ref) => (
    <AriaMenuItem
      ref={ref}
      textValue={props.textValue || (typeof children === 'string' ? children : undefined)}
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'relative flex cursor-default select-none items-center gap-2 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors',
          /* Icons */
          '[&>svg]:size-4 [&>svg]:shrink-0',
          /* Disabled */
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          /* Focused */
          'data-[focused]:bg-accent data-[focused]:text-accent-foreground',
          /* Selection Mode */
          'data-[selection-mode]:pl-8',
          composedClassName,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (composedChildren, renderProps) => (
        <>
          <span className="absolute left-2 flex size-4 items-center justify-center">
            {renderProps.isSelected && (
              <>
                {renderProps.selectionMode === 'single' && (
                  <CircleIcon className="size-2 fill-current" />
                )}
                {renderProps.selectionMode === 'multiple' && <CheckIcon className="size-4" />}
              </>
            )}
          </span>

          {composedChildren}

          {renderProps.hasSubmenu && <ChevronRightIcon className="ml-auto size-4" />}
        </>
      ))}
    </AriaMenuItem>
  ),
);

interface MenuHeaderProps extends React.ComponentProps<typeof AriaHeader> {
  inset?: boolean;
  separator?: boolean;
}

const MenuHeader = React.forwardRef<React.ElementRef<typeof AriaHeader>, MenuHeaderProps>(
  ({ className, inset, separator = true, ...props }, ref) => (
    <AriaHeader
      ref={ref}
      className={cn(
        'px-3 py-1.5 text-sm font-semibold',
        inset && 'pl-8',
        separator && '-mx-1 mb-1 border-b border-b-border pb-2.5',
        className,
      )}
      {...props}
    />
  ),
);

const MenuSeparator = React.forwardRef<React.ElementRef<typeof AriaSeparator>, AriaSeparatorProps>(
  ({ className, ...props }, ref) => (
    <AriaSeparator ref={ref} className={cn('-mx-1 my-1 h-px bg-border', className)} {...props} />
  ),
);

const MenuKeyboard = React.forwardRef<
  React.ElementRef<typeof AriaKeyboard>,
  React.ComponentProps<typeof AriaKeyboard>
>(({ className, ...props }, ref) => (
  <AriaKeyboard
    ref={ref}
    className={cn('ml-auto text-xs tracking-widest opacity-60', className)}
    {...props}
  />
));

// TODO: refactor this unified interface into our own form
interface JollyMenuProps<T>
  extends AriaMenuProps<T>,
    VariantProps<typeof buttonVariants>,
    Omit<AriaMenuTriggerProps, 'children'> {
  label?: string;
}
function JollyMenu<T extends object>({
  label,
  children,
  variant,
  size,
  ...props
}: JollyMenuProps<T>) {
  return (
    <MenuTrigger {...props}>
      <Button variant={variant} size={size}>
        {label}
      </Button>
      <MenuPopover className="min-w-[--trigger-width]">
        <Menu {...props}>{children}</Menu>
      </MenuPopover>
    </MenuTrigger>
  );
}

export {
  MenuTrigger,
  Menu,
  MenuPopover,
  MenuItem,
  MenuHeader,
  MenuSeparator,
  MenuKeyboard,
  MenuSection,
  MenuSubTrigger,
  MenuCollection,
  JollyMenu,
};
export type { MenuHeaderProps, JollyMenuProps };
