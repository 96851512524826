import { SkuDTO, SkuType } from '@invenco/common-interface/products';
import {
  Filter,
  FilterSelections,
  useDataTable,
  useRowNavigation,
  View,
} from 'components/data-table';
import { FilterOption } from 'components/data-table/filter-panel/types';
import { Label } from 'components/label';
import { formatDate } from 'date-fns';
import { saveAs } from 'file-saver';
import { useGateways } from 'gateways/GatewayProvider';
import { useMemo, useState } from 'react';
import { useAutoCompleteQuery, useGatewayMutation, useQueryWithInput } from 'shared/hooks/queries';
import { skuTypeTitle } from 'shared/title-maps';
import { ComponentData, Page, Pagination } from 'shared/types';
import { HorizontallySpaced } from 'styles/layout';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';

export type SkuRow = SkuDTO;

type FilterKey = 'skuNames' | 'type';

type Models = {
  isCreateModalOpen: boolean;
  isExportModalOpen: boolean;
  isLoading: boolean;
  isExporting: boolean;
  hasError: boolean;
  rows?: SkuRow[];
  pagination?: Pagination;
  total?: number;
  query?: string;
  view?: string;
  filterSelections?: FilterSelections<FilterKey>;
  filters: Filter<FilterKey>[];
};

type Operations = {
  search: (query: string) => void;
  navigate: (page: Page) => void;
  changeView: (view: string) => void;
  updateFilters: (filters: FilterSelections<FilterKey>) => void;
  onClickRow: (row: SkuRow, event: React.MouseEvent<HTMLTableRowElement>) => void;
  refresh: () => void;
  exportAll: () => void;
  onImportComplete: () => void;
  openCreateModal: () => void;
  closeCreateModal: () => void;
  openExportModal: () => void;
  closeExportModal: () => void;
};

const skuTypeOrder = [SkuType.STANDARD, SkuType.BUNDLE, SkuType.MYSTERY];

const skuTypeOptions: FilterOption[] = skuTypeOrder.map((type) => ({
  value: type,
  title: skuTypeTitle[type],
}));

export const views: View<FilterKey>[] = [
  { key: '', title: 'All' },
  ...skuTypeOrder.map((type) => ({
    key: type,
    title: skuTypeTitle[type],
    filters: { type: { value: type, title: skuTypeTitle[type] } },
  })),
];

export function useSkuListPage(): ComponentData<Models, Operations> {
  const { productsGateway } = useGateways();
  const { onClickRow } = useRowNavigation<SkuRow>({ baseUrl: '/products/skus' });
  const [tableState, setTableState, { getFilterValue, getMultiFilterValues }] = useDataTable({
    filterKeys: ['type'],
    multiFilterKeys: ['skuNames'],
    views,
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { data, isLoading, isError, refetch } = useQueryWithInput({
    parentKey: 'skus',
    input: {
      take: tableState.page?.take || DEFAULT_PAGE_SIZE,
      cursor: tableState.page?.cursor,
      search: tableState.query,
      skuNames: getMultiFilterValues('skuNames'),
      type: getFilterValue<SkuType>('type'),
    },
    query: (input, { signal }) => productsGateway.getSkus(input, { signal }),
  });

  const {
    options: skus,
    onSearch: onSkuSearch,
    isLoading: isLoadingSkus,
  } = useAutoCompleteQuery({
    parentKey: 'skus',
    query: (input, { signal }) => productsGateway.getSkus(input, { signal }),
  });

  const skuFilter = useMemo<Filter<FilterKey>>(
    () => ({
      key: 'skuNames',
      title: 'SKUs',
      loading: isLoadingSkus,
      options:
        skus.map((sku) => ({
          value: sku.name,
          title: (
            <HorizontallySpaced $factor={0.5}>
              <Label normalCase>{sku.name} </Label>
              <span> {sku.description} </span>
            </HorizontallySpaced>
          ),
        })) || [],
      onSearch: onSkuSearch,
      multiple: true,
    }),
    [skus, isLoadingSkus, onSkuSearch],
  );

  const filters: Filter<FilterKey>[] = [
    skuFilter,
    {
      key: 'type',
      title: 'Type',
      options: skuTypeOptions,
    },
  ];

  const { mutate: exportAll, isPending: isExporting } = useGatewayMutation({
    mutationFn: () => productsGateway.exportAllSkusToBlob(),
    onSuccess: (skuData) => {
      setIsExportModalOpen(false);
      saveAs(skuData, `${formatDate(new Date(), 'yyyy-MM-dd')}_SKUs.csv`);
    },
  });

  return {
    models: {
      isCreateModalOpen,
      isExportModalOpen,
      isLoading,
      isExporting,
      hasError: isError,
      rows: data?.items as SkuRow[] | undefined,
      pagination: data?.pagination,
      total: data?.total,
      query: tableState.query,
      view: tableState.view,
      filterSelections: tableState.filters,
      filters,
    },
    operations: {
      search: (query) => setTableState({ query, page: undefined }),
      navigate: (page) => setTableState({ page }),
      changeView: (view) => setTableState({ view, page: undefined, filters: undefined }),
      updateFilters: (updated) =>
        setTableState({ filters: updated, view: undefined, page: undefined }),
      refresh: refetch,
      onClickRow,
      exportAll: () => void exportAll(),
      onImportComplete: refetch,
      openCreateModal: () => setIsCreateModalOpen(true),
      closeCreateModal: () => setIsCreateModalOpen(false),
      openExportModal: () => setIsExportModalOpen(true),
      closeExportModal: () => setIsExportModalOpen(false),
    },
  };
}
