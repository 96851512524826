import { OrderDTO, OrderLineDTO } from '@invenco/common-interface/sales';

import { LoadingSkeleton } from '../../../../../../components/loading-skeleton';
import { formatMoney } from '../../../../../../shared/helpers';
import { SubTotalLine, SubTotalLines, TotalLine } from './styles';
import { useOrderTotalsComponent } from './useOrderTotalsComponent';

type Props = {
  loading?: boolean;
  orderDetails: Partial<OrderDTO>;
  orderLines: Partial<OrderLineDTO>[];
};

export function OrderTotals({ loading, orderDetails, orderLines }: Props) {
  const {
    models: { subTotal, discountTotal, shippingTotal, total },
  } = useOrderTotalsComponent({ orderLines, shippingTotal: orderDetails.subtotalShipping });
  return (
    <SubTotalLines>
      <SubTotalLine>
        <div>Subtotal</div>
        <div>{loading ? <LoadingSkeleton /> : formatMoney(subTotal, orderDetails.currency)}</div>
      </SubTotalLine>
      <SubTotalLine>
        <div>Discounts</div>
        <div>
          {loading ? <LoadingSkeleton /> : formatMoney(discountTotal, orderDetails.currency)}
        </div>
      </SubTotalLine>
      <SubTotalLine>
        <div>{orderDetails.shippingProfileName ?? 'Shipping'}</div>
        <div>
          {loading ? <LoadingSkeleton /> : formatMoney(shippingTotal, orderDetails.currency)}
        </div>
      </SubTotalLine>
      <TotalLine>
        <div>Order Total</div>
        <div>{loading ? <LoadingSkeleton /> : formatMoney(total, orderDetails.currency)}</div>
      </TotalLine>
    </SubTotalLines>
  );
}
