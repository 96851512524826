import { Form, FormInstance } from 'antd';
import { useEffect } from 'react';
import { CarrierDTO } from '@invenco/common-interface/shipping';
import { ComponentData } from '../../../../../shared/types';

type Props = {
  carriers: CarrierDTO[];
  onSave: (carrier: CarrierDTO) => void;
  onClose: () => void;
};

type Models = {
  form: FormInstance;
};

type Operations = {
  filter: (inputValue: string, option: any) => boolean;
  submit: (data: Record<string, any>) => void;
};

export function useCarrierSelectionModal({
  carriers,
  onSave,
  onClose,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();

  const filter = (inputValue: string, option: { label?: string }) =>
    (option.label?.toString() ?? '').toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());

  const submit = (data: Record<string, any>) => {
    const carrier = carriers.find(({ id }) => id === data.carrierId);
    if (carrier) onSave(carrier);
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  return {
    models: {
      form,
    },
    operations: {
      filter,
      submit,
    },
  };
}
