import React from 'react';
import { ChevronRightIcon, MoreHorizontalIcon } from 'lucide-react';
import {
  Breadcrumb as AriaBreadcrumb,
  BreadcrumbProps as AriaBreadcrumbProps,
  Breadcrumbs as AriaBreadcrumbs,
  BreadcrumbsProps as AriaBreadcrumbsProps,
  Link as AriaLink,
  LinkProps as AriaLinkProps,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

function Breadcrumbs<T extends object>({ className, children, ...props }: AriaBreadcrumbsProps<T>) {
  return (
    <AriaBreadcrumbs
      className={cn(
        'flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground sm:gap-2',
        className,
      )}
      {...props}
    >
      {children}
    </AriaBreadcrumbs>
  );
}

function BreadcrumbItem({ className, children, ...props }: AriaBreadcrumbProps) {
  return (
    <AriaBreadcrumb
      className={cn('inline-flex items-center gap-1.5 sm:gap-2.5', className)}
      {...props}
    >
      {children}
    </AriaBreadcrumb>
  );
}

function BreadcrumbLink({ className, children, ...props }: AriaLinkProps) {
  return (
    <AriaLink
      className={composeRenderProps(className, (composedClassName) =>
        cn(
          'transition-colors',
          /* Hover */
          'data-[hovered]:text-foreground',
          /* Disabled */
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          /* Current */
          'data-[current]:pointer-events-auto data-[current]:opacity-100',
          '[&>svg]:size-4',
          composedClassName,
        ),
      )}
      {...props}
    >
      {children}
    </AriaLink>
  );
}

function BreadcrumbSeparator({ children, className, ...props }: React.ComponentProps<'span'>) {
  return (
    <span
      role="presentation"
      aria-hidden="true"
      className={cn('[&>svg]:size-3.5', className)}
      {...props}
    >
      {children || <ChevronRightIcon />}
    </span>
  );
}

function BreadcrumbEllipsis({ className, ...props }: React.ComponentProps<'span'>) {
  return (
    <span
      role="presentation"
      aria-hidden="true"
      className={cn('flex size-9 items-center justify-center', className)}
      {...props}
    >
      <MoreHorizontalIcon className="size-4" />
      <span className="sr-only">More</span>
    </span>
  );
}

interface BreadcrumbPageProps extends Omit<AriaLinkProps, 'href'> {}

function BreadcrumbPage({ className, children, ...props }: BreadcrumbPageProps) {
  return (
    <AriaLink
      className={composeRenderProps(className, (composedClassName) =>
        cn('font-normal text-foreground', composedClassName),
      )}
      {...props}
    >
      {children}
    </AriaLink>
  );
}

export {
  Breadcrumbs,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
};
