import { SkuDTO } from '@invenco/common-interface/products';
import { ActionLink } from 'components/button';
import { Label } from 'components/label';
import { ComponentProps } from 'react';
import { HorizontallySpaced, SpacedBetween } from '../../styles/layout';
import { AutoComplete } from '../auto-complete';
import { Container } from './styles';
import { QueryOptions, useSkuSearchComponent } from './useSkuSearchComponent';

type Props = Omit<
  ComponentProps<typeof AutoComplete<SkuDTO>>,
  'options' | 'onSearch' | 'onSelect'
> & {
  selectedSku?: SkuDTO;
  onSelect: (sku?: SkuDTO) => any;
  queryOptions?: QueryOptions;
  hideEdit?: boolean;
};

export function SkuSearch({
  selectedSku,
  onSelect: onSelectSku,
  queryOptions,
  hideEdit,
  ...props
}: Props) {
  const {
    models: { isLoading, options },
    operations: { search, selectOption, clearSelection },
  } = useSkuSearchComponent({ onSelectSku, queryOptions });
  return (
    <Container>
      {selectedSku ? (
        <SpacedBetween>
          <HorizontallySpaced $factor={0.5}>
            <Label normalCase>{selectedSku.name}</Label>
            <span>{selectedSku.description}</span>
          </HorizontallySpaced>
          {!hideEdit && <ActionLink onClick={clearSelection}>Edit</ActionLink>}
        </SpacedBetween>
      ) : (
        <AutoComplete
          options={options}
          onSelect={selectOption}
          onSearch={search}
          placeholder="Search by SKU or description"
          aria-label="SKU"
          loading={isLoading}
          {...props}
        />
      )}
    </Container>
  );
}
