import type { SkuDTO } from '@invenco/common-interface/products';
import { useGateways } from '../../gateways/GatewayProvider';
import { GetSkusQuery } from '../../gateways/impl/AxiosProductsGateway';
import { useAutoCompleteQuery } from '../../shared/hooks/queries/useAutoCompleteQuery';
import { ComponentData } from '../../shared/types';

export type QueryOptions = Partial<Omit<GetSkusQuery, 'take' | 'cursor' | 'search'>>;

type Models = {
  isLoading: boolean;
  options: SkuDTO[];
};

type Operations = {
  search: (query: string) => void;
  selectOption: (sku?: SkuDTO) => void;
  clearSelection: () => void;
};

type Props = {
  onSelectSku: (sku?: SkuDTO) => any;
  queryOptions?: QueryOptions;
};

export const useSkuSearchComponent = ({
  onSelectSku,
  queryOptions,
}: Props): ComponentData<Models, Operations> => {
  const { productsGateway } = useGateways();
  const {
    options,
    onSearch: search,
    isLoading,
  } = useAutoCompleteQuery({
    parentKey: 'skus',
    queryParams: queryOptions,
    query: (params, { signal }) => productsGateway.getSkus(params, { signal }),
  });

  const selectOption = (sku?: SkuDTO) => {
    if (sku) {
      onSelectSku(sku);
    }
  };

  const clearSelection = () => {
    onSelectSku(undefined);
  };

  return {
    models: { isLoading, options },
    operations: { search, selectOption, clearSelection },
  };
};
