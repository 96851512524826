import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from 'react-router';
import { Button } from 'components/button';
import { spaceHorizontalChildren, spaceVerticalChildren } from '../../styles/mixins';

export const HeaderContainer = styled.header`
  width: 100%;
  ${spaceVerticalChildren()}
`;

export const TopRowContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;
    ${spaceHorizontalChildren()}
  }
`;

export const FetchingIndicator = styled(() => <LoadingOutlined spin />)`
  font-size: 12px;
  color: var(--gray-7);
`;

export const BottomRowContainer = styled.div`
  .ant-skeleton-title {
    margin: 0;
  }
`;

export const TitleLineWrapper = styled.div`
  display: flex;
  align-items: center;
  ${spaceHorizontalChildren(0.5)}
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${spaceHorizontalChildren()}

  .ant-skeleton-title {
    height: 24px;
    max-width: 400px;
  }

  h1,
  .ant-skeleton {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
  }
`;

export const BackLink = styled(Link)`
  background: none;
  border: none;
  padding: 3px;
  font-size: 18px;
  color: var(--gray-9);

  &:hover,
  &:active {
    color: var(--gray-8);
  }
`;

export const ActionWrapper = styled.div`
  ${spaceHorizontalChildren(0.5)}
`;

export const RefreshButton = styled(Button)`
  color: var(--gray-7);
`;

export const Description = styled.div<{ $hasBackButton?: boolean }>`
  min-height: 32px;
  display: flex;
  align-items: center;
  ${({ $hasBackButton }) => $hasBackButton && 'margin-left: 32px;'}
`;
