import { Route, Routes } from 'react-router';
import { WorkflowScopeType } from '@invenco/common-domain/enums';
import { commonRoutes, redirectRoute } from '../../shared/helpers/routing';
import { TenantList } from './tenants/tenant-list/TenantList';
import { WarehouseCarrierServiceList } from './warehouseCarrierServices/warehouse-carrier-service-list/WarehouseCarrierServiceList';
import { WarehouseCarrierServiceDetails } from './warehouseCarrierServices/warehouse-carrier-service-details/WarehouseCarrierServiceDetails';
import { TenantDetails } from './tenants/tenant-details/TenantDetails';
import { WorkflowDetails } from '../common/workflows/workflow-details/WorkflowDetails';
import { WorkflowList } from '../common/workflows/workflow-list/WorkflowList';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

export function WarehouseRoutes() {
  const { isInvencoUser } = useAuthToken();

  return (
    <Routes>
      {redirectRoute('/', '/tenants')}
      <Route path="/tenants">
        <Route index element={<TenantList />} />
        <Route path=":id" element={<TenantDetails />} />
      </Route>

      <Route path="/automation">
        {redirectRoute('', 'workflows')}
        <Route path="workflows">
          <Route index element={<WorkflowList scopeType={WorkflowScopeType.WMS_INSTANCE} />} />
          <Route
            path=":id"
            element={<WorkflowDetails scopeType={WorkflowScopeType.WMS_INSTANCE} />}
          />
        </Route>
      </Route>

      <Route path="/warehouseCarrierServices">
        <Route index element={<WarehouseCarrierServiceList />} />
        <Route path=":id" element={<WarehouseCarrierServiceDetails />} />
      </Route>

      {commonRoutes(isInvencoUser)}
    </Routes>
  );
}
