import styled from 'styled-components';
import { Link, LinkProps } from 'react-router';
import { RightOutlined } from '@ant-design/icons';

export const StyledLink = styled(
  ({
    selected,
    icon,
    ...rest
  }: LinkProps & {
    selected?: boolean;
    icon?: boolean;
  }) => <Link {...rest} />,
)`
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  font-size: ${({ icon }) => (icon ? '16px' : '12px')};
  color: var(--gray-9);
  text-decoration: none;
  ${({ to }) => !to && 'pointer-events: none;'}
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: var(--gray-9);
    text-decoration: none;
  }
`;

export const Separator = styled(RightOutlined)`
  font-size: 10px;
`;
