import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';

export type Theme = 'dark' | 'light' | 'system';

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const THEME_STORAGE_KEY = 'lynk/theme';

const ThemeProviderContext = createContext<ThemeProviderState>({
  theme: 'system',
  setTheme: () => {},
});

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(THEME_STORAGE_KEY) as Theme) || 'light',
  );
  const { getFeatureFlag } = useFeatureFlags();

  // Add root class for new UI, used by both CSS resets (Tailwind and Ant) to determine which one to apply
  // TODO LYNK-3372: remove
  useEffect(() => {
    const root = window.document.documentElement;
    if (getFeatureFlag('new-ui')) {
      root.classList.add('mcshadwind');
    }
    return () => root.classList.remove('mcshadwind');
  }, [getFeatureFlag]);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove('light', 'dark');

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      setTheme: (newTheme: Theme) => {
        localStorage.setItem(THEME_STORAGE_KEY, newTheme);
        setTheme(newTheme);
      },
    }),
    [theme],
  );

  return <ThemeProviderContext.Provider value={value}>{children}</ThemeProviderContext.Provider>;
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
