import {
  CreateOrderDTO,
  CreateOrderLineDTO,
  OrderDTO,
  OrderLineDTO,
  OrderStatus,
} from '@invenco/common-interface/sales';
import { ContactAddressDTO } from '@invenco/common-interface/shared';

export const prepareCustomerAddress = (address: Partial<ContactAddressDTO> = {}) => ({
  companyName: address.companyName || '',
  contactName: address.contactName || '',
  contactEmail: address.contactEmail || undefined,
  contactNumber: address.contactNumber || '',
  address1: address.address1 || '',
  address2: address.address2 || '',
  city: address.city || '',
  state: address.state || '',
  postcode: address.postcode || '',
  country: address.country || '',
});

export const compareAddresses = (
  a?: Partial<ContactAddressDTO>,
  b?: Partial<ContactAddressDTO>,
) => {
  const preppedA = prepareCustomerAddress(a ?? {});
  const preppedB = prepareCustomerAddress(b ?? {});
  // assumes they both have same keys via prepare function above
  return !Object.keys(preppedA).some(
    (key) =>
      preppedA[key]?.trim().toLocaleLowerCase() !== preppedB[key]?.trim().toLocaleLowerCase(),
  );
};

export const getDiscountAmount = ({
  discountAmount,
  discountRate,
  qtyOrdered,
  unitPrice,
}: Partial<OrderLineDTO>) => {
  if (qtyOrdered && qtyOrdered > 0) {
    if (discountRate && discountRate > 0) {
      return (qtyOrdered ?? 0) * (unitPrice ?? 0) * (discountRate / 100);
    }
    return discountAmount ?? 0;
  }
  return 0;
};

export const getOrderUpdateDTO = (order: Partial<OrderDTO>): any => ({
  customerName: order.customerName,
  email: order.email,
  phone: order.phone,
  currency: order.currency,
  channelName: order.channelName,
  notes: order.notes,
  shippingAddress: prepareCustomerAddress(order.shippingAddress),
  billingAddress: order.billingAddress?.contactName
    ? prepareCustomerAddress(order.billingAddress)
    : null,
  allowPartialFulfillments: order.allowPartialFulfillments,
  shippingProfileName: order.shippingProfileName,
  subtotalShipping: order.subtotalShipping,
  ignoreAddressCorrection: order.ignoreAddressCorrection,
  reference: order.reference,
  packingInstructions: order.packingInstructions,
  deliveryInstructions: order.deliveryInstructions,
});

export const getOrderCreateDTO = (
  orderDetails: Partial<OrderDTO>,
  orderLines: Partial<OrderLineDTO>[],
): CreateOrderDTO =>
  ({
    ...getOrderUpdateDTO(orderDetails),
    status: OrderStatus.DRAFT,
    orderLines: orderLines.map((line) => ({
      skuName: line.skuName,
      description: line.description,
      qtyOrdered: line.qtyOrdered,
      unitPrice: line.unitPrice,
      discountAmount: line.discountAmount,
      discountRate: line.discountRate,
    })),
  }) as CreateOrderDTO;

export const getCreateOrderLineDTO = (orderLine: Partial<OrderLineDTO>): CreateOrderLineDTO =>
  ({
    skuName: orderLine.skuName,
    description: orderLine.description,
    qtyOrdered: orderLine.qtyOrdered,
    unitPrice: orderLine.unitPrice,
    discountAmount: orderLine.discountAmount,
    discountRate: orderLine.discountRate,
    externalSkuCode: orderLine.externalSkuCode,
    externalSkuName: orderLine.externalSkuName,
    externalId: orderLine.externalId,
    measurement: orderLine.measurement,
    customAttributes: orderLine.customAttributes,
  }) as CreateOrderLineDTO;
