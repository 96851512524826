import { SkuAssemblyComponentDTO, SkuSelectionMethod } from '@invenco/common-interface/products';
import { createId } from '@paralleldrive/cuid2';
import { useEffect, useMemo, useState } from 'react';
import { Result } from '../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../shared/types';
import {
  getMysterySkuComponents,
  isMysteryGroupComponent,
  MysteryComponent,
  MysteryGroupComponent,
  MysterySkuComponent,
} from '../useMysteryComponents';

type ComponentType = 'fixed' | 'group';
type GroupDetails = Omit<Partial<MysteryGroupComponent>, 'id' | 'components'>;

type Props = {
  isOpen: boolean;
  existingMysteryComponent?: MysteryComponent;
  allAssemblyComponents: SkuAssemblyComponentDTO[];
  onSave: (group: MysteryComponent) => Promise<Result<void>>;
  onClose: () => void;
};

type Models = {
  isSaving: boolean;
  canSave: boolean;
  type: ComponentType;
  mysteryComponent: MysteryComponent;
  excludeSkuIds: string[];
};

type Operations = {
  changeType: (type: ComponentType) => void;
  updateGroupDetails: (group: GroupDetails) => void;
  addChildComponent: () => void;
  updateChildComponent: (id: string, component: Partial<SkuAssemblyComponentDTO>) => void;
  deleteChildComponent: (id: string) => void;
  submit: () => Promise<void>;
};

const getDefaultSkuComponent = (
  existingMysteryComponent?: MysteryComponent,
): MysterySkuComponent => {
  if (existingMysteryComponent) {
    if (!isMysteryGroupComponent(existingMysteryComponent)) {
      return existingMysteryComponent;
    }
    if (existingMysteryComponent.components.length) {
      return existingMysteryComponent.components[0];
    }
  }
  return { id: createId(), quantity: 1 };
};

const getDefaultGroupComponent = (
  existingMysteryComponent?: MysteryComponent,
): MysteryGroupComponent =>
  existingMysteryComponent && isMysteryGroupComponent(existingMysteryComponent)
    ? existingMysteryComponent
    : {
        id: '',
        name: '',
        quantity: 1,
        components: [getDefaultSkuComponent(existingMysteryComponent)],
        selectionMethods: [
          SkuSelectionMethod.MOST_UNIQUE_TO_ORDER,
          SkuSelectionMethod.MOST_AVAILABLE,
        ],
      };

const getDefaultType = (existingMysteryComponent?: MysteryComponent): ComponentType =>
  !existingMysteryComponent || isMysteryGroupComponent(existingMysteryComponent)
    ? 'group'
    : 'fixed';

export function useMysteryComponentModal({
  isOpen,
  existingMysteryComponent,
  allAssemblyComponents,
  onSave,
  onClose,
}: Props): ComponentData<Models, Operations> {
  const [isSaving, setIsSaving] = useState(false);
  const [type, setType] = useState<ComponentType>(getDefaultType(existingMysteryComponent));
  const [groupComponent, setGroupComponent] = useState<MysteryGroupComponent>(
    getDefaultGroupComponent(existingMysteryComponent),
  );
  const [fixedComponent, setFixedComponent] = useState<MysterySkuComponent>(
    getDefaultSkuComponent(existingMysteryComponent),
  );
  const currentComponent = type === 'fixed' ? fixedComponent : groupComponent;

  const canSave = useMemo(
    () =>
      Boolean(
        !isMysteryGroupComponent(currentComponent)
          ? currentComponent.quantity && currentComponent.componentSkuId
          : currentComponent.name &&
              currentComponent.quantity &&
              currentComponent.components.length > 0 &&
              currentComponent.components.every((comp) => comp.componentSkuId),
      ),
    [currentComponent],
  );

  const excludeSkuIds = useMemo(() => {
    // account for any in-state data changes to this group's components
    const ownedComponentIds = new Set(
      getMysterySkuComponents(existingMysteryComponent).map(({ id }) => id),
    );
    return [
      ...allAssemblyComponents.filter(({ id }) => !ownedComponentIds.has(id)),
      ...getMysterySkuComponents(currentComponent),
    ]
      .map((comp) => comp.componentSku?.id ?? comp.componentSkuId)
      .filter((x) => x) as string[];
  }, [existingMysteryComponent, allAssemblyComponents, currentComponent]);

  useEffect(() => {
    setType(getDefaultType(existingMysteryComponent));
    setFixedComponent(getDefaultSkuComponent(existingMysteryComponent));
    setGroupComponent(getDefaultGroupComponent(existingMysteryComponent));
  }, [existingMysteryComponent, isOpen]);

  const changeType = (newType: ComponentType) => {
    setType(newType);
  };

  const updateGroup = (data: GroupDetails) => setGroupComponent({ ...groupComponent, ...data });

  const addComponent = () => {
    if (type === 'fixed') return;
    setGroupComponent({
      ...groupComponent,
      components: [...groupComponent.components, getDefaultSkuComponent()],
    });
  };

  const updateComponent = (id: string, data: Partial<SkuAssemblyComponentDTO>) =>
    type === 'fixed'
      ? setFixedComponent({ ...fixedComponent, ...data })
      : setGroupComponent({
          ...groupComponent,
          components: groupComponent.components.map((c) => (c.id === id ? { ...c, ...data } : c)),
        });

  const deleteComponent = (id: string) => {
    if (type === 'fixed') return;
    setGroupComponent({
      ...groupComponent,
      components: groupComponent.components.filter((c) => c.id !== id),
    });
  };

  const submit = async () => {
    setIsSaving(true);
    const result = await onSave(currentComponent);
    if (result.isSuccess) {
      onClose();
    }
    setIsSaving(false);
  };

  return {
    models: { isSaving, canSave, type, mysteryComponent: currentComponent, excludeSkuIds },
    operations: {
      changeType,
      updateGroupDetails: updateGroup,
      addChildComponent: addComponent,
      updateChildComponent: updateComponent,
      deleteChildComponent: deleteComponent,
      submit,
    },
  };
}
