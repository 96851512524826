import { OrderLineDTO } from '@invenco/common-interface/sales';

import { FormModal } from '../../../../../../components/form-modal';
import { SkuSearch } from '../../../../../../components/sku-search';
import { Result } from '../../../../../../shared/helpers/Result';
import { ModalContent } from './styles';
import { useAssignSkuModalComponent } from './useAssignSkuModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  line?: Partial<OrderLineDTO>;
  updateOrderLine: (lineId: string, data: Partial<OrderLineDTO>) => Promise<Result>;
};

export function AssignSkuModal({ isOpen, onClose, line, updateOrderLine }: Props) {
  const {
    models: { isSaving, selectedSku },
    operations: { confirm, selectSku },
  } = useAssignSkuModalComponent({ isOpen, onClose, line, updateOrderLine });
  return (
    <FormModal
      open={isOpen}
      title="Assign SKU"
      onCancel={onClose}
      saveTitle="Assign"
      onSave={confirm}
      saveDisabled={!selectedSku}
      isSaving={isSaving}
      closable={!isSaving}
    >
      <ModalContent>
        <p>
          <strong>
            {line?.skuName} ; {line?.description}
          </strong>{' '}
          cannot be matched to an existing SKU and is invalid.
        </p>
        <p>Please search for an existing SKU to resolve the issue.</p>
        <SkuSearch selectedSku={selectedSku} onSelect={selectSku} autoFocus />
      </ModalContent>
    </FormModal>
  );
}
