import { CarrierServiceCategory, CarrierServiceDTO } from '@invenco/common-interface/shipping';
import { Link as RouterLink } from 'react-router';
import { formatDateTime } from 'shared/helpers';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { useCarrierServiceListPage } from './useCarrierServiceListPage';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { Card } from '../../../../components/card';
import { carrierServiceCategoryTitle } from '../../../../shared/title-maps';

function getColumns({ carriersById }): Column<CarrierServiceDTO>[] {
  return [
    { title: 'Name', key: 'name' },
    { title: 'Code', key: 'code' },
    {
      title: 'Carrier',
      key: 'carrierId',
      render: (carrierId: string) => carriersById?.[carrierId]?.name,
    },
    {
      title: 'Category',
      key: 'category',
      render: (category: CarrierServiceCategory) => carrierServiceCategoryTitle[category],
    },
    {
      title: 'External Codes',
      key: 'externalCodes',
      render: (codes: string[]) => codes.join(', '),
    },
    {
      title: 'Created',
      key: 'createdAt',
      render: (createdAt: string) => formatDateTime(createdAt),
    },
  ];
}

export function CarrierServiceList() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, carriersById },
    operations: { search, navigate, refresh, onClickRow },
  } = useCarrierServiceListPage();

  return (
    <Container>
      <Header
        links={[{ url: '/shipping/carrier_services', title: 'Carrier Services' }]}
        title="Carrier Services"
        actionContent={
          <Button type="primary">
            <RouterLink to="/shipping/carrier_services/new">Create carrier service</RouterLink>
          </Button>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
          />

          <DataTable
            rows={rows}
            columns={getColumns({ carriersById })}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
