import { NavLink, Outlet } from 'react-router';
import { RocketOutlined } from '@ant-design/icons';
import { Container, DetailContainer, MainContainer } from '../../../styles/layout';
import { Header } from '../../../components/header';
import { Menu, MenuContainer } from './styles';
import { useSettingsContainer } from './useSettingsContainer';

export function SettingsContainer() {
  const {
    models: { breadcrumbs },
  } = useSettingsContainer();
  return (
    <Container>
      <Header links={breadcrumbs} />
      <DetailContainer $scroll={false}>
        <MenuContainer>
          <h1>Settings</h1>
          <Menu>
            <ul>
              <li>
                <NavLink to="shipping">
                  <RocketOutlined aria-hidden="true" />
                  <span>Shipping</span>
                </NavLink>
              </li>
            </ul>
          </Menu>
        </MenuContainer>
        <MainContainer $scroll>
          <Outlet />
        </MainContainer>
      </DetailContainer>
    </Container>
  );
}
