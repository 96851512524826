import { Route, Routes } from 'react-router';
import { commonRoutes, redirectRoute } from 'shared/helpers/routing';
import { useFeatureFlags } from '@/shared/providers/FeatureFlagProvider';
import { AccountListShad } from '@/pages/platform/accounts/account-list/AccountListShad';
import { AccountList } from './accounts/account-list/AccountList';
import { AccountDetails } from './accounts/account-details/AccountDetails';
import { LocationList } from './locations/location-list/LocationList';
import { LocationDetails } from './locations/location-details/LocationDetails';
import { CarrierList } from './carriers/carrier-list/CarrierList';
import { CarrierDetails } from './carriers/carrier-details/CarrierDetails';
import { CarrierServiceList } from './carriers/carrier-service-list/CarrierServiceList';
import { CarrierServiceDetails } from './carriers/carrier-service-details/CarrierServiceDetails';
import { ShippingPriceList } from './shipping-prices/shipping-price-list/ShippingPriceList';
import { ShippingPriceDetails } from './shipping-prices/shipping-price-details/ShippingPriceDetails';
import { ShipmentList } from './shipments/shipment-list/ShipmentList';
import { BillList } from './billings/bill-list/BillList';
import { BillingDetails } from './billings/billing-details/BillingDetails';
import { RequestQuote } from './quotes/RequestQuote';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

export function PlatformRoutes() {
  const { isInvencoUser } = useAuthToken();
  const { getFeatureFlag } = useFeatureFlags();
  const isNewUI = getFeatureFlag('new-ui');

  return (
    <Routes>
      {redirectRoute('/', '/dashboard')}
      {redirectRoute('/dashboard', '/accounts')}

      <Route path="/accounts">
        <Route index element={isNewUI ? <AccountListShad /> : <AccountList />} />
        <Route path=":id" element={<AccountDetails />} />
      </Route>

      <Route path="/locations">
        <Route index element={<LocationList />} />
        <Route path=":id" element={<LocationDetails />} />
      </Route>

      <Route path="/shipping">
        {redirectRoute('', 'carriers')}
        <Route path="carriers">
          <Route index element={<CarrierList />} />
          <Route path=":id" element={<CarrierDetails />} />
        </Route>

        <Route path="carrier_services">
          <Route index element={<CarrierServiceList />} />
          <Route path=":id" element={<CarrierServiceDetails />} />
        </Route>

        <Route path="prices">
          <Route index element={<ShippingPriceList />} />
          <Route path=":id" element={<ShippingPriceDetails />} />
        </Route>

        <Route path="shipments" element={<ShipmentList />} />
        <Route path="quotes" element={<RequestQuote />} />
      </Route>

      <Route path="/bills">
        <Route index element={<BillList />} />
        <Route path=":id" element={<BillingDetails />} />
      </Route>

      {commonRoutes(isInvencoUser)}
    </Routes>
  );
}
