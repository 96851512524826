import { AccessContext } from '@invenco/common-interface/auth';
import {
  BarcodeIcon,
  BoxesIcon,
  ChartNoAxesCombinedIcon,
  GaugeIcon,
  type LucideIcon,
  MapPinHouseIcon,
  ReceiptIcon,
  ReceiptTextIcon,
  StoreIcon,
  TruckIcon,
  WarehouseIcon,
  ZapIcon,
} from 'lucide-react';

export type NavItem = {
  title: string;
  icon: LucideIcon;
  url?: string;
  children?: NavSubItem[];
};
export type NavSubItem = {
  title: string;
  url: string;
};

const ITEMS_BY_CONTEXT: Record<AccessContext, NavItem[]> = {
  SELLER: [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: GaugeIcon,
    },
    {
      title: 'Products',
      url: '/products/skus',
      icon: BarcodeIcon,
    },
    {
      title: 'Supply',
      url: '/supply/asns',
      icon: BoxesIcon,
    },
    {
      title: 'Inventory',
      url: '/inventory/quantities',
      icon: WarehouseIcon,
    },
    {
      title: 'Sales',
      icon: ReceiptIcon,
      children: [
        { title: 'Orders', url: '/sales/orders' },
        { title: 'Channels', url: '/sales/channels' },
      ],
    },
    {
      title: 'Automation',
      url: '/automation/workflows',
      icon: ZapIcon,
    },
    {
      title: 'Analytics',
      url: '/analytics/insights',
      icon: ChartNoAxesCombinedIcon,
    },
    // {
    //   title: 'Settings',
    //   url: '/settings',
    //   icon: <SettingOutlined />,
    //   secondary: true,
    // },
  ],
  PLATFORM: [
    {
      title: 'Accounts',
      url: '/accounts',
      icon: StoreIcon,
    },
    {
      title: 'Locations',
      url: '/locations',
      icon: MapPinHouseIcon,
    },
    {
      title: 'Shipping',
      icon: TruckIcon,
      children: [
        { title: 'Carriers', url: '/shipping/carriers' },
        { title: 'Services', url: '/shipping/carrier_services' },
        { title: 'Prices', url: '/shipping/prices' },
        { title: 'Shipments', url: '/shipping/shipments' },
        { title: 'Quotes', url: '/shipping/quotes' },
      ],
    },
    {
      title: 'Bills',
      url: '/bills?view=PENDING',
      icon: ReceiptTextIcon,
    },
  ],
  WAREHOUSE: [
    { title: 'Tenants', url: '/tenants', icon: StoreIcon },
    {
      title: 'Automation',
      url: '/automation/workflows',
      icon: ZapIcon,
    },
    {
      title: 'Carrier services',
      url: '/warehouseCarrierServices',
      icon: TruckIcon,
    },
  ],
  SYSTEM: [],
};

const DEFAULT_ITEMS: NavItem[] = [];

export const getItems = (context?: AccessContext) =>
  context ? ITEMS_BY_CONTEXT[context] : DEFAULT_ITEMS;
