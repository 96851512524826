import { OrderDTO } from '@invenco/common-interface/sales';
import { ShippingProfileDTO } from '@invenco/common-interface/sales/ShippingProfileDTO';
import { useQuery } from '@tanstack/react-query';
import { Form, FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useGateways } from '../../../../../../gateways/GatewayProvider';
import { Result } from '../../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../../shared/types';

type Models = {
  isSaving: boolean;
  isLoading: boolean;
  form: FormInstance;
  initialValues: Record<string, any>;
  shippingProfiles: ShippingProfileDTO[];
};

type Operations = {
  submit: (data: Record<string, string>) => Promise<void>;
};

type Props = {
  preload?: boolean;
  isOpen: boolean;
  onClose: () => void;
  orderDetails: Partial<OrderDTO>;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function useShippingModalComponent({
  preload,
  isOpen,
  orderDetails,
  updateOrder,
  onClose,
}: Props): ComponentData<Models, Operations> {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const { salesGateway } = useGateways();

  const { data, isLoading } = useQuery({
    queryKey: ['shippingProfiles'],
    queryFn: ({ signal }) => salesGateway.getShippingProfiles({ signal }),
    // use hasRequested to prevent isOpen flip-flopping to retrigger useQuery
    enabled: isOpen || preload,
  });

  useEffect(() => {
    form.resetFields();
  }, [form, isOpen]);

  const submit = async (formData: Record<string, string>) => {
    setIsSaving(true);
    const result = await updateOrder({
      shippingProfileName: formData.shippingProfileName,
      subtotalShipping: parseFloat(formData.subtotalShipping),
    });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  return {
    models: {
      isSaving,
      isLoading,
      form,
      initialValues: {
        shippingProfileName: orderDetails.shippingProfileName,
        subtotalShipping: orderDetails.subtotalShipping ?? 0,
      },
      shippingProfiles: data?.items ?? [],
    },
    operations: { submit },
  };
}
