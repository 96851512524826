import { useCallback, useEffect, useMemo, useState } from 'react';
import { TableProps as AntTableProps } from 'antd';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router';
import { SEARCH_DEBOUNCE_TIMEOUT_MS } from '../../constants';
import { useUpdateEffect } from '../../shared/hooks/useUpdateEffect';
import {
  BaseRow,
  RowAction,
  TableColumn,
  TableOption,
  TableSelection,
  TableTab,
  TableUpdateOptions,
} from './types';
import { ComponentData, Page } from '../../shared/types';
import { PrefixContainer } from './styles';
import { useMessages } from '../../shared/providers/MessagesProvider';
import { RowActionContent } from './RowActionContent';

type Models<T extends BaseRow> = {
  performingAction?: RowAction<T>;
  defaultQuery?: string;
  selectedTab?: string;
  selectedOptions?: Set<string>;
  selectedKeys?: string[];
  fullOptions?: TableOption[];
  processedColumns: TableColumn<object>[];
};

type Operations<T extends BaseRow> = {
  selectTab: (key: string) => void;
  selectPage: (page: Page) => void;
  search: (query: string) => void;
  addOption: (key: string) => void;
  removeOption: (key: string) => void;
  onCheckedChange: (keys: string[], rows: T[]) => void;
  refresh: () => void;
  onRow: AntTableProps<T>['onRow'];
};

type Props<T extends BaseRow> = {
  columns: TableColumn<T>[];
  rows: T[];
  rowKey: keyof T;
  tabs?: TableTab[];
  selected?: TableSelection;
  options?: TableOption[];
  selectedRowKeys?: string[];
  hasAllOption?: boolean;
  checkable?: boolean;
  queryMinLength?: number;
  onUpdate?: (selection: TableSelection, options: TableUpdateOptions) => void;
  onClickRow?: (record: T, e: MouseEvent) => void;
  onCheckRows?: (keys: string[], records: T[]) => void;
  rowURL?: keyof T | ((record: T) => string);
  rowActions?: RowAction<T>[];
};

export const ALL_OPTION_KEY = 'ALL';
const ALL_OPTION: TableOption = { key: ALL_OPTION_KEY, title: 'All' };
const ACTIONS_MESSAGE_KEY = 'table-actions';

export function useListTableComponent<T extends BaseRow>({
  columns,
  rows,
  rowKey,
  tabs,
  selected,
  selectedRowKeys,
  options,
  hasAllOption,
  queryMinLength,
  onUpdate,
  onClickRow,
  onCheckRows,
  rowURL,
  rowActions,
}: Props<T>): ComponentData<Models<T>, Operations<T>> {
  const hasOptions = !!options?.length;
  const controlledSelectedTab = (tabs && (selected?.tab ?? tabs?.[0]?.key)) || undefined;
  const controlledQuery = selected?.query;
  const controlledSelectedPage = selected?.page;

  const controlledSelectedOptions = selected?.options;
  const transformedControlledSelectedOptions = useMemo(
    () => {
      if (!hasOptions) return undefined;
      const optionSet = new Set(controlledSelectedOptions ?? []);
      if (hasAllOption && !optionSet.size) optionSet.add(ALL_OPTION_KEY);
      return optionSet;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasOptions],
  );

  const navigate = useNavigate();
  const messages = useMessages();
  const [query, setQuery] = useState(controlledQuery);
  const [selectedTab, setSelectedTab] = useState(controlledSelectedTab);
  const [selectedOptions, setSelectedOptions] = useState(transformedControlledSelectedOptions);
  const [selectedPage, setSelectedPage] = useState(controlledSelectedPage);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedRowKeys ?? []);
  const [performingAction, setPerformingAction] = useState<RowAction<T>>();

  const fullOptions = useMemo(() => {
    if (!options) return undefined;
    return hasAllOption ? [ALL_OPTION, ...options] : options;
  }, [hasAllOption, options]);

  const processedColumns = useMemo(
    () =>
      columns.map(
        ({ key, prefix, render, ...rest }) =>
          ({
            key,
            dataIndex: key,
            // if a prefix is specified, render it with the normal content in a flex container
            render: prefix
              ? (value: any, row: T, index: number) => (
                  <PrefixContainer $align={rest.align}>
                    {prefix(row)}
                    {render ? render(value, row, index) : value}
                  </PrefixContainer>
                )
              : render,
            ...rest,
            // AntD constrains the record type at some point to object, necessitating casts
          }) as TableColumn<object>,
      ),
    [columns],
  );

  const requestUpdate = (updateOptions: TableUpdateOptions = { forceUpdate: false }) =>
    onUpdate?.(
      {
        query,
        tab: selectedTab,
        options: hasOptions && selectedOptions ? Array.from(selectedOptions) : undefined,
        page: selectedPage,
      },
      updateOptions,
    );

  useEffect(() => {
    setQuery(controlledQuery);
  }, [controlledQuery]);
  useEffect(() => {
    setSelectedTab(controlledSelectedTab);
  }, [controlledSelectedTab]);
  useEffect(() => {
    setSelectedOptions(transformedControlledSelectedOptions);
  }, [transformedControlledSelectedOptions]);
  useEffect(() => {
    setSelectedPage(controlledSelectedPage);
  }, [controlledSelectedPage]);
  useEffect(() => {
    setSelectedKeys(selectedRowKeys ?? []);
  }, [selectedRowKeys]);
  useEffect(() => {
    // if there selected keys aren't manually provided, clear them when the rows change
    if (!selectedRowKeys) {
      setSelectedKeys([]);
    }
  }, [selectedRowKeys, rows]);

  useUpdateEffect(() => {
    requestUpdate();
  }, [query, selectedTab, selectedOptions, selectedPage]);

  useEffect(
    // return cleanup function on unmount
    () => () => {
      if (rowActions?.length) {
        messages.close(ACTIONS_MESSAGE_KEY);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowActions],
  );

  const selectTab = (tab: string) => {
    setSelectedPage(undefined);
    setSelectedTab(tab);
  };

  const selectPage = (page: Page) => {
    setSelectedPage(page);
  };

  const search = debounce((newQuery: string) => {
    setSelectedPage(undefined);
    setQuery(queryMinLength && newQuery.trim().length < queryMinLength ? undefined : newQuery);
  }, SEARCH_DEBOUNCE_TIMEOUT_MS);

  const addOption = (key: string) => {
    let newOptions;
    if (hasAllOption && key === ALL_OPTION_KEY) {
      newOptions = new Set([ALL_OPTION_KEY]);
    } else {
      newOptions = new Set(selectedOptions).add(key);
      newOptions.delete(ALL_OPTION_KEY);
    }
    setSelectedPage(undefined);
    setSelectedOptions(newOptions);
  };

  const removeOption = (key: string) => {
    const newOptions = new Set(selectedOptions);
    newOptions.delete(key);
    if (hasAllOption && !newOptions.size) {
      newOptions.add(ALL_OPTION_KEY);
    }
    setSelectedPage(undefined);
    setSelectedOptions(newOptions);
  };

  const refresh = () => requestUpdate({ forceUpdate: true });

  const onRow = (row: T) => ({
    onClick: (e) => {
      if (rowURL) {
        const url = typeof rowURL === 'function' ? rowURL(row) : row[rowURL];
        if (url) {
          const urlWithSearchParams = window.location.search
            ? `${url}?parentSearch=${encodeURIComponent(window.location.search)}`
            : url;
          if (e.ctrlKey || e.metaKey) {
            window.open(urlWithSearchParams, '_blank');
          } else {
            void navigate(urlWithSearchParams);
          }
        }
      }
      if (onClickRow) onClickRow(row, e);
    },
  });

  const onRowAction = useCallback(
    async (action: RowAction<T>) => {
      setPerformingAction(action);
      await action.action(rows.filter((row) => selectedKeys.includes(row[rowKey])));
      setPerformingAction(undefined);
    },
    [selectedKeys, rows, rowKey],
  );

  const onCheckedChange = useCallback(
    (keys: string[], newRows: T[]) => {
      setSelectedKeys(keys);
      if (onCheckRows) onCheckRows(keys, newRows);
    },
    [onCheckRows],
  );

  useEffect(() => {
    if (rowActions?.length) {
      if (selectedKeys.length) {
        messages.fixed(
          ACTIONS_MESSAGE_KEY,
          <RowActionContent
            performingAction={performingAction}
            actions={rowActions}
            selectionCount={selectedKeys.length}
            onAction={(action) => void onRowAction(action)}
            onClose={() => onCheckedChange([], [])}
          />,
        );
      } else {
        messages.close(ACTIONS_MESSAGE_KEY);
      }
    }
  }, [messages, selectedKeys, performingAction, rowActions, onCheckedChange, onRowAction]);

  return {
    models: {
      performingAction,
      defaultQuery: controlledQuery,
      selectedTab,
      selectedOptions,
      selectedKeys,
      fullOptions,
      processedColumns,
    },
    operations: {
      selectTab,
      selectPage,
      search,
      addOption,
      removeOption,
      refresh,
      onRow,
      onCheckedChange,
    },
  };
}
