import { MembershipDTO } from '@invenco/common-interface/accounts';
import { AccessContext } from '@invenco/common-interface/auth';
import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useGateways } from 'gateways/GatewayProvider';
import { useNavigate } from 'react-router';
import { DEFAULT_USER_ACCOUNT_LIST_SIZE } from '@/constants';
import { useMessages } from '@/shared/providers/MessagesProvider';
import { ComponentData } from '@/shared/types';
import { useGatewayMutation } from '@/shared/hooks/queries';
import { Result } from '@/shared/helpers/Result';
import { useCurrentUserQuery } from '@/shared/hooks/queries/common';
import { getMembershipName } from './membershipName';

export type MembershipGroup = {
  context: AccessContext;
  memberships: MembershipDTO[];
};

type Models = {
  isLoading: boolean;
  isMenuOpen: boolean;
  changingToMembership?: MembershipDTO;
  hasMultipleMemberships: boolean;
  hasNonSellerMemberships: boolean;
  query: string;
  activeMembership?: MembershipDTO;
  membershipGroups: MembershipGroup[];
};

type Operations = {
  search: (query: string) => void;
  selectMembership: (membershipId: MembershipDTO['id']) => Promise<Result>;
  setIsMenuOpen: (isOpen: boolean) => void;
};

export function useAccountMenuComponent(): ComponentData<Models, Operations> {
  const navigate = useNavigate();
  const { accountsGateway } = useGateways();
  const messages = useMessages();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [changingToMembershipId, setChangingToMembershipId] = useState<string>();

  const { data: { memberships, activeMembership } = {}, isLoading } = useCurrentUserQuery();

  const membershipGroups = useMemo(() => {
    const membershipsByContext = (memberships ?? [])
      .filter(
        (m) =>
          !query ||
          getMembershipName(m)?.toLocaleLowerCase().includes(query.toLocaleLowerCase().trim()),
      )
      .reduce(
        (acc, m) => {
          if (!acc[m.accessContext]) {
            acc[m.accessContext] = [];
          }
          if (acc[m.accessContext].length < DEFAULT_USER_ACCOUNT_LIST_SIZE) {
            acc[m.accessContext].push(m);
          }
          return acc;
        },
        {} as Record<AccessContext, MembershipDTO[]>,
      );
    return [AccessContext.SELLER, AccessContext.WAREHOUSE, AccessContext.PLATFORM]
      .map((context) => ({
        context,
        memberships: membershipsByContext[context] ?? [],
      }))
      .filter((g) => g.memberships.length > 0);
  }, [memberships, query]);

  useEffect(() => {
    setQuery('');
  }, [isMenuOpen]);

  const { mutate: selectMembership } = useGatewayMutation({
    onMutate: async (membershipId: string) => {
      setIsMenuOpen(false);
      setChangingToMembershipId(membershipId);
      await queryClient.cancelQueries();
    },
    mutationFn: (membershipId: string) => accountsGateway.setUserActiveMembership(membershipId),
    onSuccess: async (newUser) => {
      // force a refresh of the auth0 token
      await getAccessTokenSilently({ cacheMode: 'off' });

      // delay to allow routing changes to propagate after token is updated
      setTimeout(() => {
        // navigate home as routes may change
        void navigate('/');

        // IMPORTANT - the cache must be cleared as query keys don't include account/membership id
        // if this is not done, initially cached data from the wrong account may be returned from queries.
        // We're doing it upon navigation and not immediately to prevent attempting to immediately refetch
        // data on screen with the wrong token.
        queryClient.clear();

        // Reset the user query data to prevent it flashing the loading state again after clearing cache
        queryClient.setQueryData(['users', 'me'], newUser);

        // update UI
        setChangingToMembershipId(undefined);
        messages.info('Switched account', { name: getMembershipName(newUser?.activeMembership) });
      }, 100);
    },
    onError: () => setChangingToMembershipId(undefined),
    linkedQuery: ['users', 'me'],
  });

  return {
    models: {
      isLoading,
      isMenuOpen,
      changingToMembership: memberships?.find((m) => m.id === changingToMembershipId),
      hasMultipleMemberships: !!memberships && memberships.length > 1,
      hasNonSellerMemberships:
        memberships?.some((m) => m.accessContext !== AccessContext.SELLER) ?? false,
      query,
      activeMembership,
      membershipGroups,
    },
    operations: {
      search: setQuery,
      selectMembership,
      setIsMenuOpen,
    },
  };
}
