import { cva, type VariantProps } from 'class-variance-authority';
import {
  FieldError as AriaFieldError,
  FieldErrorProps as AriaFieldErrorProps,
  Group as AriaGroup,
  GroupProps as AriaGroupProps,
  Label as AriaLabel,
  LabelProps as AriaLabelProps,
  Text as AriaText,
  TextProps as AriaTextProps,
  composeRenderProps,
} from 'react-aria-components';

import { cn } from '@/shared/helpers';

const labelVariants = cva([
  'text-sm font-medium leading-none',
  /* Disabled */
  'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-70',
  /* Invalid */
  'group-data-[invalid]:text-destructive',
]);

function Label({ className, children, ...props }: AriaLabelProps) {
  return (
    <AriaLabel className={cn(labelVariants(), className)} {...props}>
      {children}
    </AriaLabel>
  );
}

function FieldDescription({ className, children, ...props }: AriaTextProps) {
  return (
    <AriaText
      className={cn('text-[0.8rem] text-muted-foreground', className)}
      {...props}
      slot="description"
    >
      {children}
    </AriaText>
  );
}

function FieldError({ className, children, ...props }: AriaFieldErrorProps) {
  return (
    <AriaFieldError
      className={cn('text-[0.8rem] font-medium text-destructive', className)}
      {...props}
    >
      {children}
    </AriaFieldError>
  );
}

const fieldGroupVariants = cva('', {
  variants: {
    variant: {
      default: [
        'relative flex h-9 w-full items-center overflow-hidden rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors',
        /* Focus Within */
        'data-[focus-within]:outline-none data-[focus-within]:ring-1 data-[focus-within]:ring-ring',
        /* Disabled */
        'data-[disabled]:opacity-50',
      ],
      ghost: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

interface GroupProps extends AriaGroupProps, VariantProps<typeof fieldGroupVariants> {}

function FieldGroup({ className, variant, children, ...props }: GroupProps) {
  return (
    <AriaGroup
      className={composeRenderProps(className, (composedClassName) =>
        cn(fieldGroupVariants({ variant }), composedClassName),
      )}
      {...props}
    >
      {children}
    </AriaGroup>
  );
}

export { Label, labelVariants, FieldGroup, fieldGroupVariants, FieldError, FieldDescription };
