import { useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MAX_AUTO_COMPLETE_RESULTS, SEARCH_DEBOUNCE_TIMEOUT_MS } from '../../../constants';
import { InputQueryFunction, useQueryWithInput } from './useQueryWithInput';

const STALE_TIME = 10 * 1000; // 10s

// T = domain data type (e.g. SkuDTO)
// TOpts = additional query options which may be passed in and added to the key / params arg

// The query key is a composite of the fetch parameters and the domain key.
// The params are a combination of the search query, a default take, and optionally
// other parameters that can be passed in.
type BaseParams = { search: string; take: number };
type QueryKey<TOpts extends Record<string, any>> = [string, BaseParams & TOpts];
type DataT<T> = { items: T[] };

type Props<T, TOpts extends Record<string, any>> = {
  parentKey: string;
  queryParams?: TOpts;
  // for convenience, we will pass all params needed to make the fetch call
  // along with the rest of the query context
  query: InputQueryFunction<DataT<T>, BaseParams & TOpts>;
} & Omit<
  Partial<UseQueryOptions<DataT<T>, Error, DataT<T>, QueryKey<TOpts>>>,
  'queryFn' | 'queryKey'
>;

export function useAutoCompleteQuery<T, TOpts extends Record<string, any> = {}>({
  parentKey,
  queryParams = {} as TOpts, // = {} as TOpts necessary to avoid type confusion around queryKey
  query,
  ...rest
}: Props<T, TOpts>) {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  // use separate state (as opposed to just data return variable) to support immediate cache hit below
  const [options, setOptions] = useState<T[]>([]);

  const params = {
    search,
    take: MAX_AUTO_COMPLETE_RESULTS,
    ...queryParams,
  };

  const debouncedSetQuery = useMemo(
    () =>
      debounce((newQuery) => {
        setSearch(newQuery);
      }, SEARCH_DEBOUNCE_TIMEOUT_MS),
    [],
  );

  // If we have the data in cache, we should display it immediately. Otherwise, debounce the fetch call.
  // Debouncing the actual queryFn seems to cause odd state bugs, hence it's easier to debounce
  // the query param and manually check for a cache hit.
  const onSearch = useCallback(
    (newQuery: string) => {
      debouncedSetQuery(newQuery);
      if (!newQuery) {
        // query won't override this, since it's disabled when query is empty
        setOptions([]);
        return;
      }

      const cacheData = queryClient.getQueryData<{ items: T[] }>([
        parentKey,
        { search: newQuery, take: MAX_AUTO_COMPLETE_RESULTS, ...queryParams },
      ]);
      if (cacheData) {
        setOptions(cacheData.items ?? []);
      }
    },
    [debouncedSetQuery, queryClient, parentKey, queryParams],
  );

  const { isLoading, isError, data, error } = useQueryWithInput({
    parentKey,
    input: params,
    query,
    staleTime: STALE_TIME, // avoid unneeded immediate refetches when we have cache
    enabled: !!search,
    ...rest,
  });

  useEffect(() => {
    if (data) {
      // if the query returns over the take (e.g. getSkus with allowOverTake),
      // ensure we limit what we actually display
      setOptions(data.items.slice(0, MAX_AUTO_COMPLETE_RESULTS) ?? []);
    } else if (error) {
      setOptions([]);
    }
  }, [data, error]);

  return {
    options,
    onSearch,
    isLoading,
    isError,
  };
}
