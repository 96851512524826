import {
  OrderDTO,
  OrderHoldDTO,
  OrderHoldSubtype,
  OrderHoldType,
  OrderLineDTO,
  OrderLineStatus,
} from '@invenco/common-interface/sales';
import { Label } from '../../../../../../components/label';
import { assertNever } from '../../../../../../shared/helpers';
import { orderHoldTypeTitle } from '../../../../../../shared/title-maps';
import { ListItem } from './styles';

const MAX_ITEMS = 4;

type Props = {
  hold: OrderHoldDTO;
  orderDetails: Partial<OrderDTO>;
  orderLines: Partial<OrderLineDTO>[];
};

const getBackorderDescription = (orderLines: Partial<OrderLineDTO>[]) => {
  const backorderLines = orderLines.filter((line) => line.status === OrderLineStatus.BACKORDER);
  if (backorderLines.length) {
    return backorderLines.length > MAX_ITEMS
      ? `${backorderLines.length} items on backorder`
      : `${backorderLines.map((line) => line.skuName).join(', ')} ${
          backorderLines.length === 1 ? 'is' : 'are'
        } on backorder`;
  }
  return 'Unable to fully reserve inventory';
};

const getInvalidSkuDescription = (orderLines: Partial<OrderLineDTO>[]) => {
  const invalidLines = orderLines.filter((line) => line.status === OrderLineStatus.ITEM_NOT_FOUND);
  if (invalidLines.length) {
    return invalidLines.length > MAX_ITEMS
      ? `${invalidLines.length} invalid SKU names`
      : `${invalidLines.map((line) => line.skuName).join(', ')} ${
          invalidLines.length === 1 ? 'is an invalid SKU name' : 'are invalid SKU names'
        }`;
  }
  return 'Unable to fully reserve inventory';
};

export function OrderHoldItem({ orderDetails, orderLines, hold }: Props) {
  const getDescription = () => {
    if (hold.description) {
      return hold.description;
    }

    if (hold.subtype) {
      switch (hold.subtype) {
        case OrderHoldSubtype.NO_PREFERRED_RATE:
          return (
            <>
              Cannot determine a preferred carrier service for shipping profile{' '}
              <strong>{orderDetails.shippingProfileName}</strong>
            </>
          );
        case OrderHoldSubtype.SHIPPING_PROFILE_NOT_FOUND:
          return `Unable to find a shipping profile${
            orderDetails.shippingDescription
              ? ` for description "${orderDetails.shippingDescription}"`
              : ''
          }`;
        default:
          return assertNever(hold.subtype);
      }
    }

    switch (hold.type) {
      case OrderHoldType.SHIPPING_ADDRESS_PROCESS:
        return 'Invalid customer address';
      case OrderHoldType.RESERVE_INVENTORY_PROCESS:
        return getBackorderDescription(orderLines);
      case OrderHoldType.AUTOMATION_PROCESS:
        return 'Automation process failed';
      case OrderHoldType.ITEM_NOT_FOUND:
        return getInvalidSkuDescription(orderLines);
      case OrderHoldType.MANUAL_HOLD:
        return 'Manually placed on hold';
      case OrderHoldType.SHIPPING_HOLD:
        // should be covered by sub types
        return 'Error in shipping';
      default:
        return assertNever(hold.type);
    }
  };

  return (
    <ListItem>
      <Label>{orderHoldTypeTitle[hold.type]}</Label>
      <div>{getDescription()}</div>
    </ListItem>
  );
}
