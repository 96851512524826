import { OrderDTO } from '@invenco/common-interface/sales';
import { Form } from 'components/form';
import { FormModal } from 'components/form-modal';
import { Input } from 'components/input';
import { Result } from 'shared/helpers/Result';
import { useReferenceModalComponent } from './useReferenceModalComponent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  reference?: string | null;
  updateOrder: (data: Partial<OrderDTO>) => Promise<Result>;
};

export function ReferenceModal({ isOpen, reference, updateOrder, onClose }: Props) {
  const {
    models: { form, isSaving },
    operations: { submit },
  } = useReferenceModalComponent({ isOpen, onClose, updateOrder });
  return (
    <FormModal
      title="Reference"
      open={isOpen}
      onCancel={onClose}
      isSaving={isSaving}
      onSave={() => form.submit()}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => void submit(data)}
        initialValues={{ reference }}
      >
        <Form.Item name="reference">
          <Input.TextArea
            aria-label="Reference"
            autoFocus
            rows={4}
            showCount
            maxLength={25}
            style={{ paddingBottom: 10 }} // Make space for showCount, otherwise the scrollbar will appear
          />
        </Form.Item>
      </Form>
    </FormModal>
  );
}
