import {
  CarrierDTO,
  ShippingPriceBandDTO,
  ShippingPriceDTO,
} from '@invenco/common-interface/shipping';
import { AccountDTO, LocationDTO } from '@invenco/common-interface/accounts';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { sortBands } from '../sortBands';
import { ComponentData } from '../../../../shared/types';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';

type Models = {
  isNew: boolean;
  isSaving: boolean;
  isLoading: boolean;
  isLoadingAccounts: boolean;
  isLoadingCarriers: boolean;
  isDetailsModalOpen: boolean;
  isCarrierSelectionModalOpen: boolean;
  hasChanges: boolean;
  canSave: boolean;
  shippingPrice: Partial<ShippingPriceDTO>;
  accounts: AccountDTO[];
  account?: AccountDTO;
  carrierOptions: CarrierDTO[];
  location?: LocationDTO;
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  updateDetails: (data: Partial<ShippingPriceDTO>) => void;
  save: () => Promise<Result>;
  addNewGroup: (carrier: CarrierDTO) => void;
  removeGroup: (groupIndex: number) => void;
  addBand: (band: ShippingPriceBandDTO) => void;
  addGroupBand: (groupIndex: number, band: ShippingPriceBandDTO) => void;
  updateBand: (index: number, band: Partial<ShippingPriceBandDTO>) => void;
  updateGroupBand: (
    groupIndex: number,
    bandIndex: number,
    band: Partial<ShippingPriceBandDTO>,
  ) => void;
  removeBand: (index: number) => void;
  removeGroupBand: (groupIndex: number, bandIndex: number) => void;
  openDetailsModal: () => void;
  closeDetailsModal: () => void;
  openCarrierSelectionModal: () => void;
  closeCarrierSelectionModal: () => void;
};

export function useShippingPriceDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const isNew = id === 'new';
  const { shippingGateway, accountsGateway } = useGateways();
  const navigate = useNavigate();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [shippingPrice, setShippingPrice] = useState<Partial<ShippingPriceDTO>>({
    validFrom: new Date().toISOString(),
    priceBands: [],
  });
  const [isCarrierSelectionModalOpen, setIsCarrierSelectionModalOpen] = useState(false);

  const {
    data: shippingPriceData,
    isLoading,
    refetch,
  } = useEntityDetailsQuery({
    parentKey: 'shippingPrices',
    id,
    isNew,
    query: (fetchId, { signal }) => shippingGateway.getShippingPrice(fetchId, { signal }),
  });

  const {
    data: allAccounts,
    isLoading: isLoadingAccounts,
    refetch: refetchAccounts,
  } = useQuery({
    queryKey: ['accounts'],
    queryFn: ({ signal }) => accountsGateway.getAccounts(undefined, { signal }),
  });

  const {
    data: allCarriers,
    isLoading: isLoadingCarriers,
    refetch: refetchCarriers,
  } = useQuery({
    queryKey: ['carriers'],
    queryFn: ({ signal }) => shippingGateway.getCarriers(undefined, { signal }),
  });

  const existingName = shippingPriceData?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/shipping', title: 'Shipping' },
      { url: '/shipping/prices', title: 'Prices' },
      {
        url: `/shipping/prices/${id}`,
        title: isNew ? 'New Price' : existingName,
        loading: isLoading,
      },
    ],
    [id, isNew, isLoading, existingName],
  );

  const { data: accountDetails, refetch: refetchAccountDetails } = useQuery({
    queryKey: ['account', shippingPrice.accountId],
    queryFn: ({ signal }) => accountsGateway.getAccount(shippingPrice.accountId || '', { signal }),
  });

  const account = useMemo(
    () => allAccounts?.items.find((a) => a.id === shippingPrice.accountId),
    [allAccounts, shippingPrice.accountId],
  );
  const location = useMemo(
    () => accountDetails?.locations?.find((l) => l.id === shippingPrice.locationId),
    [accountDetails, shippingPrice.locationId],
  );

  const canSave = useMemo(
    () =>
      Boolean(
        shippingPrice.name &&
          shippingPrice.accountId &&
          shippingPrice.validFrom &&
          shippingPrice.validTo &&
          [shippingPrice, ...(shippingPrice.carrierPrices || [])].every(({ priceBands }) => {
            const numberOfLastBands = priceBands?.filter((b) => b.maxCost === undefined)?.length;
            return numberOfLastBands === 1;
          }),
      ),
    [shippingPrice],
  );

  const carrierOptions = useMemo(() => {
    const toBeExcludedList = [
      'cancelled',
      'warehouse',
      ...(shippingPrice.carrierPrices?.map(({ carrierName }) => carrierName?.toLocaleLowerCase()) ??
        []),
    ];
    return (allCarriers?.items ?? []).filter(
      ({ name }) => !toBeExcludedList.includes(name.toLocaleLowerCase()),
    );
  }, [allCarriers, shippingPrice]);

  useEffect(() => {
    setShippingPrice(
      shippingPriceData
        ? { ...shippingPriceData, priceBands: sortBands(shippingPriceData.priceBands) }
        : {
            validFrom: new Date().toISOString(),
            priceBands: [],
          },
    );
    setHasChanges(false);
  }, [shippingPriceData]);

  const refresh = async () => {
    await Promise.all([refetch(), refetchAccounts(), refetchCarriers(), refetchAccountDetails()]);
  };

  const updateDetails = (newData: Partial<ShippingPriceDTO>) => {
    setShippingPrice({ ...shippingPrice, ...newData });
    setHasChanges(true);
  };

  const addNewGroup = (carrier: CarrierDTO) => {
    const newGroup = {
      priceBands: [],
      carrierId: carrier.id,
      carrierName: carrier.name,
    };
    setShippingPrice({
      ...shippingPrice,
      carrierPrices: [...(shippingPrice.carrierPrices ?? []), newGroup],
    });
  };

  const removeGroup = (groupIndex: number) => {
    if (!shippingPrice.carrierPrices) {
      return;
    }
    const group = shippingPrice.carrierPrices[groupIndex];
    if (!group) {
      return;
    }
    setShippingPrice({
      ...shippingPrice,
      carrierPrices: [
        ...shippingPrice.carrierPrices.slice(0, groupIndex),
        ...shippingPrice.carrierPrices.slice(groupIndex + 1),
      ],
    });
    setHasChanges(true);
  };

  const addBand = (band: ShippingPriceBandDTO) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: sortBands([...(shippingPrice.priceBands ?? []), band]),
    });
    setHasChanges(true);
  };

  const addGroupBand = (groupIndex: number, band: ShippingPriceBandDTO) => {
    if (!shippingPrice.carrierPrices) {
      return;
    }
    setShippingPrice({
      ...shippingPrice,
      carrierPrices: [
        ...shippingPrice.carrierPrices.slice(0, groupIndex),
        {
          ...shippingPrice.carrierPrices[groupIndex],
          priceBands: sortBands([
            ...(shippingPrice.carrierPrices[groupIndex].priceBands ?? []),
            band,
          ]),
        },
        ...shippingPrice.carrierPrices.slice(groupIndex + 1),
      ],
    });
    setHasChanges(true);
  };

  const updateBand = (index: number, band: Partial<ShippingPriceBandDTO>) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: sortBands(
        (shippingPrice.priceBands ?? []).map((b, i) => (i === index ? { ...b, ...band } : b)),
      ),
    });
    setHasChanges(true);
  };

  const updateGroupBand = (
    groupIndex: number,
    bandIndex: number,
    band: Partial<ShippingPriceBandDTO>,
  ) => {
    if (!shippingPrice.carrierPrices || !shippingPrice.carrierPrices[groupIndex]) {
      return;
    }
    setShippingPrice({
      ...shippingPrice,
      carrierPrices: [
        ...shippingPrice.carrierPrices.slice(0, groupIndex),
        {
          ...shippingPrice.carrierPrices[groupIndex],
          priceBands: sortBands(
            (shippingPrice.carrierPrices[groupIndex].priceBands ?? []).map((b, i) =>
              i === bandIndex ? { ...b, ...band } : b,
            ),
          ),
        },
        ...shippingPrice.carrierPrices.slice(groupIndex + 1),
      ],
    });
    setHasChanges(true);
  };

  const removeBand = (index: number) => {
    setShippingPrice({
      ...shippingPrice,
      priceBands: (shippingPrice.priceBands ?? []).filter((_, i) => i !== index),
    });
    setHasChanges(true);
  };

  const removeGroupBand = (groupIndex: number, bandIndex: number) => {
    if (!shippingPrice.carrierPrices || !shippingPrice.carrierPrices[groupIndex]) {
      return;
    }
    setShippingPrice({
      ...shippingPrice,
      carrierPrices: [
        ...shippingPrice.carrierPrices.slice(0, groupIndex),
        {
          ...shippingPrice.carrierPrices[groupIndex],
          priceBands: (shippingPrice.carrierPrices[groupIndex].priceBands ?? []).filter(
            (_, i) => i !== bandIndex,
          ),
        },
        ...shippingPrice.carrierPrices.slice(groupIndex + 1),
      ],
    });
    setHasChanges(true);
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: async () => {
      const dto = {
        name: shippingPrice.name,
        accountId: shippingPrice.accountId,
        validFrom: shippingPrice.validFrom,
        validTo: shippingPrice.validTo,
        locationId: shippingPrice.locationId,
        priceBands: shippingPrice.priceBands,
        carrierPrices: shippingPrice.carrierPrices,
      };
      if (isNew) {
        return shippingGateway.createShippingPrice(dto);
      }
      if (hasChanges) {
        delete dto.accountId;
        return shippingGateway.updateShippingPrice(id!, dto);
      }
      return shippingPrice;
    },
    onSuccess: (dto) => {
      setHasChanges(false);
      if (isNew) {
        void navigate(`/shipping/prices/${dto.id}`);
      }
    },
    linkedQuery: ['shippingPrices', id],
    successMessage: `Shipping price ${isNew ? 'created' : 'saved'}`,
  });

  return {
    models: {
      isNew,
      isSaving,
      isLoading,
      isLoadingAccounts,
      isLoadingCarriers,
      isDetailsModalOpen,
      isCarrierSelectionModalOpen,
      hasChanges,
      canSave,
      shippingPrice,
      accounts: allAccounts?.items ?? [],
      account,
      location,
      carrierOptions,
      breadcrumbs,
    },
    operations: {
      refresh,
      updateDetails,
      save,
      addNewGroup,
      removeGroup,
      addBand,
      addGroupBand,
      updateBand,
      updateGroupBand,
      removeBand,
      removeGroupBand,
      openDetailsModal: () => setIsDetailsModalOpen(true),
      closeDetailsModal: () => setIsDetailsModalOpen(false),
      openCarrierSelectionModal: () => setIsCarrierSelectionModalOpen(true),
      closeCarrierSelectionModal: () => setIsCarrierSelectionModalOpen(false),
    },
  };
}
