import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { WarehouseTenantDTO } from '@invenco/common-interface/warehousing';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { ComponentData } from '../../../../shared/types';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';

type Models = {
  isLoading: boolean;
  isSaving: boolean;
  hasChanges: boolean;
  warehouseTenant: Partial<WarehouseTenantDTO>;
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  refresh: () => Promise<void>;
  updateDetails: (newData: Partial<WarehouseTenantDTO>) => void;
  updateOptions: (optionData: Record<string, any>) => void;
  save: () => Promise<Result>;
};

export function useTenantDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const { warehousingGateway } = useGateways();
  const [warehouseTenant, setWarehouseTenant] = useState<Partial<WarehouseTenantDTO>>({});
  const [hasChanges, setHasChanges] = useState(false);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'warehouseTenants',
    id,
    isNew: false,
    query: (fetchId, { signal }) => warehousingGateway.getWarehouseTenant(fetchId, { signal }),
  });

  const tenantName = warehouseTenant.tenant?.name;
  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/tenants', title: 'Tenants' },
      { url: `/tenants/${id}`, title: tenantName ?? 'Loading...', loading: isLoading },
    ],
    [id, tenantName, isLoading],
  );

  useEffect(() => {
    setWarehouseTenant(data ?? {});
  }, [data]);

  const updateDetails = (newData: Partial<WarehouseTenantDTO>) => {
    setWarehouseTenant({ ...warehouseTenant, ...newData });
    setHasChanges(true);
  };

  const refresh = async () => {
    await refetch();
  };

  const updateOptions = (optionData: Record<string, any>) => {
    updateDetails({
      warehouseTenantOptions: { ...warehouseTenant.warehouseTenantOptions, ...optionData },
    });
  };

  const { mutate: save, isPending: isSaving } = useGatewayMutation({
    mutationFn: () =>
      warehousingGateway.updateWarehouseTenant(id!, {
        warehouseTenantOptions: warehouseTenant.warehouseTenantOptions,
      }),
    onSuccess: () => setHasChanges(false),
    linkedQuery: ['warehouseTenants', id],
    successMessage: 'Tenant updated',
  });

  return {
    models: {
      isLoading,
      isSaving,
      hasChanges,
      warehouseTenant,
      breadcrumbs,
    },
    operations: {
      refresh,
      updateDetails,
      updateOptions,
      save,
    },
  };
}
