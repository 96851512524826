import { MembershipDTO } from '@invenco/common-interface/accounts';
import { AccessContext } from '@invenco/common-interface/auth';
import { CircleUser, LoaderCircleIcon, UserCog } from 'lucide-react';
import { cn } from '@/shared/helpers';
import { Avatar, AvatarFallback } from '@/components-shad/ui/avatar';
import { Skeleton } from '@/components-shad/ui/skeleton';
import { getMembershipName } from './membershipName';

const getMembershipDefaultIcon = (membership?: MembershipDTO) => {
  switch (membership?.accessContext) {
    case AccessContext.PLATFORM:
      return <UserCog />;
    case AccessContext.SYSTEM:
      return <UserCog />;

    default:
      return <CircleUser />;
  }
};

export function MembershipDetail({
  loading,
  active,
  membership,
  className,
}: {
  /**
   * Indicates that a membership is currently being fetched over the network.
   *
   * Note - Optimistic Updates: It is possible to be in a loading state and already have a membership
   * if the user is currently switching accounts. Having a loading prop and the membership prop
   * allows to optimistically showing the switched account while also showing a loading indicator
   * while the switched account is still being fetched
   */
  loading?: boolean;
  active?: boolean;
  membership?: MembershipDTO;
  className?: string;
}) {
  const name = getMembershipName(membership);

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Avatar className="h-8 w-8 rounded-lg" aria-hidden>
        <AvatarFallback className="bg-sidebar-primary text-sidebar-primary-foreground">
          {loading ? (
            <LoaderCircleIcon className="animate-spin" />
          ) : (
            getMembershipDefaultIcon(membership)
          )}
        </AvatarFallback>
      </Avatar>
      <div
        className={cn(
          'flex-1 truncate text-sm',
          active && 'font-semibold leading-tight',
          loading && 'text-muted-foreground',
        )}
      >
        {loading && !name ? <Skeleton className="h-4 w-full" /> : name}
      </div>
    </div>
  );
}
