import { SkuDTO } from '@invenco/common-interface/products';
import { AsnLineDTO } from '@invenco/common-interface/supply';
import { useMemo, useState } from 'react';
import { Result } from '../../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../../shared/types';

type Models = {
  isSelectingSku: boolean;
  isEditingNewLine: boolean;
  selectedLineId?: string;
  processedLines: Partial<AsnLineDTO>[];
  selectedSku?: SkuDTO;
};

type Operations = {
  selectLine: (orderLine: Partial<AsnLineDTO>) => void;
  closeLine: () => void;
  save: (id: string, data: Record<string, any>) => Promise<Result>;
  selectNewSku: (sku?: SkuDTO) => void;
  showSkuSelection: () => void;
  onValuesChange: (changed: Record<string, any>, values: Record<string, any>, form: any) => void;
  validateForm: (data: Record<string, any>) => boolean;
};

type Props = {
  editable?: boolean;
  asnLines: Partial<AsnLineDTO>[];
  canRecost: boolean;
  addAsnLine: (line: Partial<AsnLineDTO>) => Promise<Result>;
  updateAsnLine: (lineId: string, line: Partial<AsnLineDTO>) => Promise<Result>;
  recostLines: (asnLine: Partial<AsnLineDTO>) => Promise<Result>;
};

const NEW_ID = 'NEW';

export function useAsnLinesComponent({
  editable,
  asnLines,
  addAsnLine,
  updateAsnLine,
  canRecost,
  recostLines,
}: Props): ComponentData<Models, Operations> {
  const [selectedLineId, setSelectedLineId] = useState<string>();
  const [isSelectingSku, setIsSelectingSku] = useState(!!editable && !asnLines.length); // open initially for new/empty asns
  const [selectedSku, setSelectedSku] = useState<SkuDTO>();
  const isEditingNewLine = selectedLineId === NEW_ID;

  const processedLines = useMemo(() => {
    const lines = [...asnLines];
    if (selectedSku) {
      lines.push({
        id: NEW_ID,
        skuName: selectedSku.name,
        description: selectedSku.description,
      });
    }
    return lines;
  }, [asnLines, selectedSku]);

  const closeLine = () => {
    setSelectedLineId(undefined);
    setSelectedSku(undefined);
    if (!asnLines.length && editable) {
      setIsSelectingSku(true);
    }
  };

  const selectLine = ({ id }: Partial<AsnLineDTO>) => {
    if (!id || (!editable && !canRecost) || id === selectedLineId) return;

    setSelectedLineId(id);
    setIsSelectingSku(false);
    setSelectedSku(undefined);
  };

  const save = async (
    id: string,
    { description, qtyExpected, unitPurchaseCost, unitCostTotal }: Record<string, string>,
  ) => {
    const lineData = {
      description,
      qtyExpected: qtyExpected !== undefined ? +qtyExpected : undefined,
      unitPurchaseCost: unitPurchaseCost ? +unitPurchaseCost : null,
      unitCostTotal: unitCostTotal ? +unitCostTotal : null,
    };
    if (canRecost) {
      return recostLines({
        id: selectedLineId,
        unitPurchaseCost: unitPurchaseCost ? +unitPurchaseCost : undefined,
        unitCostTotal: unitCostTotal ? +unitCostTotal : undefined,
      });
    }

    if (id !== NEW_ID) {
      return updateAsnLine(id, lineData);
    }
    if (selectedSku) {
      setSelectedSku(undefined);
      return addAsnLine({ skuName: selectedSku.name, ...lineData });
    }
    return Result.fail('Invalid operation');
  };

  const selectNewSku = (sku?: SkuDTO) => {
    setIsSelectingSku(false);
    setSelectedSku(sku);
    if (sku) {
      setSelectedLineId(NEW_ID);
    }
  };

  const showSkuSelection = () => {
    setIsSelectingSku(true);
    setSelectedLineId(undefined);
  };

  const calculateUnitCostTotal = (
    unitPurchaseCost: number,
    qtyReceived: number,
    qtyExpected: number,
  ) => (unitPurchaseCost * (qtyReceived || qtyExpected)).toFixed(2);

  const calculateUnitCost = (unitCostTotal: number, qtyReceived: number, qtyExpected: number) =>
    (unitCostTotal / (qtyReceived || qtyExpected)).toFixed(6);

  const onValuesChange = (changed: Record<string, any>, values: Record<string, any>, form: any) => {
    let { qtyReceived, qtyExpected } = values;
    let line: Partial<AsnLineDTO>;
    let unitCostTotal;
    let unitPurchaseCost;

    if (canRecost) {
      line = processedLines.find((asnLine) => asnLine.id === selectedLineId)!;
      qtyReceived = line.qtyReceived;
      qtyExpected = line.qtyExpected;
    }

    if ('qtyExpected' in changed && changed.qtyExpected) {
      unitCostTotal = values.unitCostTotal
        ? calculateUnitCostTotal(+values.unitPurchaseCost, 0, +changed.qtyExpected)
        : null;
      unitPurchaseCost = values.unitPurchaseCost
        ? calculateUnitCost(+unitCostTotal, 0, +changed.qtyExpected)
        : null;
      form.setFieldsValue({
        unitCostTotal,
        unitPurchaseCost,
      });

      return;
    }

    if ('unitPurchaseCost' in changed) {
      if (changed.unitPurchaseCost && !Number.isNaN(Number(changed.unitPurchaseCost))) {
        unitCostTotal = calculateUnitCostTotal(
          +changed.unitPurchaseCost,
          +qtyReceived,
          +qtyExpected,
        );
        form.setFieldsValue({
          unitCostTotal,
          ...changed.unitPurchaseCost,
        });
      } else {
        form.setFieldsValue({ unitCostTotal: null, unitPurchaseCost: null });
      }
    } else if ('unitCostTotal' in changed) {
      if (changed.unitCostTotal && !Number.isNaN(Number(changed.unitCostTotal))) {
        unitPurchaseCost = calculateUnitCost(+changed.unitCostTotal, +qtyReceived, +qtyExpected);
        form.setFieldsValue({ ...changed.unitCostTotal, unitPurchaseCost });
      } else {
        form.setFieldsValue({ unitCostTotal: null, unitPurchaseCost: null });
      }
    }
  };

  const validateForm = ({ unitPurchaseCost, unitCostTotal }: Record<string, string>) => {
    if (canRecost) {
      const line = processedLines.find((asnLine) => asnLine.id === selectedLineId)!;
      if (
        (!unitPurchaseCost && Number(line?.unitPurchaseCost) >= 0) ||
        (!unitCostTotal && Number(line?.unitCostTotal) >= 0)
      ) {
        return false;
      }
    }
    return true;
  };

  return {
    models: {
      isSelectingSku,
      isEditingNewLine,
      selectedLineId,
      processedLines,
      selectedSku,
    },
    operations: {
      selectLine,
      closeLine,
      showSkuSelection,
      selectNewSku,
      save,
      onValuesChange,
      validateForm,
    },
  };
}
