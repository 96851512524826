import { Link as RouterLink } from 'react-router';
import { Card } from 'components/card';
import { Container, ListContainer } from 'styles/layout';
import { Header } from 'components/header';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { formatDateTime } from '../../../../shared/helpers';
import { useAccountListPage, sortOptions } from './useAccountListPage';

const columns: Column<AccountDTO>[] = [
  { title: 'Name', key: 'name' },
  { title: 'Primary Contact', key: 'primaryName' },
  { title: 'Primary Email', key: 'primaryEmail' },
  { title: 'Primary Phone', key: 'primaryPhone' },
  { title: 'Created', key: 'createdAt', render: (val) => formatDateTime(val) },
];

export function AccountList() {
  const {
    models: { isLoading, hasError, rows, pagination, total, query, sort },
    operations: { search, navigate, changeSort, refresh, onClickRow },
  } = useAccountListPage();

  return (
    <Container>
      <Header
        links={[{ url: '/accounts', title: 'Accounts' }]}
        title="Accounts"
        actionContent={
          <Button type="primary">
            <RouterLink to="/accounts/new">Create account</RouterLink>
          </Button>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            defaultSearchValue={query}
            onSearch={search}
            onNavigate={navigate}
            pagination={pagination}
            total={total}
            onReload={refresh}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />

          <DataTable
            columns={columns}
            rows={rows}
            loading={isLoading}
            onClickRow={onClickRow}
            hasError={hasError}
            sort={sort}
            sortOptions={sortOptions}
            onChangeSort={changeSort}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
