import { SkuDTO } from '@invenco/common-interface/products';
import { OrderLineDTO } from '@invenco/common-interface/sales';
import { useEffect, useState } from 'react';
import { Result } from '../../../../../../shared/helpers/Result';
import { ComponentData } from '../../../../../../shared/types';

type Models = {
  isSaving: boolean;
  selectedSku?: SkuDTO;
};

type Operations = {
  confirm: () => Promise<void>;
  selectSku: (sku?: SkuDTO) => void;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  line?: Partial<OrderLineDTO>;
  updateOrderLine: (lineId: string, data: Partial<OrderLineDTO>) => Promise<Result>;
};

export function useAssignSkuModalComponent({
  isOpen,
  onClose,
  line,
  updateOrderLine,
}: Props): ComponentData<Models, Operations> {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedSku, setSelectedSku] = useState<SkuDTO>();

  const confirm = async () => {
    if (!selectedSku || !line?.id) return;
    setIsSaving(true);
    const result = await updateOrderLine(line.id, { skuName: selectedSku.name });
    setIsSaving(false);
    if (result.isSuccess) onClose();
  };

  useEffect(() => {
    setSelectedSku(undefined);
  }, [isOpen]);

  return {
    models: { isSaving, selectedSku },
    operations: { confirm, selectSku: setSelectedSku },
  };
}
