import { Popconfirm } from 'antd';
import {
  UserAction,
  ServiceAgreementAction,
  ServiceAgreementDTO,
} from '@invenco/common-interface/accounts';
import { formatDate, formatDateTime } from 'shared/helpers';
import { Header } from 'components/header';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Card } from 'components/card';
import { DataTable, useRowNavigation, Column } from 'components/data-table';
import { DetailsCard } from 'components/details-card';
import { DetailsBlock } from 'components/details-block';
import { ContextMenu } from 'components/context-menu';
import { Button } from 'components/button';
import { StatusLabel } from '../../../../components/label';
import { AccountDetailsModal } from './account-details-modal/AccountDetailsModal';
import { useAccountDetailsPage } from './useAccountDetailsPage';
import { DEFAULT_TIME_ZONE, EXPANDED_DATETIME_FORMAT, NA_TEXT } from '../../../../constants';
import { AddUserModal } from './add-user-modal/AddUserModal';
import { TenantModal } from './tenant-modal/TenantModal';
import { ServiceAgreementModal } from './service-agreement-modal/ServiceAgreementModal';
import { locationStatusType, serviceAgreementStatusType } from '../../../../shared/status-maps';
import { locationStatusTitle, serviceAgreementStatusTitle } from '../../../../shared/title-maps';
import { DetailList } from '../../../../components/detail-list/DetailList';

export function AccountDetails() {
  const {
    models: {
      isNew,
      isDetailsModalOpen,
      isAddUserModalOpen,
      isTenantModalOpen,
      isServiceAgreementModalOpen,
      isLoading,
      isCreateAccountMutationPending,
      isUpdateAccountMutationPending,
      isAddingUser,
      isAddingServiceAgreement,
      isUpdatingServiceAgreement,
      isTerminatingServiceAgreement,
      canSave,
      canUpdate,
      breadcrumbs,
      account,
      isTerminate,
      selectedServiceAgreement,
    },
    operations: {
      openDetailsModal,
      closeDetailsModal,
      openAddUserModal,
      closeAddUserModal,
      openTenantModal,
      closeTenantModal,
      openServiceAgreementModal,
      closeServiceAgreementModal,
      addServiceAgreement,
      refresh,
      updateAccount,
      createAccountMutation,
      updateAccountMutation,
      addUser,
      deleteMembership,
      resendInvite,
      addToWarehouse,
      editServiceAgreement,
      terminate,
      terminateServiceAgreement,
      updateServiceAgreement,
    },
  } = useAccountDetailsPage();
  const { onClickRow } = useRowNavigation({ baseUrl: '/locations' });

  const renderDeleteLabel = (membershipId: string) => (
    <Popconfirm
      title="Are you sure you want to remove this user from the account?"
      onConfirm={() => void deleteMembership(membershipId)}
      okText="Delete"
      cancelText="Cancel"
      okButtonProps={{ danger: true }}
      placement="topLeft"
      arrowPointAtCenter
    >
      Delete
    </Popconfirm>
  );

  const serviceAgreementColumns: Column<ServiceAgreementDTO>[] = [
    { key: 'name', title: 'Name' },
    {
      key: 'commencementDate',
      title: 'Commencement Date',
      render: (date) => (date ? formatDate(date) : ''),
    },
    { key: 'locationName', title: 'Location' },
    {
      key: 'status',
      title: 'Status',
      render: (status) => (
        <StatusLabel
          status={status}
          type={serviceAgreementStatusType}
          title={serviceAgreementStatusTitle}
        />
      ),
    },
    {
      key: 'id',
      title: '',
      align: 'right',
      render: (id, data) =>
        data.allowableActions.length > 0 && (
          <ContextMenu
            onSelect={({ key }) => {
              if (key === 'edit') void editServiceAgreement(id);
              if (key === 'terminate') void terminate(id);
            }}
            items={[
              data?.allowableActions.includes(ServiceAgreementAction.EDIT)
                ? {
                    key: 'edit',
                    label: 'Edit',
                  }
                : null,
              data?.allowableActions.includes(ServiceAgreementAction.TERMINATE)
                ? {
                    key: 'terminate',
                    label: 'Terminate',
                  }
                : null,
            ].filter((a) => a)}
            buttonLabel="Further actions"
          />
        ),
    },
  ];

  return (
    <Container>
      <Header
        links={breadcrumbs}
        title={isNew ? 'New Account' : account.name}
        description={
          account.createdAt &&
          formatDateTime(account.createdAt, DEFAULT_TIME_ZONE, EXPANDED_DATETIME_FORMAT)
        }
        loading={isLoading}
        loadDescription
        backURL="/accounts"
        onRefresh={!isNew ? refresh : undefined}
        actionContent={
          isNew ? (
            <Button
              type="primary"
              disabled={!canSave}
              loading={isCreateAccountMutationPending}
              onClick={() => void createAccountMutation()}
            >
              Save
            </Button>
          ) : (
            <Button
              type="primary"
              disabled={!canUpdate}
              loading={isUpdateAccountMutationPending}
              onClick={() => void updateAccountMutation()}
            >
              Update
            </Button>
          )
        }
      />

      <DetailContainer>
        <MainContainer>
          <Card
            title="Users"
            count={account.memberships?.length ?? 0}
            rightContent={
              !isNew && (
                <Button type="link" onClick={openAddUserModal} inline>
                  Invite user
                </Button>
              )
            }
          >
            <DataTable
              simple
              columns={[
                { key: 'name', dataIndex: 'user', title: 'Name', render: ({ name }) => name },
                { key: 'email', dataIndex: 'user', title: 'Email', render: ({ email }) => email },
                {
                  key: 'id',
                  title: '',
                  align: 'right',
                  render: (membershipId: string, { user }) =>
                    !isNew && (
                      <ContextMenu
                        onSelect={({ key }) => {
                          if (key === 'resend_invite' && user) void resendInvite(user.id!);
                        }}
                        items={[
                          user?.allowableActions.includes(UserAction.RESEND_INVITE)
                            ? {
                                key: 'resend_invite',
                                label: 'Resend Invite',
                              }
                            : null,
                          {
                            key: 'delete',
                            label: renderDeleteLabel(membershipId),
                          },
                        ].filter((a) => a)}
                        buttonLabel="Further actions"
                      />
                    ),
                },
              ]}
              rows={account.memberships ?? []}
              loading={isLoading}
            />
          </Card>
          <Card
            title="Service Agreements"
            count={account.serviceAgreements?.length ?? 0}
            rightContent={
              !isNew && (
                <Button type="link" onClick={openServiceAgreementModal} inline>
                  Add service agreement
                </Button>
              )
            }
          >
            <DataTable
              simple
              columns={serviceAgreementColumns}
              rows={account.serviceAgreements ?? []}
              loading={isLoading}
            />
          </Card>
          <Card
            title="Locations"
            count={account.locations?.length}
            rightContent={
              !isNew && (
                <Button type="link" onClick={openTenantModal} inline>
                  Invite to warehouse
                </Button>
              )
            }
          >
            <DataTable
              simple
              columns={[
                { key: 'name', title: 'Name' },
                { key: 'type', title: 'Type' },
                { key: 'warehouseLocationName', title: 'Warehouse Location' },
                {
                  title: 'Status',
                  key: 'status',
                  render: (status) => (
                    <StatusLabel
                      status={status}
                      type={locationStatusType}
                      title={locationStatusTitle}
                    />
                  ),
                },
              ]}
              rows={account.locations ?? []}
              loading={isLoading}
              onClickRow={onClickRow}
            />
          </Card>
        </MainContainer>
        <SideContainer>
          <DetailsCard hasEditButton onEdit={openDetailsModal}>
            <DetailsBlock title="Account Details" loadingRows={3} loading={isLoading}>
              <DetailList
                testId="account-details-list"
                items={[
                  {
                    label: 'Name',
                    value: account.name ?? NA_TEXT,
                  },
                  {
                    label: 'Code',
                    value: account.code ?? NA_TEXT,
                  },
                  {
                    label: 'Billing ID',
                    value: account.externalId ?? NA_TEXT,
                  },
                ]}
              />
            </DetailsBlock>
            <DetailsBlock title="Primary Contact" loadingRows={3} loading={isLoading}>
              <DetailList
                testId="primary-contact-details-list"
                items={[
                  {
                    label: 'Email',
                    value: account.primaryEmail ?? NA_TEXT,
                  },
                  {
                    label: 'Name',
                    value: account.primaryName ?? NA_TEXT,
                  },
                  {
                    label: 'Phone',
                    value: account.primaryPhone ?? NA_TEXT,
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <AccountDetailsModal
        isNew={isNew}
        isOpen={isDetailsModalOpen}
        account={account}
        onClose={closeDetailsModal}
        updateAccount={updateAccount}
      />
      <AddUserModal
        isOpen={isAddUserModalOpen}
        isAdding={isAddingUser}
        onClose={closeAddUserModal}
        addUser={addUser}
      />
      <TenantModal
        isOpen={isTenantModalOpen}
        account={account}
        onClose={closeTenantModal}
        addToWarehouse={addToWarehouse}
      />
      <ServiceAgreementModal
        accountId={account.id!}
        isOpen={isServiceAgreementModalOpen}
        isSaving={
          isAddingServiceAgreement || isUpdatingServiceAgreement || isTerminatingServiceAgreement
        }
        onClose={closeServiceAgreementModal}
        addServiceAgreement={addServiceAgreement}
        updateServiceAgreement={updateServiceAgreement}
        serviceAgreement={selectedServiceAgreement}
        isTerminate={isTerminate}
        terminateServiceAgreement={terminateServiceAgreement}
      />
    </Container>
  );
}
