import { Page, PageContent } from '@/components-shad/layout';
import { Card } from '@/components-shad/ui/card';
import { Header } from '@/components-shad/header/Header';
import { Controller } from 'react-hook-form';
import { toTitleCase } from '@/shared/helpers';
import { TextField } from '@/components-shad/ui/text-field';
import { GatewayName } from '@/gateways/types';
import { Button } from '@/components-shad/ui/button';
import { Input } from '@/components-shad/ui/input';
import { useDeveloperConfigPage } from './useDeveloperConfigPage';

export function DeveloperConfigShad() {
  const {
    models: { isConfiguringUrls, baseUrls, configureFormControl, urlFormControl },
    operations: { submitConfigureForm, submitUrlForm, resetUrls },
  } = useDeveloperConfigPage();

  return (
    <Page>
      <Header
        title="Developer Config"
        description="Adjust developer-only settings"
        breadcrumbs={[{ title: 'Developer Config', url: '/dev-config' }]}
      />
      <PageContent layout="column">
        <Card
          title="API Base URLs"
          actions={
            <form
              name="configure"
              className="flex w-full flex-col gap-2 sm:w-auto sm:flex-row"
              onSubmit={submitConfigureForm}
            >
              <Controller
                name="branch"
                control={configureFormControl}
                render={({ field }) => <Input {...field} className="w-full sm:w-32" />}
              />
              <Button type="submit" className="w-full sm:w-auto" isDisabled={isConfiguringUrls}>
                Configure for Ticket
              </Button>
            </form>
          }
        >
          <form name="url" className="space-y-6" onSubmit={submitUrlForm}>
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
              {Object.keys(baseUrls).map((key) => (
                <Controller
                  key={key}
                  name={key as GatewayName}
                  control={urlFormControl}
                  render={({ field }) => <TextField {...field} label={toTitleCase(key)} />}
                />
              ))}
            </div>
            <div className="flex gap-2 sm:justify-end [&>*]:flex-1 [&>*]:sm:flex-none">
              <Button onPress={resetUrls} variant="secondary">
                Reset
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </Card>
      </PageContent>
    </Page>
  );
}
