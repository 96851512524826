import { ShippingPriceBandDTO, ShippingPriceDTO } from '@invenco/common-interface/shipping';
import { Link as RouterLink } from 'react-router';
import { Card } from 'components/card';
import { Button } from 'components/button';
import { DataTable, Column } from 'components/data-table';
import { AccountDTO } from '@invenco/common-interface/accounts';
import { Container, ListContainer } from '../../../../styles/layout';
import { Header } from '../../../../components/header';
import { formatDate, formatMoney, truncateNumber } from '../../../../shared/helpers';
import { useShippingPriceListPage } from './useShippingPriceListPage';
import { LoadingSkeleton } from '../../../../components/loading-skeleton';
import { sortBands } from '../sortBands';

type GetColumnProps = {
  accountsById: Record<string, AccountDTO> | undefined;
  isLoadingAccounts: boolean;
};

function getColumns({
  accountsById,
  isLoadingAccounts,
}: GetColumnProps): Column<ShippingPriceDTO>[] {
  return [
    { title: 'Name', key: 'name' },
    {
      title: 'Account',
      key: 'accountId',
      render: (accountId: string) =>
        isLoadingAccounts ? <LoadingSkeleton /> : accountsById?.[accountId]?.name,
    },
    {
      title: 'Location',
      key: 'locationName',
      render: (locationName: string) => locationName,
    },
    {
      title: 'Valid From',
      key: 'validFrom',
      render: (validFrom: string) => formatDate(validFrom),
    },
    {
      title: 'Until',
      key: 'validTo',
      render: (validTo: string) => formatDate(validTo),
    },
    {
      title: 'Price Bands',
      key: 'priceBands',
      render: (priceBands: ShippingPriceBandDTO[]) =>
        sortBands(priceBands)
          .slice(0, 4)
          .map(({ markup }, index) => {
            const startingAt = index > 0 ? priceBands[index - 1].maxCost : undefined;
            return `${truncateNumber(markup * 100, 1)}%${
              startingAt === 0 || startingAt ? ` at ${formatMoney(startingAt)}` : ''
            }`;
          })
          .join(', ') + (priceBands.length > 4 ? ', ...' : ''),
    },
  ];
}

export function ShippingPriceList() {
  const {
    models: {
      isLoading,
      isLoadingAccounts,
      hasError,
      rows,
      pagination,
      total,
      query,
      accountsById,
      filters,
      filterSelections,
    },
    operations: { search, navigate, updateFilters, refresh, onClickRow },
  } = useShippingPriceListPage();

  return (
    <Container>
      <Header
        title="Shipping Prices"
        links={[
          { url: '/shipping', title: 'Shipping' },
          { url: '/shipping/prices', title: 'Prices' },
        ]}
        actionContent={
          <Button type="primary">
            <RouterLink to="/shipping/prices/new">Create Shipping Price</RouterLink>
          </Button>
        }
      />
      <ListContainer>
        <Card>
          <DataTable.SearchPanel
            loading={isLoading}
            searchPlaceholderText="Search..."
            defaultSearchValue={query}
            pagination={pagination}
            total={total}
            onSearch={search}
            onNavigate={navigate}
            onReload={refresh}
            filters={filters}
            filterSelections={filterSelections}
            onChangeFilterSelections={updateFilters}
          />

          <DataTable
            rows={rows}
            columns={getColumns({ isLoadingAccounts, accountsById })}
            loading={isLoading}
            hasError={hasError}
            onClickRow={onClickRow}
          />
        </Card>
      </ListContainer>
    </Container>
  );
}
