import { Select, Switch } from 'antd';
import { Header } from 'components/header';
import { Card } from 'components/card';
import { DetailsCard } from 'components/details-card';
import { DetailsBlock } from 'components/details-block';
import { formatDateTime, truncateText } from 'shared/helpers';
import { AddressDisplay } from 'components/address-display';
import {
  Container,
  DetailContainer,
  HorizontallySpaced,
  MainContainer,
  SideContainer,
  SpacedBetween,
} from 'styles/layout';
import { Form } from 'components/form';
import { Button } from 'components/button';
import { HelpTooltip } from 'components/help-tooltip';
import { TagOutlined } from '@ant-design/icons';
import {
  SameAddressText,
  ShippingDescription,
  ShippingProfileTitle,
  OrderCount,
  CardSubHeader,
  CardSubHeaderContent,
  CardSubHeaderTitle,
} from './styles';
import { OrderProgressTracker } from './order-progress-tracker';
import { OrderActionsPanel } from './order-actions-panel';
import { useOrderDetailsPage } from './useOrderDetailsPage';
import { compareAddresses } from './utils';
import { OrderTotals } from './order-totals/OrderTotals';
import { OrderLines } from './order-lines/OrderLines';
import {
  DEFAULT_TIME_ZONE,
  EXPANDED_DATETIME_FORMAT,
  MAX_TAG_CHARACTERS,
  SUPPORTED_CURRENCIES,
} from '../../../../../constants';
import { CustomerSearch } from './customer-search/CustomerSearch';
import { CustomerModal } from './customer-modal/CustomerModal';
import { CustomerStep } from './customer-modal/useCustomerModalComponent';
import { OrderFulfillments } from './order-fulfillments/OrderFulfillments';
import { NotesModal } from './notes-modal/NotesModal';
import { ShippingModal } from './shipping-modal/ShippingModal';
import { AssignSkuModal } from './assign-sku-modal/AssignSkuModal';
import { ReferenceModal } from './reference-modal/ReferenceModal';
import { InstructionModal } from './instruction-modal/InstructionModal';
import { orderStatusTitle } from '../../../../../shared/title-maps';
import { StatusLabel } from '../../../../../components/label';
import { orderStatusType } from '../../../../../shared/status-maps';
import { Tag, Tags } from '../../../../../components/tag';
import { OrderHolds } from './order-holds/OrderHolds';

export function OrderDetails() {
  const {
    models: {
      isLoading,
      isLoadingChannels,
      isSaving,
      isNew,
      isEditable,
      isSaveEnabled,
      hasCustomerInfo,
      isCustomerModalOpen,
      isCustomerModalSequential,
      customerModalStep,
      isShippingModalOpen,
      isNotesModalOpen,
      isReferenceModalOpen,
      isInstructionModalOpen,
      isAssignSkuModalOpen,
      assignSkuLine,
      preloadShippingProfiles,
      orderDetails,
      orderLines,
      breadcrumbs,
      selectedChannel,
      isChannelSelectionEnabled,
      channels,
    },
    operations: {
      refresh,
      save,
      updateDetails,
      addOrderLine,
      updateOrderLine,
      deleteOrderLine,
      performAction,
      openNewCustomerCreation,
      changeCustomerModalStep,
      closeCustomerModal,
      openNotesModal,
      closeNotesModal,
      openReferenceModal,
      closeReferenceModal,
      openInstructionModal,
      closeInstructionModal,
      openShippingModal,
      closeShippingModal,
      openAssignSkuModal,
      closeAssignSkuModal,
      cancel,
    },
  } = useOrderDetailsPage();

  const renderActionButtons = () => {
    if (isNew) {
      return (
        <>
          <Button onClick={cancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={!isSaveEnabled}
            loading={isSaving}
            onClick={() => void save()}
          >
            Save draft
          </Button>
        </>
      );
    }
    return isLoading ? null : (
      <OrderActionsPanel
        availableActions={orderDetails?.availableActions ?? []}
        onAction={performAction}
      />
    );
  };

  const renderBillingAddress = () => {
    if (
      !isLoading &&
      orderDetails.shippingAddress?.contactName &&
      compareAddresses(orderDetails.shippingAddress, orderDetails.billingAddress)
    ) {
      return <SameAddressText>Same as shipping address</SameAddressText>;
    }

    return <AddressDisplay address={orderDetails.billingAddress} loading={isLoading} />;
  };

  return (
    <Container>
      <Header
        title={isNew ? 'New Order' : orderDetails.name}
        links={breadcrumbs}
        backURL="/sales/orders"
        loading={isLoading}
        loadDescription
        actionContent={renderActionButtons()}
        onRefresh={!isNew ? refresh : undefined}
        description={
          orderDetails?.orderedAt || orderDetails?.tags?.length ? (
            <HorizontallySpaced $factor={2}>
              {orderDetails?.orderedAt && (
                <span>
                  {formatDateTime(
                    orderDetails.orderedAt,
                    DEFAULT_TIME_ZONE,
                    EXPANDED_DATETIME_FORMAT,
                  )}
                </span>
              )}
              <Tags>
                {orderDetails?.tags?.map((tag) => (
                  <Tag key={tag.id} prefix={<TagOutlined aria-hidden="true" />}>
                    {truncateText(tag.name, MAX_TAG_CHARACTERS)}
                  </Tag>
                ))}
              </Tags>
            </HorizontallySpaced>
          ) : undefined
        }
        extraContent={
          orderDetails.status && (
            <StatusLabel
              aria-label={`Status: ${orderStatusTitle[orderDetails.status]}`}
              status={orderDetails.status}
              type={orderStatusType}
              title={orderStatusTitle}
            />
          )
        }
      />

      {orderDetails?.status && !['draft', 'cancelled'].includes(orderDetails.status) ? (
        <OrderProgressTracker order={orderDetails} />
      ) : null}

      <DetailContainer>
        <MainContainer>
          {orderDetails?.orderHolds && orderDetails.orderHolds.length > 0 && (
            <Card title="Holds" count={orderDetails.orderHolds.length}>
              <OrderHolds
                holds={orderDetails.orderHolds}
                orderDetails={orderDetails}
                orderLines={orderLines}
              />
            </Card>
          )}

          <Card title="Items" count={isLoading ? undefined : orderLines.length}>
            <OrderLines
              orderLines={orderLines}
              loading={isLoading}
              editable={isEditable}
              canDeleteLines={isNew}
              orderCurrency={orderDetails.currency}
              channel={selectedChannel!}
              addOrderLine={addOrderLine}
              updateOrderLine={updateOrderLine}
              deleteOrderLine={deleteOrderLine}
              openAssignSku={openAssignSkuModal}
            />
            <OrderTotals orderDetails={orderDetails} orderLines={orderLines} loading={isLoading} />
          </Card>

          <Card
            title="Shipping"
            spaceChildren={false}
            rightContent={
              isEditable && (
                <Button type="link" inline onClick={openShippingModal}>
                  {orderDetails.shippingProfileName ? 'Edit' : 'Set Shipping'}
                </Button>
              )
            }
          >
            {(orderDetails.shippingDescription || orderDetails.shippingProfileName) && (
              <>
                <ShippingProfileTitle>{orderDetails.shippingProfileName}</ShippingProfileTitle>
                <ShippingDescription>{orderDetails.shippingDescription}</ShippingDescription>
              </>
            )}
          </Card>

          {!!orderDetails.fulfillments?.length && (
            <OrderFulfillments loading={isLoading} fulfillments={orderDetails.fulfillments} />
          )}
        </MainContainer>

        <SideContainer>
          <DetailsCard
            title="Customer"
            hasEditButton={isEditable && hasCustomerInfo}
            onEdit={() => changeCustomerModalStep(CustomerStep.CUSTOMER)}
          >
            <DetailsBlock
              contentTitle={orderDetails.customerName}
              loading={isLoading}
              loadingRows={3}
            >
              {hasCustomerInfo ? (
                <>
                  <div>{orderDetails.email}</div>
                  <div>{orderDetails.phone}</div>
                  {orderDetails.customerOrderNumber && (
                    <OrderCount>{`Order count: ${orderDetails.customerOrderNumber}`}</OrderCount>
                  )}
                </>
              ) : (
                isEditable && (
                  <CustomerSearch
                    updateOrder={updateDetails}
                    onCreateNewCustomer={openNewCustomerCreation}
                  />
                )
              )}
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard
            title="Address"
            hasEditButton={isEditable && hasCustomerInfo}
            onEdit={() => changeCustomerModalStep(CustomerStep.ADDRESS)}
          >
            <DetailsBlock title="Shipping Address">
              <AddressDisplay loading={isLoading} address={orderDetails.shippingAddress} />
            </DetailsBlock>
            <DetailsBlock title="Billing Address">{renderBillingAddress()}</DetailsBlock>
          </DetailsCard>

          <DetailsCard
            title="Reference"
            hasEditButton={isEditable}
            onEdit={openReferenceModal}
            tooltip="Shown on the carrier labels with the order name. Primarily used for the PO number."
          >
            {orderDetails.reference}
          </DetailsCard>

          <DetailsCard title="Properties">
            <DetailsBlock>
              <Form layout="vertical">
                <Form.Item label="Channel">
                  <Select
                    aria-label="Channel"
                    value={orderDetails.channelName}
                    onChange={(channelName) => void updateDetails({ channelName })}
                    loading={isLoadingChannels || isLoading || isSaving}
                    disabled={!isChannelSelectionEnabled}
                  >
                    {channels.map((ch) => (
                      <Select.Option key={ch.name} value={ch.name}>
                        {ch.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Currency">
                  <Select
                    aria-label="Currency"
                    value={orderDetails.currency}
                    onChange={(currency) => void updateDetails({ currency })}
                    loading={isLoading || isSaving}
                    disabled={!isEditable}
                  >
                    {SUPPORTED_CURRENCIES.map((currency) => (
                      <Select.Option key={currency} value={currency}>
                        {currency}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </DetailsBlock>

            <DetailsBlock title="Fulfillment">
              <SpacedBetween>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '8px' }}>Partial Fulfillment</div>
                  <HelpTooltip title="Flag will automatically toggle off when the next fulfilment is created upon order release. Editing the order may require the flag to be toggled on again." />
                </div>
                <Switch
                  loading={isSaving || isLoading}
                  size="small"
                  checked={orderDetails.allowPartialFulfillments}
                  onChange={(value) => void updateDetails({ allowPartialFulfillments: value })}
                  disabled={!isEditable}
                  aria-label="Allow partial fulfillment"
                />
              </SpacedBetween>
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard
            title="Notes"
            hasEditButton={isEditable}
            onEdit={openNotesModal}
            tooltip="Lynk order notes field. This is not passed to the warehouse."
          >
            {orderDetails.notes}
          </DetailsCard>

          <DetailsCard
            title="Instructions"
            hasEditButton={isEditable}
            onEdit={openInstructionModal}
            tooltip={
              <>
                <div>
                  Delivery Instructions for the carrier, these are passed to the warehouse and
                  printed on the shipping labels.
                </div>
                <div style={{ marginTop: '10px' }}>
                  Packing Instruction passed to the warehouse, shown to the packers to support the
                  physical packing process.
                </div>
              </>
            }
          >
            <>
              {orderDetails.deliveryInstructions && (
                <CardSubHeader>
                  <CardSubHeaderTitle>Delivery</CardSubHeaderTitle>
                  <CardSubHeaderContent>{orderDetails.deliveryInstructions}</CardSubHeaderContent>
                </CardSubHeader>
              )}
              {orderDetails.packingInstructions && (
                <CardSubHeader>
                  <CardSubHeaderTitle $applyPaddingTop>Packing</CardSubHeaderTitle>
                  <CardSubHeaderContent>{orderDetails.packingInstructions}</CardSubHeaderContent>
                </CardSubHeader>
              )}
            </>
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <CustomerModal
        isOpen={isCustomerModalOpen}
        isSequential={isCustomerModalSequential}
        step={customerModalStep}
        orderDetails={orderDetails}
        updateDetails={updateDetails}
        changeStep={changeCustomerModalStep}
        onClose={closeCustomerModal}
      />
      <NotesModal
        isOpen={isNotesModalOpen}
        onClose={closeNotesModal}
        updateOrder={updateDetails}
        notes={orderDetails.notes}
      />
      <ShippingModal
        preload={preloadShippingProfiles}
        isOpen={isShippingModalOpen}
        onClose={closeShippingModal}
        orderDetails={orderDetails}
        updateOrder={updateDetails}
      />
      <AssignSkuModal
        isOpen={isAssignSkuModalOpen}
        onClose={closeAssignSkuModal}
        line={assignSkuLine}
        updateOrderLine={updateOrderLine}
      />
      <ReferenceModal
        isOpen={isReferenceModalOpen}
        onClose={closeReferenceModal}
        reference={orderDetails.reference}
        updateOrder={updateDetails}
      />
      <InstructionModal
        isOpen={isInstructionModalOpen}
        onClose={closeInstructionModal}
        deliveryInstructions={orderDetails.deliveryInstructions}
        packingInstructions={orderDetails.packingInstructions}
        updateOrder={updateDetails}
      />
    </Container>
  );
}
