import { LocationDTO, LocationType } from '@invenco/common-interface/accounts';
import { ShippingMethodType } from '@invenco/common-domain/enums/ShippingMethod';
import {
  CreateWarehouseShippingMethodDTO,
  ShippingMethodDTO,
} from '@invenco/common-interface/shipping';
import { WarehouseDTO } from '@invenco/common-interface/warehousing';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { ComponentData } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { BreadCrumb } from '../../../../components/header';
import { Result } from '../../../../shared/helpers/Result';
import {
  useEntityDetailsQuery,
  useGatewayMutation,
  useQueryWithInput,
} from '../../../../shared/hooks/queries';
import { useRowNavigation } from '../../../../components/data-table';

type Models = {
  isLoading: boolean;
  isLoadingShippingMethods: boolean;
  isLoadingWarehouse: boolean;
  isLoadingManagedLocations: boolean;
  isShippingMethodModalOpen: boolean;
  location: Partial<LocationDTO>;
  shippingMethods: ShippingMethodDTO[];
  warehouse?: WarehouseDTO;
  managedLocations?: LocationDTO[];
  breadcrumbs: BreadCrumb[];
};

type Operations = {
  openShippingMethodModal: () => void;
  closeShippingMethodModal: () => void;
  refresh: () => Promise<void>;
  addWarehouseShippingMethod: (shippingMethod: AddShippingMethodDTO) => Promise<Result>;
  addManagedShippingMethod: (warehouseShippingMethod: ShippingMethodDTO) => Promise<Result>;
  onClickManagedLocation: (
    location: LocationDTO,
    event: React.MouseEvent<HTMLTableRowElement>,
  ) => void;
};

export type AddShippingMethodDTO = Omit<
  CreateWarehouseShippingMethodDTO,
  'accountId' | 'locationId'
>;

export function useLocationDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams();
  const isNew = !id;
  const { accountsGateway, shippingGateway, warehousingGateway } = useGateways();
  const { onClickRow: onClickManagedLocation } = useRowNavigation({ baseUrl: '/locations' });
  const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] = useState(false);

  const { data, isLoading, refetch } = useEntityDetailsQuery({
    parentKey: 'locations',
    id,
    isNew,
    query: (fetchId, { signal }) => accountsGateway.getLocation(fetchId, { signal }),
  });

  // setup this way in case we allow editing at some point
  const location: Partial<LocationDTO> = data || {};
  const isWarehouse = location.type === LocationType.WAREHOUSE;

  const {
    data: shippingMethods,
    isLoading: isLoadingShippingMethods,
    refetch: refetchShippingMethods,
  } = useQueryWithInput({
    parentKey: 'shippingMethods',
    input: { locationId: id },
    query: (input, { signal }) => shippingGateway.getShippingMethods(input, { signal }),
    select: ({ items }) => items,
    enabled: !!id,
  });

  const {
    data: warehouse,
    isLoading: isLoadingWarehouse,
    refetch: refetchWarehouse,
  } = useQuery({
    queryKey: ['warehouses'],
    queryFn: ({ signal }) => warehousingGateway.getWarehouses(undefined, { signal }),
    select: ({ items }) => items.find((w) => w.locationId === id),
    enabled: !!id && isWarehouse,
  });

  const {
    data: managedLocations,
    isLoading: isLoadingManagedLocations,
    refetch: refetchManagedLocations,
  } = useQuery({
    queryKey: ['locations'],
    queryFn: ({ signal }) => accountsGateway.getLocations(undefined, { signal }),
    select: ({ items }) => items.filter((l) => l.warehouseLocationId === id),
    enabled: !!id && isWarehouse,
  });

  const breadcrumbs = useMemo<BreadCrumb[]>(
    () => [
      { url: '/locations', title: 'Locations' },
      { url: `/locations/${id}`, title: location.name, loading: isLoading },
    ],
    [id, location.name, isLoading],
  );

  const refresh = async () => {
    await Promise.all([
      refetch(),
      refetchShippingMethods(),
      refetchWarehouse(),
      refetchManagedLocations(),
    ]);
  };

  const { mutate: addWarehouseShippingMethod } = useGatewayMutation({
    mutationFn: (shippingMethod: AddShippingMethodDTO) =>
      shippingGateway.createShippingMethod({
        ...shippingMethod,
        accountId: location.accountId,
        locationId: id,
        type: ShippingMethodType.WAREHOUSE,
      }),
    linkedQuery: ['shippingMethods', { locationId: id }],
    linkResponseToQuery: false,
    successMessage: 'Warehouse shipping method added',
  });

  const { mutate: addManagedShippingMethod } = useGatewayMutation({
    mutationFn: async (warehouseShippingMethod: ShippingMethodDTO) => {
      // sanity check
      if (warehouseShippingMethod.locationId !== location.warehouseLocationId) {
        throw new Error('Invalid warehouse shipping method provided, location ids do not match.');
      }
      return shippingGateway.createShippingMethod({
        name: warehouseShippingMethod.name,
        accountId: location.accountId,
        locationId: id,
        warehouseShippingMethodId: warehouseShippingMethod.id,
        carrierServiceId: warehouseShippingMethod.carrierServiceId,
        bookingCarrierAccountId: warehouseShippingMethod.bookingCarrierAccountId,
        ratesCarrierAccountId: warehouseShippingMethod.ratesCarrierAccountId,
      });
    },
    linkedQuery: ['shippingMethods', { locationId: id }],
    linkResponseToQuery: false,
    successMessage: 'Managed shipping method added',
  });

  return {
    models: {
      isLoading,
      isLoadingShippingMethods,
      isLoadingWarehouse,
      isLoadingManagedLocations,
      isShippingMethodModalOpen,
      location,
      shippingMethods: shippingMethods ?? [],
      warehouse: isWarehouse ? warehouse : undefined,
      managedLocations: isWarehouse ? managedLocations : undefined,
      breadcrumbs,
    },
    operations: {
      openShippingMethodModal: () => setIsShippingMethodModalOpen(true),
      closeShippingMethodModal: () => setIsShippingMethodModalOpen(false),
      refresh,
      addWarehouseShippingMethod,
      addManagedShippingMethod,
      onClickManagedLocation,
    },
  };
}
