import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { toTitleCase } from '../../../shared/helpers';
import { BreadCrumb } from '../../../components/header';
import { ComponentData } from '../../../shared/types';

type Models = {
  breadcrumbs: BreadCrumb[];
};

export function useSettingsContainer(): ComponentData<Models> {
  const { pathname } = useLocation();
  const breadcrumbs = useMemo<BreadCrumb[]>(() => {
    const links = [{ url: '/settings', title: 'Settings' }];
    const segments = pathname.split('/');
    if (segments.length >= 3) {
      links.push({ url: `/settings/${segments[2]}`, title: toTitleCase(segments[2]) });
    }
    return links;
  }, [pathname]);
  return { models: { breadcrumbs } };
}
